/* eslint-disable camelcase */
import {
  Role,
  RoleResponse,
  User,
  UserBadge,
  UserBadgeResponse,
  UserResponse,
} from '@/interfaces/user';
import { toCamel } from '@/utilities/helpers';

export const badgeAdapter = (badgeResponse: UserBadgeResponse): UserBadge => ({
  title: badgeResponse.title,
  lightModeUrl: badgeResponse.light_mode_url,
  darkModeUrl: badgeResponse.dark_mode_url,
});

export const roleAdapter = (rolesResponse: RoleResponse): Role => toCamel(rolesResponse) as Role;

const userAdapter = ({ user }: UserResponse): User => {
  const {
    id,
    badges: badgesResponse,
    app_download_eligible,
    bonus_cash,
    email,
    has_phone,
    identification_status,
    pre_reg_eligible,
    profile_color,
    profile_icon_url,
    roles: rolesResponse,
    registration_source,
    state,
    username,
    wallet,
  } = user;

  const badges = badgesResponse?.map(badgeAdapter);
  const roles = rolesResponse?.map(roleAdapter);

  return {
    id,
    appDownloadEligible: app_download_eligible,
    badges,
    bonusCash: bonus_cash || '0',
    email,
    hasPhone: has_phone,
    identificationStatus: toCamel(identification_status) as User['identificationStatus'],
    profileColor: profile_color,
    preRegEligible: pre_reg_eligible,
    profileIconUrl: profile_icon_url,
    registrationSource: registration_source,
    roles,
    state,
    username,
    wallet: wallet || {},
  };
};

export default userAdapter;

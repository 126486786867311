/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useStore } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';

import { RootState } from '@/store';
import {
  closeToast as closeToastAction,
  openToast as openToastAction,
} from '@/store/modules/toast/actions';

import { OpenToastProps } from '@/interfaces/toast';

import styles from './styles.scss';

export interface ToastMessage {
  message: string | React.ReactNode;
  key: number;
}

export interface ToastProps {
  isOpen: boolean;
  closeToast: (data?: OpenToastProps) => any;
  toastProps: OpenToastProps;
}

const Toast = ({ isOpen, closeToast, toastProps }: ToastProps) => {
  // separating these so that we don't pass customClose into snackbar
  const { customClose, message, ...remainingToastProps } = toastProps || {};

  const [open, setOpen] = useState(isOpen);
  const [messageQueue, setMessageQueue] = useState<readonly ToastMessage[]>([]);
  const [toastMessage, setToastMessage] = useState<ToastMessage | undefined>(undefined);

  useEffect(() => {
    if (messageQueue.length && !toastMessage) {
      // Set a new snack when we don't have an active one
      setToastMessage({ ...messageQueue[0] });
      setMessageQueue((prev) => prev.slice(1));
      setOpen(true);
    } else if (messageQueue.length && toastMessage && open) {
      // Close an active snack when a new one is added
      setOpen(false);
    }
  }, [messageQueue, toastMessage, open]);

  useEffect(() => {
    if (message && isOpen) {
      setMessageQueue((prev) => [...prev, { message, key: new Date().getTime() }]);
    }

    if (!message) {
      setOpen(false);
    }
  }, [message, isOpen]);

  const handleCloseToast = () => {
    // We pass in toastProps on close to fix an issue where there was a flash of unstyled, message-less Snackbar
    // right before it was removed from the page.
    // previously toastConfig was getting set to null in the close action of the reducer
    // and thus stripping the component of the custom classNames passed in
    setOpen(false);
    closeToast(toastProps);

    if (customClose) {
      customClose();
    }
  };

  const handleExited = () => {
    setToastMessage(null);
  };

  return (
    <Snackbar
      {...remainingToastProps}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      classes={{ root: styles.toastContainerRoot }}
      autoHideDuration={6000}
      open={open}
      onClose={handleCloseToast}
      data-testid="snackbar-container"
      TransitionProps={{ onExited: handleExited }}
    >
      <SnackbarContent
        classes={{
          root: styles.toastContentRoot,
        }}
        message={toastMessage && toastMessage.message}
        data-testid="snackbar-content"
      />
    </Snackbar>
  );
};

export const useToast = () => {
  const store = useStore();
  return useCallback(
    (toastConfig: OpenToastProps) => store.dispatch(openToastAction(toastConfig)),
    [store]
  );
};

export default connect(
  (state: RootState) => ({
    isOpen: state.toast.isOpen,
    toastProps: state.toast.toastConfig,
  }),
  (dispatch) => ({
    closeToast: (data?: OpenToastProps) => dispatch(closeToastAction(data)),
  })
)(Toast);

/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';

import ExternalLink from '@/components/atoms/external-link';
import LinkWrapper from '@/components/atoms/link-wrapper';
import { AllStateConfigurations } from '@/interfaces/state-configuration';
import { User } from '@/interfaces/user';

import styles from './styles.scss';

export type ResponsibleGamingTextType =
  | 'rgAccountText'
  | 'rgDraftText'
  | 'rgLandingText'
  | 'rgPickemText';

const getOptions = () => ({
  renderNode: {
    [INLINES.HYPERLINK]: (node: any, children: React.ReactNode) => {
      if (node.data.uri.includes('https://www.underdogfantasy.com')) {
        const { pathname } = new URL(node.data.uri);
        return <LinkWrapper to={pathname} className={styles.link} element={children} />;
      }
      return <ExternalLink className={styles.link} href={node.data.uri} text={children} />;
    },
  },
});

export const formatStateSpecificRGText = ({
  authenticated,
  stateConfigurations,
  textType,
  user,
}: {
  authenticated: boolean;
  stateConfigurations: AllStateConfigurations;
  textType: ResponsibleGamingTextType;
  user: User;
}) => {
  let responsibleGamingText: React.ReactNode;
  const defaultResponsibleGamingText = stateConfigurations?.DEFAULT?.[textType];

  const { state } = user;
  const stateResponsibleGaming = stateConfigurations?.[state];
  const stateResponsibleGamingText = stateResponsibleGaming?.[textType];

  const showDefaultRGText =
    (!authenticated && defaultResponsibleGamingText) || !stateResponsibleGamingText;
  if (showDefaultRGText) {
    responsibleGamingText = documentToReactComponents(defaultResponsibleGamingText, getOptions());
  } else {
    responsibleGamingText = documentToReactComponents(stateResponsibleGamingText, getOptions());
  }

  if (!responsibleGamingText || responsibleGamingText === '') {
    return null;
  }

  return responsibleGamingText;
};

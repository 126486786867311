import { SagaIterator } from 'redux-saga';
import { all, call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';

import * as configurationsApi from '@/api/configurations';
import * as api from '@/api/pick-em';
import errorLogger from '@/utilities/errors/logger';

import {
  getLivePickEmEntries,
  getPickEm,
  getPickEmPoolLeaderboard,
  getSettledPickEmEntries,
  getSlipPayoutOutcome,
  setEntrySlipLimits,
  setFavoritePickEmPlayers,
  setFlexPoolStyles,
  setLivePickEmEntries,
  setPickEmConfig,
  setPickEmOverUnderLines,
  setPickEmPoolLeaderboard,
  setPickEmPoolStyles,
  setPickEmRivalLines,
  setSettledPickEmEntries,
  setSlipPayoutOutcome,
} from '../actions';

export const getPayoutOutcomeSaga = function* getPayoutOutcomeSaga(
  action: ReturnType<typeof getSlipPayoutOutcome>
): SagaIterator {
  try {
    const { options, entryRoles } = action.payload;
    const response = yield call(api.getSlipPayoutOutcome, { options });
    yield put(setSlipPayoutOutcome({ data: response.data, entryRoles }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getPickEmConfigSaga = function* getPickEmConfigSaga(): SagaIterator {
  try {
    const [payoutStylesResponse, correlationsResponse, configurationsResponse] = yield all([
      call(api.getPayoutStyles),
      call(api.getCorrelations),
      call(configurationsApi.getConfigurations, 'Pickem'),
    ]);

    yield put(
      setPickEmConfig({
        payoutStylesResponse: payoutStylesResponse.data,
        correlationsResponse: correlationsResponse.data,
        pickEmConfigurationsResponse: configurationsResponse.data,
      })
    );
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

// get over_under, live_over_under, rivals, and favorite_players all at once
export const getPickEmSaga = function* getPickEmSaga(
  action: ReturnType<typeof getPickEm>
): SagaIterator {
  try {
    const [
      entrySlipLimitsResponse,
      overUnderLineResponse,
      liveOverUnderLineResponse,
      rivalLineResponse,
      favoritesResponse,
      featuredLobbyResponse,
      featuredOverUnderLineResponse,
    ] = yield all([
      call(api.getEntrySlipLimits),
      call(api.getOverUnderLines),
      call(api.getLiveOverUnderLines),
      call(api.getRivalLines),
      call(api.getFavoritePickEmPlayers),
      call(api.getFeaturedLobby),
      call(api.getFeaturedOverUnderLines),
    ]);

    yield put(
      setEntrySlipLimits({
        limits: entrySlipLimitsResponse.data.limits,
      })
    );

    yield put(
      setPickEmOverUnderLines({
        preGameOverUnderLines: overUnderLineResponse.data,
        liveOverUnderLines: liveOverUnderLineResponse.data,
        favoritePlayers: favoritesResponse?.data?.favorite_players,
        draftingConfig: action.payload.draftingConfig,
        featuredLobby: featuredLobbyResponse.data,
        featuredOverUnders: featuredOverUnderLineResponse.data,
        pickEmStateConfig: action.payload.pickEmStateConfig,
        roles: action.payload.roles,
      })
    );
    yield put(
      setPickEmRivalLines({
        data: rivalLineResponse.data,
        favoritePlayers: favoritesResponse?.data?.favorite_players,
        draftingConfig: action.payload.draftingConfig,
        pickEmStateConfig: action.payload.pickEmStateConfig,
        roles: action.payload.roles,
      })
    );
    yield put(setFavoritePickEmPlayers({ data: favoritesResponse?.data }));
  } catch (e) {
    errorLogger(true, `getPickEmSaga: ${e}`);
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getLivePickEmEntrySlipsSaga = function* getLivePickEmEntrySlipsSaga(
  action: ReturnType<typeof getLivePickEmEntries>
): SagaIterator {
  try {
    const response = yield call(api.getActiveEntrySlips, action.payload);

    yield put(setLivePickEmEntries({ data: response.data, pageNumber: action.payload.page }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getPickEmPoolLeaderboardSaga = function* getPickEmPoolLeaderboardSaga(
  action: ReturnType<typeof getPickEmPoolLeaderboard>
): SagaIterator {
  try {
    const response = yield call(api.getPickEmPoolLeaderboard, action.payload);

    yield put(setPickEmPoolLeaderboard({ data: response.data }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getSettledPickEmEntrySlipsSaga = function* getSettledPickEmEntrySlipsSaga(
  action: ReturnType<typeof getSettledPickEmEntries>
): SagaIterator {
  try {
    const response = yield call(api.getSettledEntrySlips, action.payload);

    yield put(setSettledPickEmEntries({ data: response.data, pageNumber: action.payload.page }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getPickEmPoolStylesSaga = function* getPickEmPoolStylesSaga(): SagaIterator {
  try {
    const response = yield call(api.getPickEmPoolStyles);

    yield put(setPickEmPoolStyles({ data: response.data }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

export const getFlexPoolStylesSaga = function* getFlexPoolStylesSaga(): SagaIterator {
  try {
    const response = yield call(api.getFlexPoolStyles);

    yield put(setFlexPoolStyles({ data: response.data }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
  }
};

import { Experiment } from '@amplitude/experiment-js-client';

const webDeploymentKey =
  process.env.APP_ENV === 'production'
    ? 'client-6ZveT3gLFqCx2hh9aDbipoNhmu5YkfFk'
    : 'client-C54WWGJozt97npgQrVcnMx3nsKBdWBqX';

export const experiment = Experiment.initializeWithAmplitudeAnalytics(webDeploymentKey, {
  fetchOnStart: true,
  automaticFetchOnAmplitudeIdentityChange: true,
});

export const amplitudeExperimentKeys = {
  // The control and variant values aren't needed since this experiment happens on the API side
  // We only need the key to track user exposure on the client (https://www.docs.developers.amplitude.com/experiment/sdks/javascript-sdk/#exposure)
  defaultDepositMatchV2: {
    key: 'default-deposit-match-v2',
  },
  defaultInsurance: {
    key: 'default-insurance',
    control: 'control',
    variantA: 'variant-a',
  },
  // // Structure Example:
  // testWebFlag: {
  //   key: 'test_web_flag',
  //   variantValueA: 'on',
  //   variantValueB: 'web-test-variant',
  // },
};

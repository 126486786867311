import client from '@/api-contentful/contentful-request';

export const getContentfulAllSweepstakesTermsAndConditions = async (): Promise<any> => {
  const response = await client.getEntries({
    content_type: 'sweepstakeTermsAndConditions',
  });

  return response;
};

export const getContentfulSweepstakesTermsAndConditions = async ({
  slug,
}: {
  slug: string;
}): Promise<any> => {
  const response = await client.getEntries({
    content_type: 'sweepstakeTermsAndConditions',
    'fields.slug': slug,
  });

  return response;
};

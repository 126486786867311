import { getFeatureFlagFromLocalStorage } from '@/store/modules/feature-flags/utils';

import {
  ConstructedEntrySlipPickEmOverUnderAppearance,
  SelectedOverUnder,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { SelectedRival } from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { Boost, PayoutStyle, PayoutStyles, PickEmSlipType } from '@/interfaces/pick-em';
import { UD_PICK_EM } from '@/utilities/constants';

/**
 * the payoutStyle changes based on the makeup of the entrySlip
 * this lets us find the correct payoutStyle base on current lines & slipType
 */
export const getPayoutStyle = ({
  payoutStyles,
  selectedOverUnders,
  selectedRivals,
  slipType,
}: {
  payoutStyles: PayoutStyles;
  selectedOverUnders: SelectedOverUnder[];
  selectedRivals: SelectedRival[];
  slipType: PickEmSlipType;
}): PayoutStyle => {
  const selections = [...selectedOverUnders, ...selectedRivals];

  // Check to see if there's a Payout Booster or Special Line
  const boostedSelectedOverUnder = selectedOverUnders.find((sOU) =>
    sOU.constructedOverUnderAppearance.overUnderLines.find(
      (oUL) => sOU.overUnderLineId === oUL.id && oUL.overUnder.boost
    )
  );

  if (boostedSelectedOverUnder) {
    // returns boost payout style (note: a slip can only ever have one boost)
    const boostedOU = boostedSelectedOverUnder.constructedOverUnderAppearance.overUnderLines.find(
      (oUL) => oUL.overUnder.boost && oUL.id === boostedSelectedOverUnder.overUnderLineId
    );
    return payoutStyles[boostedOU.overUnder.boost.payoutStyleId];
  }

  if (slipType === 'insurance') {
    // returns the insurance payout style, which changes based on the current number of selections in slip
    return Object.values(payoutStyles).find((payoutStyle: PayoutStyle) => {
      if (payoutStyle.insurance) {
        // 0, 1, and 2 selections use the 'insuredSelections: 2' payout style
        if (!selections.length || selections.length <= 2) {
          return payoutStyle.insuredSelections === 2;
        }
        // Otherwise 3, 4, and 5 each have their own insurance payout style
        if (selections.length === payoutStyle.insuredSelections) {
          return true;
        }
      }
      // Otherwise return false if there are no insurance payout styles that match the total number of selections
      return false;
    });
  }

  // default: no selections were boosted, and insurance wasn't selected
  return Object.values(payoutStyles).find((payoutStyle) => payoutStyle.default);
};

/**
 * Check if slip has any boosts, if yes return boost type
 * Boost type will be set to the slipType
 */
export const getBoostType = ({
  selectedOverUnders,
}: {
  selectedOverUnders: SelectedOverUnder[];
}): Boost['boostType'] => {
  const boostedSelectedOverUnder = selectedOverUnders.filter((sOU) =>
    sOU.constructedOverUnderAppearance.overUnderLines.find(
      (oUL) => sOU.overUnderLineId === oUL.id && oUL.overUnder.boost
    )
  )[0];

  if (boostedSelectedOverUnder) {
    // returns boost payout style (slip can only ever have one boost)
    const boostedOU = boostedSelectedOverUnder.constructedOverUnderAppearance.overUnderLines.find(
      (oUL) => oUL.overUnder.boost && oUL.id === boostedSelectedOverUnder.overUnderLineId
    );
    return boostedOU.overUnder.boost.boostType;
  }

  return null;
};

/**
 * this lets us adjust the slipType of the selected PickEmEntrySlip based on
 * the pickEmInsurance feature flag and the localStorage slipType, so we can
 * keep the slipType up to date with every change to the slip
 */
export const adjustSlipType = ({
  boostType,
}: {
  boostType: Boost['boostType'];
}): PickEmSlipType => {
  const insuranceFeatureFlag = getFeatureFlagFromLocalStorage('pickEmInsurance');
  const localStoragePickEm = JSON.parse(localStorage.getItem(UD_PICK_EM));

  if (boostType) {
    return boostType;
  }

  if (!insuranceFeatureFlag && localStoragePickEm?.slipType === 'insurance') {
    // if pick'em insurance is disabled by feature flag, slipType cannot be 'insurance'
    return 'default';
  }

  // local storage might indicate boosted slip type, but boost was removed by the admin
  return localStoragePickEm?.slipType === 'insurance' ? 'insurance' : 'default';
};

// eslint-disable-next-line max-len
export const getPayoutMultiplier = ({
  selectedOverUnders,
  constructedEntrySlipPickEmOverUnderAppearances,
}: {
  selectedOverUnders?: SelectedOverUnder[];
  constructedEntrySlipPickEmOverUnderAppearances?: ConstructedEntrySlipPickEmOverUnderAppearance[];
}) => {
  if (selectedOverUnders) {
    return selectedOverUnders.reduce((acc, curr) => acc * curr.option.payoutMultiplier, 1);
  }
  if (constructedEntrySlipPickEmOverUnderAppearances) {
    return constructedEntrySlipPickEmOverUnderAppearances.reduce((acc, curr) => {
      return acc * curr.overUnderOption.payoutMultiplier;
    }, 1);
  }
  return 1;
};

import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { setError } from '@/store/modules/error/actions';
import {
  errorContestsForSlate,
  getContestsForSlate,
  getSlatesForSport,
  setContestsForSlate,
  setMainLobby,
  setSlatesForSport,
} from '@/store/modules/lobbies/actions';

import * as lobbyApi from '@/api/lobbies';
import * as slateApi from '@/api/slates';
import { ContestsResponse, MainLobbyResponse } from '@/interfaces/lobbies';
import { SlatesResponse } from '@/interfaces/slates';
import errorLogger from '@/utilities/errors/logger';

export const getMainLobbySaga = function* getMainLobbySaga(): SagaIterator {
  try {
    const response = yield call(lobbyApi.getLobby);
    const { data }: { data: MainLobbyResponse } = response;

    yield put(setMainLobby({ data }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
    errorLogger(true, `getMainLobbySaga: ${e}`);
  }
};

export const getSlatesForSportSaga = function* getSlatesForSportSaga(
  action: ReturnType<typeof getSlatesForSport>
): SagaIterator {
  try {
    const response = yield call(slateApi.getSlatesForSport, action.payload);
    const { data }: { data: SlatesResponse } = response;

    yield put(setSlatesForSport({ slates: data, sportId: action.payload.sportId }));
  } catch (e) {
    yield put(setError({ ...e, type: 'toast' }));
    errorLogger(true, `getSlatesForSportSaga: ${e}`);
  }
};

export const getContestsForSlateSaga = function* getContestsForSlateSaga(
  action: ReturnType<typeof getContestsForSlate>
): SagaIterator {
  try {
    const response = yield call(lobbyApi.getContestsForSlate, action.payload);
    const { data }: { data: ContestsResponse } = response;

    yield put(
      setContestsForSlate({
        contests: data.contests,
      })
    );
  } catch (e) {
    yield put(errorContestsForSlate());
    yield put(setError({ ...e, type: 'toast' }));
    errorLogger(true, `getContestsForSlateSaga: ${e}`);
  }
};

import { StateConfigResponse } from '@/interfaces/drafting-config';
import {
  BonusBalanceUpdateResponse,
  IdentificationStatusChangeResponse,
  RegistrationSourceResponse,
  UserCosmeticUpdateResponse,
  UserProfileResponse,
  UserResponse,
  WalletBalanceUpdateResponse,
} from '@/interfaces/user';

export const SET_USER = 'user/SET_USER' as const;
export const SET_USER_REGISTRATION_SOURCE = 'user/SET_USER_REGISTRATION_SOURCE' as const;
export const GET_USER_STATE_CONFIG = 'user/GET_USER_STATE_CONFIG' as const;
export const SET_USER_STATE_CONFIG = 'user/SET_USER_STATE_CONFIG' as const;
export const UPDATE_VERIFY_USER = 'user/UPDATE_VERIFY_USER' as const;
export const UPDATE_NOT_NEW_USER = 'user/UPDATE_NOT_NEW_USER' as const;
export const UPDATE_USER_ID_STATUS = 'user/UPDATE_USER_ID_STATUS' as const;
export const UPDATE_USER_WALLET = 'user/UPDATE_USER_WALLET' as const;
export const UPDATE_USER_BONUS_CASH = 'user/UPDATE_USER_BONUS_CASH' as const;
export const UPDATE_USER_COSMETIC = 'user/UPDATE_USER_COSMETIC' as const;

export const setUser = (payload: {
  data: UserResponse;
  profileData: UserProfileResponse;
  newRegistration?: true; // add optional flag for user having just registered
}) => ({ type: SET_USER, payload });

export const getUserStateConfig = () => ({ type: GET_USER_STATE_CONFIG });

export const setUserStateConfig = (payload: { stateConfigData: StateConfigResponse | null }) => ({
  type: SET_USER_STATE_CONFIG,
  payload,
});

export const updateVerifyUser = (payload: { data: UserResponse }) => ({
  type: UPDATE_VERIFY_USER,
  payload,
});

export const updateNotNewUser = () => ({ type: UPDATE_NOT_NEW_USER });

export const updateUserIdStatus = (payload: IdentificationStatusChangeResponse) => ({
  type: UPDATE_USER_ID_STATUS,
  payload,
});

export const updateUserWallet = (payload: WalletBalanceUpdateResponse) => ({
  type: UPDATE_USER_WALLET,
  payload,
});

export const updateUserBonusCash = (payload: BonusBalanceUpdateResponse) => ({
  type: UPDATE_USER_BONUS_CASH,
  payload,
});

export const updateUserCosmetic = (payload: UserCosmeticUpdateResponse) => ({
  type: UPDATE_USER_COSMETIC,
  payload,
});

export const setUserRegistrationSource = (payload: RegistrationSourceResponse) => ({
  type: SET_USER_REGISTRATION_SOURCE,
  payload,
});

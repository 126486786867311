/* eslint-disable camelcase */
import { pickEmRivalLineConstructor } from '@/store/constructors/pick-em-rival-appearance-constructor';

import {
  ConstructedPickEmRivalLine,
  PickEmRival,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { DraftingConfig, StateConfig } from '@/interfaces/drafting-config';
import {
  FavoritePlayer,
  FavoritePlayersResponse,
  RivalLine,
  RivalLinesResponse,
} from '@/interfaces/pick-em';
import { Role } from '@/interfaces/user';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import appearanceAdapter from '../../constructed-slates/adapters/appearance';
import playerAdapter from '../../constructed-slates/adapters/player';
import matchAdapter from '../../matches/adapters/match';
import {
  ADD_FAVORITE_PICK_EM_PLAYER,
  addFavoritePickEmPlayer,
  REMOVE_FAVORITE_PICK_EM_PLAYER,
  REMOVE_PICK_EM_RIVAL_LINE,
  removeFavoritePickEmPlayer,
  removePickEmRivalLine,
  SET_PICK_EM_RIVAL_LINES,
  setPickEmRivalLines,
} from '../actions';
import rivalLineAdapter from '../adapters/rival-line';
import soloGameAdapter from '../adapters/solo-game';

type State = PickEmRival;

type PickEmRivalActions = ReturnType<
  | typeof setPickEmRivalLines
  | typeof removePickEmRivalLine
  | typeof addFavoritePickEmPlayer
  | typeof removeFavoritePickEmPlayer
>;

const initialState: PickEmRival = {
  rivalLines: null,
  appearances: null,
  games: null,
  players: null,
  soloGames: null,
  constructedPickEmRivals: null,
};

const set = ({
  data,
  favoritePlayers,
  draftingConfig,
  pickEmStateConfig,
  roles,
}: {
  data: RivalLinesResponse;
  favoritePlayers: FavoritePlayersResponse['favorite_players'];
  draftingConfig: DraftingConfig;
  pickEmStateConfig: StateConfig['pickEm'];
  roles: Role[];
}): PickEmRival => {
  const {
    rival_lines,
    appearances: appearancesResponse,
    games: gamesResponse,
    players: playersResponse,
    solo_games,
  } = data;
  const isAdmin = roles.includes('admin');

  const favoritePlayerIds = favoritePlayers.map((fPlayer) => fPlayer.id);

  const rivalLines = rival_lines.map(rivalLineAdapter).filter((rL) => {
    if (isAdmin) return true;
    if (!pickEmStateConfig.visibleStats) return true;
    return pickEmStateConfig.visibleStats.includes(rL.options[0].appearanceStat.stat);
  });
  const appearances = appearancesResponse.map(appearanceAdapter);
  const games = arrayToObjectIdKeys(gamesResponse.map(matchAdapter));
  const soloGames = arrayToObjectIdKeys(solo_games.map(soloGameAdapter));
  const players = arrayToObjectIdKeys(
    playersResponse.map((player) => {
      const adaptedPlayer = playerAdapter(player);
      if (!favoritePlayerIds?.length) {
        return adaptedPlayer;
      }
      if (favoritePlayerIds.includes(player.id)) {
        return {
          ...adaptedPlayer,
          favorite: true,
        };
      }
      return adaptedPlayer;
    })
  );

  const { teams, lineupStatuses, sports } = draftingConfig;

  let constructedPickEmRivals = appearances
    ? pickEmRivalLineConstructor({
        appearances,
        games,
        lineupStatuses,
        rivalLines,
        players,
        soloGames,
        sports,
        teams,
      })
    : null;

  if (constructedPickEmRivals && !isAdmin && pickEmStateConfig.visibleSports) {
    constructedPickEmRivals = constructedPickEmRivals.filter(
      (cPERL) =>
        pickEmStateConfig.visibleSports.includes(cPERL.appearanceOptions[0].match?.sportId) ||
        pickEmStateConfig.visibleSports.includes(cPERL.appearanceOptions[0].soloGame?.sportId)
    );
  }

  return {
    rivalLines,
    appearances,
    games,
    players,
    soloGames,
    constructedPickEmRivals,
  };
};

const remove = (state: PickEmRival, { data }: { data: string[] }): PickEmRival => {
  const newRivalLines: RivalLine[] = state.rivalLines.map((rL: RivalLine) => {
    if (data.includes(rL.id)) {
      return {
        ...rL,
        status: 'removed',
      };
    }
    return rL;
  });

  const newCPERs: ConstructedPickEmRivalLine[] = state.constructedPickEmRivals.map(
    (cPER: ConstructedPickEmRivalLine) => {
      if (data.includes(cPER.id)) {
        return {
          ...cPER,
          rival: {
            ...cPER.rival,
            status: 'removed',
          },
          status: 'removed',
        };
      }
      return cPER;
    }
  );

  return {
    rivalLines: newRivalLines,
    appearances: state.appearances,
    games: state.games,
    players: state.players,
    soloGames: state.soloGames,
    constructedPickEmRivals: newCPERs,
  };
};

// TODO [FAN-2282]: look into this it doesn't feel very efficient
const addFavoritePlayer = (
  state: PickEmRival,
  { player }: { player: FavoritePlayer }
): PickEmRival => {
  // this is to stop accidentally adding inactive players to the redux for open pick'ems
  // this could happen if you 'undo' removing an inactive pick
  if (player.isInactive) {
    return state;
  }

  const newCPERs: ConstructedPickEmRivalLine[] = state.constructedPickEmRivals.map(
    (cPER: ConstructedPickEmRivalLine) => {
      const favoritedPlayer = cPER.appearanceOptions.find((aO) => aO.player.id === player.id);
      if (favoritedPlayer) {
        const newAppearanceOptions = cPER.appearanceOptions.map((aO) => {
          if (aO.player.id === player.id) {
            return {
              ...aO,
              player: {
                ...aO.player,
                favorite: true,
              },
            };
          }
          return aO;
        });

        return {
          ...cPER,
          appearanceOptions: newAppearanceOptions,
        };
      }
      return cPER;
    }
  );

  const newPlayer = {
    ...player,
    favorite: true,
  };

  return {
    ...state,
    players: {
      ...state.players,
      [newPlayer.id]: newPlayer,
    },
    constructedPickEmRivals: newCPERs,
  };
};

// TODO [FAN-2282]: look into this it doesn't feel very efficient
const removeFavoritePlayer = (
  state: PickEmRival,
  { playerId }: { playerId: string }
): PickEmRival => {
  const newPlayers = { ...state.players };

  if (!newPlayers[playerId]) {
    return state;
  }

  const newCPERs: ConstructedPickEmRivalLine[] = state.constructedPickEmRivals.map(
    (cPER: ConstructedPickEmRivalLine) => {
      const favoritedPlayer = cPER.appearanceOptions.find((aO) => aO.player.id === playerId);
      if (favoritedPlayer) {
        const newAppearanceOptions = cPER.appearanceOptions.map((aO) => {
          if (aO.player.id === playerId) {
            return {
              ...aO,
              player: {
                ...aO.player,
                favorite: false,
              },
            };
          }
          return aO;
        });

        return {
          ...cPER,
          appearanceOptions: newAppearanceOptions,
        };
      }
      return cPER;
    }
  );

  newPlayers[playerId] = {
    ...newPlayers[playerId],
    favorite: false,
  };

  return {
    ...state,
    players: newPlayers,
    constructedPickEmRivals: newCPERs,
  };
};

export const pickEmRivalLineReducer = (
  state: State = initialState,
  action: PickEmRivalActions = {} as PickEmRivalActions
): State => {
  switch (action.type) {
    case SET_PICK_EM_RIVAL_LINES:
      return set(action.payload);
    case REMOVE_PICK_EM_RIVAL_LINE:
      return remove(state, action.payload);
    case ADD_FAVORITE_PICK_EM_PLAYER:
      return addFavoritePlayer(state, action.payload);
    case REMOVE_FAVORITE_PICK_EM_PLAYER:
      return removeFavoritePlayer(state, action.payload);
    default:
      return state;
  }
};

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as braze from '@braze/web-sdk';
import * as branch from 'branch-sdk';

import Modal from '@/components/atoms/modal';
import Toast from '@/components/atoms/toast';
import { BranchProvider } from '@/components/contexts/branch';
import { PusherEventProvider } from '@/components/contexts/pusher-events';
import { ThemeProvider, ThemeWrapper } from '@/components/contexts/theme';
import { watchLocationPermissionChanges } from '@/utilities/location';

import { AuthProvider } from './components/contexts/auth';
import { NavigationProvider } from './components/contexts/navigation';
import { UIContextProvider } from './components/contexts/ui/index';
import App from './app';
import configureStore from './store';

declare global {
  interface Window {
    branch: any; // branch
    dataLayer: any; // google tag manager
    DD_LOGS: any; // datadog logs
    Socure: any; // socure
    SocureInitializer: any; // socure
    zE: any; // zendesk
    GcHtml5: any; // geocomply
    _sift: any; // sift
  }
}

if (braze) {
  braze.initialize(process.env.BRAZE_API_KEY, {
    baseUrl: process.env.BRAZE_SDK_ENDPOINT,
    manageServiceWorkerExternally: true,
    disablePushTokenMaintenance: false,
    safariWebsitePushId: process.env.BRAZE_SAFARI_DOMAIN,
  });
  braze.automaticallyShowInAppMessages();
}

if (branch) {
  branch.init(process.env.BRANCH_KEY);
}

watchLocationPermissionChanges();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

const renderApp = () => {
  const store = configureStore();
  root.render(
    <Provider store={store}>
      <PusherEventProvider>
        <ThemeProvider>
          <ThemeWrapper>
            <BranchProvider>
              <Router>
                <UIContextProvider>
                  <AuthProvider>
                    <NavigationProvider>
                      <App />
                      <Modal />
                      <Toast />
                    </NavigationProvider>
                  </AuthProvider>
                </UIContextProvider>
              </Router>
            </BranchProvider>
          </ThemeWrapper>
        </ThemeProvider>
      </PusherEventProvider>
    </Provider>
  );
};

renderApp();

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { ActiveDraft } from '@/interfaces/active-drafts';
import {
  ConstructedAppearance,
  ConstructedAppearanceDrafting,
} from '@/interfaces/constructed-interfaces/constructed-appearance';
import {
  ConstructedDraftEntry,
  ConstructedPick,
  ConstructedPositionCount,
} from '@/interfaces/constructed-interfaces/constructed-draft-entry';
import {
  ConstructedPickEmOverUnderLineAppearance,
  SelectedOverUnder,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { SelectedRival } from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import {
  ContestStyle,
  EntryStyle,
  EntryStyles,
  PickSlots,
  Slot,
  Slots,
  Sport,
  Sports,
  SportStatus,
  SportStatusKey,
} from '@/interfaces/drafting-config';
import { DraftEntry, Source, Status } from '@/interfaces/drafts';
import { SelectedOption } from '@/interfaces/pick-em';
import { User } from '@/interfaces/user';
import { MIN_INSURED_PICKS } from '@/utilities/constants';

dayjs.extend(duration);

export const toCamel = (s?: string): string => {
  if (!s) return null;
  return s.replace(/([-_][a-z])/gi, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''));
};

export const toSnake = (s?: string): string => {
  if (!s) return null;
  return s[0]
    .toLowerCase()
    .concat(s.substring(1))
    .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
    .replace(/([-])/g, '_');
};

// eslint-disable-next-line max-len
export const arrayToObjectIdKeys = <T extends Record<string, any>>(
  array: T[],
  idToUse = 'id'
): { [id: string]: T } => {
  if (!array || array.length < 1) return {};
  return array.reduce(
    (acc, curr) => {
      acc[curr[idToUse]] = curr;
      return acc;
    },
    {} as { [id: string]: T }
  );
};

export const numberToOrdinal = (num: number | string): string => {
  if (!num && num !== 0) return null;
  const stringNum = num.toString();
  const longSubString = stringNum.slice(-2);
  if (longSubString === '11' || longSubString === '12' || longSubString === '13') {
    return `${stringNum}th`;
  }
  const shortSubString = stringNum.slice(-1);
  if (shortSubString === '1') return `${stringNum}st`;
  if (shortSubString === '2') return `${stringNum}nd`;
  if (shortSubString === '3') return `${stringNum}rd`;

  return `${stringNum}th`;
};

/**
 * Given a pick number and constructedDraftEntries, get the constructedDraftEntry
 * for that pick
 */

export const draftEntryForPickNumber = ({
  pickNumber,
  entries,
}: {
  pickNumber: number;
  entries: ConstructedDraftEntry[];
}): ConstructedDraftEntry => {
  if (!pickNumber || !entries || !entries.length) return null;

  // clone the array with [...], because we reverse it here, and we don't want to do
  // that by reference
  const sortedConstructedDraftEntries = [
    ...entries.sort(
      (a: DraftEntry | ConstructedDraftEntry, b: DraftEntry | ConstructedDraftEntry) =>
        a.pickOrder - b.pickOrder
    ),
  ];

  const index = pickNumber - 1;
  // draftEntries.length I think is also totalEntryCount
  const roundNumber = Math.floor(index / entries.length) + 1;
  const draftEntriesRoundIndex = index % entries.length;
  return roundNumber % 2 !== 0
    ? sortedConstructedDraftEntries[draftEntriesRoundIndex]
    : sortedConstructedDraftEntries.reverse()[draftEntriesRoundIndex];
};

export interface RoundAndPick {
  roundNumber: number;
  pickWithinRound: number;
  pickNumber: number;
}

export const roundAndPickForPickNumber = ({
  pickNumber,
  totalEntryCount,
}: {
  pickNumber: number;
  totalEntryCount: number;
}): RoundAndPick => {
  const roundNumber = Math.floor((pickNumber - 1) / totalEntryCount) + 1;
  const pickWithinRound = ((pickNumber - 1) % totalEntryCount) + 1;
  return {
    roundNumber,
    pickWithinRound,
    pickNumber,
  };
};

export const normalizeText = (text: string) =>
  // https://www.davidbcalhoun.com/2019/matching-accented-strings-in-javascript/
  text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

// draft statuses
// live = 'live'
// results  = 'settled'
// pre-live = 'pending' | 'unfilled' | 'filled' | 'drafting' | 'completed'
// no display = 'cancelled | 'dead'

export const statusIsPreLive = (status: Status): boolean => {
  if (
    status === 'pending' ||
    status === 'unfilled' ||
    status === 'filled' ||
    status === 'drafting' ||
    status === 'completed'
  )
    return true;
  return false;
};

export const statusIsLiveResults = (status: Status): boolean => {
  if (status === 'live' || status === 'settled') return true;
  return false;
};

export const getConstructedSlateKey = ({
  slateId,
  scoringTypeId,
}: {
  slateId: string;
  scoringTypeId: string;
}): string => {
  if (!slateId || !scoringTypeId) return null;
  return `${slateId}-${scoringTypeId}`;
};

// I would ideally love to use this function for constructedDraftEntries and draftEntries
// but I don't know the conditional return type syntax for typescript
// export function draftEntryForPickNumber <T extends ConstructedDraftEntry[] | DraftEntry[]>({ pickNumber, entries }:
//   { pickNumber: number; entries: T }):
//   (T extends ConstructedDraftEntry ? ConstructedDraftEntry : DraftEntry) {
//   if (!pickNumber || !entries) return null;

//   const sortedConstructedDraftEntries = entries.sort(
//     (a: DraftEntry | ConstructedDraftEntry,
//       b: DraftEntry | ConstructedDraftEntry) => a.pickOrder - b.pickOrder,
//   );
//   const index = pickNumber - 1;
//   // draftEntries.length I think is also totalEntryCount
//   const roundNumber = Math.floor(index / entries.length) + 1;
//   const draftEntriesRoundIndex = index % entries.length;
//   return roundNumber % 2 !== 0
//     ? sortedConstructedDraftEntries[draftEntriesRoundIndex]
//     : sortedConstructedDraftEntries.reverse()[draftEntriesRoundIndex];
// };

/**
 * TODO [FAN-2425]: Test this fucking wild looping thing
 *
 * This converts the slots into the initial positionCounter
 * It turns slots into an array and filters out the slots that we don't
 * want. It then returns an array position count shapes with a 'count'
 * property that's set to 0.
 *
 * Also not super convinced that this belongs in the helper file, it might
 * be its own constructor
 * */
export const slotsForADraft = ({
  slots,
  pickSlots,
}: {
  slots: Slots;
  pickSlots: PickSlots;
}): ConstructedPositionCount[] => {
  // using a set to ensure uniqueness
  const uniqueSlotsIdsFromPickSlots = new Set(
    Object.keys(pickSlots).map((key) => pickSlots[key].slotId)
  );

  return (
    Object.keys(slots)
      .map((key) => slots[key])
      // TODO [FAN-2425]: remove the check for FLEX once this is fixed on the api
      .filter((slot) => slot.displayType === 'always' && uniqueSlotsIdsFromPickSlots.has(slot.id))
      .map((filteredSlot) => ({
        slotId: filteredSlot.id,
        positionName: filteredSlot.name,
        color: filteredSlot.color,
        count: 0,
        positionIds: filteredSlot.positionIds,
        rank: filteredSlot.rank,
      }))
      .sort((a, b) => a.rank - b.rank)
  );
};

/**
 *
 * @param { slots, positionId }
 *
 * Based on the positionId, get the slots that that position fits in
 * When constructing appearances, this is used to get the slot
 */
export const slotsForPosition = ({
  slots,
  positionId,
}: {
  slots: Slot[];
  positionId: string;
}): Slot[] => slots.filter((slot) => slot.positionIds.includes(positionId));

/**
 *
 * @param { slots, pickSlots }
 *
 * Get all of the slots that count as scoring for a Draft, this is mostly used
 * for calculating the projected points for a bestBall. We need to know what slots
 * count as scoring, and then we'll loop through this and the appearances and get
 * the highest projection for that slot
 */
export const allScoringSlotsForADraft = ({
  slots,
  pickSlots,
}: {
  slots: Slots;
  pickSlots: PickSlots;
}): ConstructedPositionCount[] =>
  Object.keys(pickSlots)
    .map((key) => slots[pickSlots[key].slotId])
    .filter((slot) => slot.scoring)
    .map((filteredSlot) => ({
      slotId: filteredSlot.id,
      positionName: filteredSlot.name,
      color: filteredSlot.color,
      count: 0,
      positionIds: filteredSlot.positionIds,
      rank: filteredSlot.rank,
    }))
    .sort((a, b) => a.rank - b.rank);

// TODO [FAN-2423]: finish this helper function
export const getProjectedPoints = ({
  slots,
  pickSlots,
}: {
  picks: ConstructedPick[];
  slots: Slots;
  pickSlots: PickSlots;
}): ConstructedPositionCount[] => {
  const scoringSlots = allScoringSlotsForADraft({ slots, pickSlots });
  // The idea is that you have all the scoring slots ^^ and then you
  // sort the picks by tops scoring, and put them into the a slot that they
  // fit and then you remove them, and once all the scoringSlots have a pick
  // or all the picks are used up, then return the total projections
  return scoringSlots;
};

export const countUntilNextPick = ({
  pickOrder,
  pickCount,
  contestStyle,
  entryStyle,
}: {
  pickOrder: number | null;
  contestStyle: ContestStyle;
  pickCount: number; // pickCount can be 0, 0 pickCount means pick 1.1, 7 pickCount means pick 1.8
  entryStyle: EntryStyle;
}): number => {
  if (!pickOrder) return null;
  const currentPickCount = pickCount + 1; // logically make 1 === pick 1.1, and 9 pick 2.1 for an 8 man draft
  const roundCount = contestStyle.rounds; // total rounds
  const { entryCount } = entryStyle; // picks per round
  const lastCompletedRound = Math.floor((currentPickCount - 1) / entryCount);
  const currentRound = lastCompletedRound + 1;
  // get the pick number for the current user and find out if it's before or after the current pick number
  const pickNumberForCurrentRound =
    lastCompletedRound * entryCount +
    (currentRound % 2 === 1 ? pickOrder : entryCount - pickOrder + 1);

  if (pickNumberForCurrentRound >= currentPickCount) {
    return pickNumberForCurrentRound - currentPickCount;
  }

  if (currentRound + 1 > roundCount) {
    // Team is full
    return null;
  }

  const pickNumberForNextRound =
    currentRound * entryCount +
    // if odd add pick number, if even add entryCount minus pickOrder plus 1
    ((currentRound + 1) % 2 === 1 ? pickOrder : entryCount - pickOrder + 1);

  return pickNumberForNextRound - currentPickCount;
};

export const isOnTheClock = ({
  pickOrder,
  pickCount,
  contestStyle,
  entryStyle,
}: {
  pickOrder: number;
  contestStyle: ContestStyle;
  pickCount: number;
  entryStyle: EntryStyle;
}): boolean =>
  countUntilNextPick({
    pickOrder,
    pickCount,
    contestStyle,
    entryStyle,
  }) === 0;

export const sortUnfilledActiveDrafts = ({
  activeDrafts,
  entryStyles,
}: {
  activeDrafts: ActiveDraft[];
  entryStyles: EntryStyles;
}): ActiveDraft[] =>
  activeDrafts.sort((a, b) => {
    const entryStyleA = entryStyles[a.entryStyleId];
    const entryStyleB = entryStyles[b.entryStyleId];
    if (a.entryCount / entryStyleA.entryCount > b.entryCount / entryStyleB.entryCount) {
      return -1;
    }
    if (a.entryCount / entryStyleA.entryCount === b.entryCount / entryStyleB.entryCount) {
      if (entryStyleA.entryCount - a.entryCount < entryStyleB.entryCount - b.entryCount) {
        return -1;
      }
      if (entryStyleA.entryCount - a.entryCount === entryStyleB.entryCount - b.entryCount) {
        return 0;
      }
    }
    return 1;
  });

// doesn't handle negative time, will return 0
export const getTimeFromNowInSeconds = (timeString: string): number => {
  const now = dayjs();
  const time = dayjs(timeString);

  const timeDiff = dayjs.duration(time.diff(now)).asSeconds();
  const displayTime = timeDiff > 0 ? Math.floor(timeDiff) : 0;
  const formattedTime = dayjs.unix(0).add(displayTime, 'second').second();
  return formattedTime;
};

export type NumberToTimeOptions = {
  timestampFormat?: string; // dayjs time format, eg. hh:mm:ss
  timestampFormatCutoff?: number; // time at which timestamp should be used (seconds)
  preTimestampFormatLabel?: string; // trailing string, defaults to 'hr'
};

export const numberToTime = (time: number, options?: NumberToTimeOptions) => {
  const {
    timestampFormat = 'mm:ss',
    timestampFormatCutoff = 3600,
    preTimestampFormatLabel = 'hr',
  } = options || {};

  if (!time && time !== 0) {
    return null;
  }

  if (time >= timestampFormatCutoff) {
    // time returns as a labeled unit of time, eg. 8hr or 12 days
    const formattedTime = Math.floor(time / timestampFormatCutoff);
    return `${formattedTime}${preTimestampFormatLabel}`;
  }

  // time returns as a timestamp based on duration format, eg 'HH:mm:ss' or 'mm:ss'
  const durationInSeconds = dayjs.duration(time, 'seconds');
  const hasMonths = durationInSeconds.months() >= 1;
  let formattedTime = durationInSeconds.format(timestampFormat);
  // handle more than 30 days in that weird case
  if (/^DD:/.test(timestampFormat) && hasMonths) {
    const newFormat = timestampFormat.replace('DD:', '');
    formattedTime = `${Math.floor(durationInSeconds.asDays())}:${durationInSeconds.format(
      newFormat
    )}`;
  }

  return formattedTime;
};

export const numberToClock = (time: number): string | null => {
  const seconds = time % 60;
  const minutes = Math.floor((time % 3600) / 60);
  if (!time) return null;
  if (time < 60) {
    return `${time} ${time === 1 ? 'second' : 'seconds'}`;
  }
  if (time % 3600 === 0) {
    return `${time / 3600} hr`;
  }
  if (time < 3600) {
    if (time % 60 < 10) {
      return `${minutes}:0${seconds} ${minutes === 1 ? 'min' : 'mins'}`;
    }
    return `${minutes}:${seconds} mins`;
  }
  if (time % 3600 < 600) {
    return `${Math.floor(time / 3600)}:0${minutes} hr`;
  }
  return `${Math.floor(time / 3600)}:${minutes} hr`;
};

// use by `await stall(3000)` to delay an action by 3 seconds
export const stall = async (stallTime = 1000): Promise<void> => {
  await new Promise((resolve) => {
    setTimeout(resolve, stallTime);
  });
};

export const isBestBall = (contestStyle: ContestStyle): boolean => {
  if (!contestStyle || !contestStyle.name) return false;
  return contestStyle.name.toLowerCase().indexOf('best ball') >= 0;
};

export const slotCountsForContest = ({
  contestStyle,
  slots,
}: {
  contestStyle: ContestStyle;
  slots: Slots;
}): { slot: Slot; count: number }[] => {
  const { pickSlots } = contestStyle;
  const slotIdArr = Object.keys(pickSlots).map((key) => pickSlots[key].slotId);
  const uniqueSlotIds = slotIdArr.filter((slotId, index, arr) => arr.indexOf(slotId) === index);

  return uniqueSlotIds.map((slotId) => {
    const slot = slots[slotId];
    const count = slotIdArr.filter((id) => id === slotId).length;
    return {
      slot,
      count,
    };
  });
};

export const validPassword = (string: string) =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).{8,128}$/.test(string);

export const validDob = (string: string) =>
  !dayjs(string, 'MM/DD/YYYY').isAfter(dayjs().subtract(18, 'year'));

export const validEmail = (string: string) =>
  // can't start with special character
  // no consecutive special characters
  // prefix length greater than 0
  // prefix can include letters, numbers, -, +, ., _
  // domain length greater 1
  // domain can include letters, numbers, -,
  // top-level domain length greater than 1
  // top-level domain can include letters

  // eslint-disable-next-line max-len
  /^[a-zA-Z0-9](?!.*[\W]{2})[\w|\-|+|.]{0,}@([a-zA-Z0-9|-]){2,}(\.{1}[a-zA-Z]{2,})*\.([a-zA-Z]{2,})$/.test(
    string
  );

export const displayDob = (dobValue: string) => {
  if (dobValue.length > 4) {
    return `${dobValue.slice(0, 2)}/${dobValue.slice(2, 4)}/${dobValue.slice(4)}`;
  }
  if (dobValue.length > 2) {
    return `${dobValue.slice(0, 2)}/${dobValue.slice(2, 4)}`;
  }
  return dobValue;
};

export const displayPhoneNumber = (phoneNumber: string) => {
  if (phoneNumber.length > 6) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  }
  if (phoneNumber.length > 3) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  }
  return phoneNumber;
};

// returns first active sport object
export const getInitialDraftSport = (sports: Sports) =>
  Object.values(sports)
    .sort((a, b) => a.rank - b.rank)
    .find((sport) => sport.draftStatus === SportStatus.ACTIVE);

export const getSportsByStatus = ({
  sports,
  sportStatusKey,
  activeOnly,
}: {
  sports: Sports;
  // eslint-disable-next-line max-len
  sportStatusKey: SportStatusKey;
  activeOnly?: boolean; // this is page specific
}): Sport[] => {
  if (!sports) return [];

  return Object.values(sports)
    .filter((sport) =>
      activeOnly
        ? sport[sportStatusKey] === SportStatus.ACTIVE
        : sport[sportStatusKey] !== SportStatus.HIDDEN
    )
    .sort((a, b) => {
      /* eslint-disable max-len */
      // sort active before disabled and status groups by rank
      if (a[sportStatusKey] === SportStatus.DISABLED && b[sportStatusKey] !== SportStatus.DISABLED)
        return 1;
      if (a[sportStatusKey] !== SportStatus.DISABLED && b[sportStatusKey] === SportStatus.DISABLED)
        return -1;
      if (a[sportStatusKey] === b[sportStatusKey]) return a.rank - b.rank;
      /* eslint-enable max-len */
      return 0;
    });
};

export const isValidSportAndStatus = ({
  sports,
  sportId,
  sportStatusKey,
  allowedSportStatusValues, // these are page specific
}: {
  sports: Sport[];
  sportId: string;
  // eslint-disable-next-line max-len
  sportStatusKey: SportStatusKey;
  allowedSportStatusValues: (SportStatus.ACTIVE | SportStatus.DISABLED | SportStatus.HIDDEN)[];
}): boolean => {
  if (!sports?.length) return false;

  const sport = sports.find((currSport: Sport) => currSport.id === sportId?.toUpperCase());

  if (!sport) return false;

  return allowedSportStatusValues.includes(sport[sportStatusKey]);
};

// eslint-disable-next-line max-len
export const sortAppearancesByDate = (arr: ConstructedPickEmOverUnderLineAppearance[]) =>
  arr?.sort((prev, curr) => {
    // decide where date comes b/c
    // pickem appearances can show matches or solo games
    const currDate = curr?.match ? curr.match.scheduledAt : curr.soloGame.scheduledAt;
    const currentDate = dayjs(currDate);
    const prevDate = prev?.match ? prev.match.scheduledAt : prev.soloGame.scheduledAt;
    // sort by date ASC

    if (currentDate.isBefore(prevDate)) return 1;
    if (currentDate.isAfter(prevDate)) return -1;
    if (currentDate.isSame(prevDate)) {
      return curr?.id < prev?.id ? 1 : -1;
    }
    return 0;
  });

export const removeHTMLFromString = (input: string) => input.replace(/<[^>]*>?/gm, '');

export const validMaxLength = ({ maxLength, value }: { maxLength: number; value: string }) =>
  value.length < maxLength;

export const validMinLength = ({ minLength, value }: { minLength: number; value: string }) =>
  value.length > minLength;

// From https://polvara.me/posts/five-things-you-didnt-know-about-testing-library
export const reactGetByTextHelper = (string: string) => (_content: any, node: any) => {
  const hasText = (el: Node) => el.textContent.replace('\u00a0', ' ').includes(string);
  const nodeHasText = hasText(node);
  const childrenDontHaveText = Array.from(node.children).every((child: any) => !hasText(child));

  return nodeHasText && childrenDontHaveText;
};

export const sameTeamPicksCheck = ({
  selectedOptions,
  selectedOverUnders,
  selectedRivals,
}: {
  selectedOptions: SelectedOption[];
  selectedOverUnders: SelectedOverUnder[];
  selectedRivals: SelectedRival[];
}): {
  isValid: boolean;
  error: string;
} => {
  const teamsArray = selectedOptions.reduce((acc, selectedOption) => {
    const selectedOverUnder = selectedOverUnders.find((sOU) => sOU.option.id === selectedOption.id);
    const selectedRival: SelectedRival = selectedRivals.find(
      (sR) => sR.option.id === selectedOption.id
    );

    if (selectedOverUnder) {
      if (!selectedOverUnder.constructedOverUnderAppearance.team) {
        return [...acc, 'noTeam'];
      }

      if (acc.indexOf(selectedOverUnder.constructedOverUnderAppearance.team?.name) >= 0) {
        return acc;
      }
      return [...acc, selectedOverUnder.constructedOverUnderAppearance.team?.name];
    }

    if (selectedRival) {
      if (!selectedRival.constructedPickEmRivalAppearance.team) {
        return [...acc, 'noTeam'];
      }
      if (acc.indexOf(selectedRival.constructedPickEmRivalAppearance.team?.name) >= 0) {
        return acc;
      }
      return [...acc, selectedRival.constructedPickEmRivalAppearance.team?.name];
    }

    return acc;
  }, []);

  if (selectedOptions.length <= 1 || teamsArray.length >= 2) {
    return {
      isValid: true,
      error: null,
    };
  }

  return {
    isValid: false,
    error: 'Your current selections cannot be submitted. Please select multiple teams.',
  };
};

export const getMinInsuredPicks = (userMinSelections: number) =>
  Math.max(MIN_INSURED_PICKS, userMinSelections ?? 0);

export const getSortedPicks = ({
  picks,
  selectedWeekId,
  shouldUseAppearancePoints,
}: {
  picks: ConstructedPick[];
  selectedWeekId: number;
  shouldUseAppearancePoints: boolean;
}) => {
  if (!picks) return null;
  return picks.sort((a, b) => {
    // sort the picks by points
    if (selectedWeekId === 0) return 0;
    if (shouldUseAppearancePoints) {
      if (!b.appearance?.score?.points) {
        return -1;
      }
      if (!a.appearance?.score?.points) {
        return 1;
      }
      // eslint-disable-next-line max-len
      return parseFloat(b.appearance?.score?.points) - parseFloat(a.appearance?.score?.points);
    }
    if (!b.points) return -1;
    if (!a.points) return 1;
    return parseFloat(b.points) - parseFloat(a.points);
  });
};

export interface AvailableSlots {
  name: string;
  slotCount: number;
  slot: Slot;
  acceptedPositionIds: string[];
}

export const getPicksWithAvailSlots = ({
  pickSlots,
  slots,
  sortedPicks,
}: {
  pickSlots: PickSlots;
  slots: Slots;
  sortedPicks: ConstructedPick[];
}) => {
  if (!sortedPicks) return null;

  const availableSlots = Object.values(pickSlots)
    .reduce((acc, curr) => {
      // get all the slots and a count of how many times they score
      const slot = slots[curr.slotId];
      const currentAvailableSlotIndex = acc.findIndex((aS) => aS.name === slot.name);
      if (currentAvailableSlotIndex >= 0) {
        acc[currentAvailableSlotIndex] = {
          ...acc[currentAvailableSlotIndex],
          slotCount: acc[currentAvailableSlotIndex].slotCount + 1,
        };
        return acc;
      }

      return [
        ...acc,
        {
          name: slot.name,
          slotCount: 1,
          acceptedPositionIds: slot.positionIds,
          slot,
        },
      ];
    }, [] as AvailableSlots[])
    .sort((a, b) => {
      if (a.slot.rank === b.slot.rank) {
        if (a.slot.scoring) return -1;
        if (b.slot.scoring) return 1;
        return 0;
      }
      return a.slot.rank - b.slot.rank;
    });

  return sortedPicks.map((pick) => {
    // add the slotForColorBar to the sortedPicks
    const availableSlot = availableSlots.find(
      (availableObject) =>
        availableObject.slotCount > 0 &&
        availableObject.slot.positionIds.includes(pick.appearance.positionId)
    );

    if (!availableSlot) {
      // something is messed up with the data if this happens
      return {
        ...pick,
        slotForColorBar: pick.appearance.slot,
      };
    }

    availableSlot.slotCount -= 1;

    return {
      ...pick,
      slotForColorBar: availableSlot?.slot,
    };
  });
};

export const floorAndFix = (number: number) => {
  const formattedNumber = Number(`${number}e2`);

  return Number(`${Math.floor(formattedNumber)}e-2`);
};

export const getIsPoolsEnabled = ({
  user,
  isForceClassicPickemVisibleFeatureFlagEnabled,
  isForcePickemPoolsVisibleFeatureFlagEnabled,
}: {
  user: User;
  isForceClassicPickemVisibleFeatureFlagEnabled: boolean;
  isForcePickemPoolsVisibleFeatureFlagEnabled: boolean;
}): boolean => {
  let isPoolsEnabled: boolean = false;

  if (isForceClassicPickemVisibleFeatureFlagEnabled) {
    // return immediately, with isPoolsEnabled = false
    // forcing classic pickem to render
    return isPoolsEnabled;
  }

  if (isForcePickemPoolsVisibleFeatureFlagEnabled) {
    // force pick em pools visible
    isPoolsEnabled = true;
  } else {
    // check if user is an admin and if so, make pools.enabled false
    if (user.roles.includes('admin')) {
      // admins ignore the state config
      isPoolsEnabled = false;
    }
    // if user is not an admin
    // set isPoolsEnabled to whatever their state config says
    isPoolsEnabled = user.stateConfig?.pickEm.pickemPoolsVisible;
  }

  return isPoolsEnabled;
};

export const isAuth0Enabled = ({
  email,
  isAuth0FeatureEnabled,
  isAuth0RolloutSignInFeatureEnabled,
  isAuth0RolloutSignUpFeatureEnabled,
  type,
}: {
  email: string;
  isAuth0FeatureEnabled: boolean;
  isAuth0RolloutSignInFeatureEnabled: boolean;
  isAuth0RolloutSignUpFeatureEnabled: boolean;
  type: 'signIn' | 'signUp';
}): boolean => {
  if (isAuth0FeatureEnabled) {
    const isUnderdogEmail = email.match(/underdogfantasy.com|und.dog/);
    if (isUnderdogEmail) {
      // if auth0 is enabled and user is admin (UD email), always set to true
      return true;
    }
    if (type === 'signIn') {
      return isAuth0RolloutSignInFeatureEnabled;
    }
    if (type === 'signUp') {
      return isAuth0RolloutSignUpFeatureEnabled;
    }
  }
  return false;
};

// note: this is not an exhaustive list of types, but covers the essentials
const prototypeStringsMap = {
  '[object Null]': 'null',
  '[object Undefined]': 'undefined',
  '[object String]': 'string',
  '[object Number]': 'number',
  '[object Boolean]': 'boolean',
  '[object Symbol]': 'symbol',
  '[object Function]': 'function',
  '[object Object]': 'object',
  '[object Array]': 'array',
  '[object Date]': 'date',
  '[object RegExp]': 'regexp',
} as const;

type PlainObject = Record<string | number | symbol, any>;

export function isPlainObject(thing: unknown): thing is PlainObject {
  if (getType(thing) !== 'object') {
    return false;
  }
  const prototype = Object.getPrototypeOf(thing) === Object.prototype;
  return Boolean(prototype && prototype.constructor === Object && prototype === Object.prototype);
}

function getType(thing: unknown) {
  const prototypeString = Object.prototype.toString.call(thing);
  const result = prototypeStringsMap[prototypeString as keyof typeof prototypeStringsMap];
  if (result) {
    return result;
  }
  return 'unknown';
}

export type DisplayStatKeys =
  | 'rankedPosition'
  | 'week'
  | 'adp'
  | 'points'
  | 'avgWeeklyPoints'
  | 'average';

interface DisplayStats {
  key: DisplayStatKeys;
  keyString: string;
  value: string | number;
}

export const getDisplayStats = ({
  cA,
  source,
}: {
  cA: ConstructedAppearanceDrafting | ConstructedAppearance;
  source: Source;
}) => {
  const { projection, byeWeek } = cA;

  // Just keep adding stats in this order, with a max of three when rankedPostion exists. Otherwise,
  // the max should be two
  // My rank (when rankedPosition exists) > bye > adp > projected points > average > projected rank
  let statsToDisplayCount = 2;
  const stats: DisplayStats[] = [];

  if ('rankedPosition' in cA && cA.rankedPosition) {
    stats.push({ key: 'rankedPosition', value: cA.rankedPosition, keyString: 'My rank' });

    statsToDisplayCount = 3;
  }

  if (byeWeek?.week && stats.length < statsToDisplayCount) {
    stats.push({ key: 'week', value: byeWeek.week, keyString: 'Bye' });
  }

  if (projection?.adp && stats.length < statsToDisplayCount) {
    stats.push({ key: 'adp', value: projection.adp, keyString: 'ADP' });
  }

  if (projection?.points && stats.length < statsToDisplayCount && source !== 'weeklyWinner') {
    stats.push({ key: 'points', value: projection.points, keyString: 'Proj' });
  }

  if (
    projection?.avgWeeklyPoints &&
    stats.length < statsToDisplayCount &&
    source === 'weeklyWinner'
  ) {
    stats.push({ key: 'avgWeeklyPoints', value: projection.avgWeeklyPoints, keyString: 'Proj' });
  }

  if (projection?.average && stats.length < statsToDisplayCount) {
    stats.push({ key: 'average', value: projection.average, keyString: 'Avg' });
  }

  return stats;
};

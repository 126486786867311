import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';

import './styles.scss';

export const ThemeContext = React.createContext(null);

interface ThemeProviderProps {
  children: JSX.Element | JSX.Element[];
}

export const useTheme = (): [string, React.Dispatch<React.SetStateAction<string>>] => {
  const [theme, setTheme] = useState('light');
  useEffect(() => {
    const underdogTheme = Cookies.get('ud_theme') || localStorage.getItem('underdogTheme');
    setTheme(underdogTheme === 'dark' ? 'dark' : 'light');
  }, []);

  return [theme, setTheme];
};

export const ThemeWrapper = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const theme = useContext(ThemeContext);
  return <div className={`theme--${theme.theme}`}>{children}</div>;
};

export const ThemeProvider = (props: ThemeProviderProps) => {
  const [theme, setTheme] = useTheme();

  const toggle = useCallback(() => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    localStorage.setItem('underdogTheme', newTheme);
    setTheme(newTheme);
  }, [setTheme, theme]);

  const toggleAndTheme = useMemo(() => ({ toggle, theme }), [toggle, theme]); // doing this because of a weird useMemo error

  return <ThemeContext.Provider value={toggleAndTheme}>{props.children}</ThemeContext.Provider>;
};

export default ThemeContext;

import React, { CSSProperties, FC } from 'react';
import clsx from 'clsx';

import Icon, { IconTypes } from '@/components/atoms/icon';

import styles from './styles.scss';

export type ButtonSize = 'small' | 'large' | 'regular';
export type ButtonType = 'solid' | 'text' | 'outline';

export interface ButtonProps {
  classNames?: {
    button?: string;
    icon?: string;
  };
  color?: 'green' | 'grey' | 'primary' | 'red';
  text?: string | JSX.Element;
  disabled?: boolean;
  icon?: IconTypes;
  size: ButtonSize;
  type: ButtonType;
  width: 'full' | 'intrinsic';
  onClick?: (val?: any) => any;
  customStyle?: CSSProperties;
  testId?: string;
}

const Button: FC<ButtonProps> = (props: ButtonProps) => {
  const {
    classNames,
    color,
    text,
    disabled,
    icon,
    size,
    type,
    width,
    onClick,
    customStyle,
    testId,
  } = props;

  const buttonClassNames = clsx(
    styles.button,
    styles[color],
    styles[size],
    styles[type],
    styles[width],
    classNames.button
  );

  const iconEl = icon && (
    <div className={clsx(classNames.icon, styles.icon)}>
      <Icon name={icon} />
    </div>
  );

  return (
    <button
      data-testid={testId ?? 'button'}
      className={buttonClassNames}
      disabled={disabled}
      onClick={onClick}
      style={customStyle}
    >
      {iconEl}
      {text}
    </button>
  );
};

Button.defaultProps = {
  classNames: {},
  color: 'green',
};

export default Button;

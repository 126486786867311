import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import * as api from '@/api/drafting-config';
import errorLogger from '@/utilities/errors/logger';

import { setUserStateConfig } from '../actions';

export const getUserStateConfigSaga = function* getUserStateConfigSaga(): SagaIterator {
  try {
    const response = yield call(api.getStateConfigs);

    yield put(setUserStateConfig({ stateConfigData: response.data }));
  } catch (e) {
    yield put(
      setUserStateConfig({
        // doing this because annoyingly we still need to set this on error
        stateConfigData: {
          state_config: {
            pick_em: {
              payout_modifiers: false,
              visible_sports: null,
              visible_stats: null,
              pickem_pools_enabled: null,
              pickem_pools_free_entry: false,
              pickem_pools_visible: null,
              minimum_selection_size: 2, // this is with almost certainty the lowest this value will be
              maximum_selection_size: Infinity,
            },
          },
        },
      })
    );
    errorLogger(true, `getUserStateConfig: ${e}`);
  }
};

/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';

import request from '@request';
import { ExposureResponse } from '@/interfaces/exposure';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const sendExposureEmail = async ({ slateId }: { slateId: string }): Promise<any> => {
  try {
    const response = await request({
      method: 'POST',
      url: `/slates/${slateId}/ownership_email`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getExposure = async ({
  slateId,
  contestStyleId,
  entryCounts,
  entryFees,
  draftPools,
  tournaments,
  weeklyWinners,
}: {
  slateId: string;
  contestStyleId: string;
  entryCounts?: string[];
  entryFees?: string[];
  draftPools?: string[];
  tournaments?: string[];
  weeklyWinners?: string[];
}): Promise<AxiosResponse<ExposureResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/user/slates/${slateId}/contest_styles/${contestStyleId}/ownership`,
      params: {
        entry_counts: entryCounts || null,
        entry_fees: entryFees || null,
        draft_pools: draftPools || null,
        tournaments: tournaments || null,
        weekly_winners: weeklyWinners || null,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/* eslint-disable camelcase */
import { Selection, SelectionResponse } from '@/interfaces/pick-em';

const selectionAdapter = (selection: SelectionResponse): Selection => {
  const {
    id,
    result,
    option_id,
    option_type,
    actual_stat_value,
    rival_actual_stat_value,
    rebooted,
  } = selection;

  return {
    id,
    result,
    optionId: option_id,
    optionType: option_type,
    actualStatValue: actual_stat_value,
    rebooted,
    rivalActualStatValue: rival_actual_stat_value,
  };
};

export default selectionAdapter;

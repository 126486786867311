/* eslint-disable camelcase */
import { pickEmOverUnderAppearancesConstructor } from '@/store/constructors/pick-em-over-under-appearances-constructor';
import appearanceAdapter from '@/store/modules/constructed-slates/adapters/appearance';
import playerAdapter from '@/store/modules/constructed-slates/adapters/player';
import matchAdapter from '@/store/modules/matches/adapters/match';

import {
  ConstructedEntrySlipPickEmOverUnderAppearance,
  ConstructedPickEmOverUnderLineAppearance,
  PickEmOverUnder,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { DraftingConfig, StateConfig } from '@/interfaces/drafting-config';
import { StatLineUpdateResponse } from '@/interfaces/live';
import {
  FavoritePlayer,
  FavoritePlayersResponse,
  FeaturedLobbyResponse,
  FeaturedOverUnderLinesResponse,
  OverUnderLine,
  OverUnderLines,
  OverUnderLinesResponse,
  OverUnderLineSwapResponse,
} from '@/interfaces/pick-em';
import { Role } from '@/interfaces/user';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import {
  ACCEPT_OVER_UNDER_LINE_SWAP,
  acceptOverUnderLineSwap,
  ADD_FAVORITE_PICK_EM_PLAYER,
  addFavoritePickEmPlayer,
  REMOVE_FAVORITE_PICK_EM_PLAYER,
  REMOVE_PICK_EM_OVER_UNDER_LINE,
  removeFavoritePickEmPlayer,
  removePickEmOverUnderLine,
  SET_PICK_EM_OVER_UNDER_LINES,
  setPickEmOverUnderLines,
  SWAP_FINISHED_PICK_EM_OVER_UNDER_LINE,
  SWAP_PICK_EM_OVER_UNDER_LINE,
  swapFinishedPickEmOverUnderLine,
  swapPickEmOverUnderLine,
  UPDATE_PICK_EM_LIVE_EVENT_STAT,
  UPDATE_PICK_EM_OVER_UNDER_LINE_STATUS,
  updatePickEmLiveEventStat,
  updatePickEmOverUnderLineStatus,
} from '../actions';
import boostAdapter from '../adapters/boost';
import featuredLobbyAdapter from '../adapters/featured-lobby';
import overUnderLineSwapAdapter from '../adapters/over-under-line-swap';
import overUnderLineAdapter from '../adapters/over-under-lines';
import soloGameAdapter from '../adapters/solo-game';

type State = PickEmOverUnder;

type PickEmOverUnderActions = ReturnType<
  | typeof setPickEmOverUnderLines
  | typeof acceptOverUnderLineSwap
  | typeof removePickEmOverUnderLine
  | typeof addFavoritePickEmPlayer
  | typeof removeFavoritePickEmPlayer
  | typeof updatePickEmOverUnderLineStatus
  | typeof updatePickEmLiveEventStat
  | typeof swapFinishedPickEmOverUnderLine
  | typeof swapPickEmOverUnderLine
>;

const initialState: PickEmOverUnder = {
  overUnderLines: {},
  appearances: null,
  games: null,
  players: null,
  soloGames: null,
  constructedPickEmOverUnderLineAppearances: null,
  comingSoonBoosts: null,
  featuredLobby: null,
};

const set = ({
  preGameOverUnderLines,
  liveOverUnderLines,
  favoritePlayers,
  draftingConfig,
  featuredLobby,
  featuredOverUnders,
  pickEmStateConfig,
  roles,
}: {
  preGameOverUnderLines: OverUnderLinesResponse;
  liveOverUnderLines: OverUnderLinesResponse;
  favoritePlayers: FavoritePlayersResponse['favorite_players'];
  draftingConfig: DraftingConfig;
  featuredLobby: FeaturedLobbyResponse;
  featuredOverUnders: FeaturedOverUnderLinesResponse;
  pickEmStateConfig: StateConfig['pickEm'];
  roles: Role[];
}): PickEmOverUnder => {
  const isAdmin = roles.includes('admin');
  const overUnderLinesResponse = [
    ...preGameOverUnderLines.over_under_lines,
    ...liveOverUnderLines.over_under_lines,
    ...featuredOverUnders.over_under_lines,
  ];

  const appearancesResponse = [
    ...preGameOverUnderLines.appearances,
    ...liveOverUnderLines.appearances,
    ...featuredOverUnders.appearances,
  ];

  const gamesResponse = [
    ...preGameOverUnderLines.games,
    ...liveOverUnderLines.games,
    ...featuredOverUnders.games,
  ];

  const playersResponse = [
    ...preGameOverUnderLines.players,
    ...liveOverUnderLines.players,
    ...featuredOverUnders.players,
  ];

  const soloGamesResponse = [
    ...preGameOverUnderLines.solo_games,
    ...liveOverUnderLines.solo_games,
    ...featuredOverUnders.solo_games,
  ];

  const favoritePlayerIds = favoritePlayers.map((fPlayer) => fPlayer.id);

  const overUnderLinesArray = overUnderLinesResponse.map(overUnderLineAdapter).filter((oUL) => {
    if (isAdmin) return true;
    if (!pickEmStateConfig.visibleStats) return true;
    if (
      !pickEmStateConfig.payoutModifier &&
      oUL.options.some((option) => option.payoutMultiplier !== 1)
    ) {
      return false;
    }
    return pickEmStateConfig.visibleStats.includes(oUL?.overUnder.appearanceStat.stat);
  });

  const appearances = appearancesResponse.map(appearanceAdapter).filter(
    (appearance, ind, arr) =>
      ind ===
      arr.findIndex(
        (
          a // removes duplicates
        ) => a.id === appearance.id
      )
  );

  const games = arrayToObjectIdKeys(gamesResponse.map(matchAdapter));

  const soloGames = arrayToObjectIdKeys(soloGamesResponse.map(soloGameAdapter));

  const players = arrayToObjectIdKeys(
    playersResponse.map((player) => {
      const adaptedPlayer = playerAdapter(player);
      if (!favoritePlayerIds?.length) {
        return adaptedPlayer;
      }
      if (favoritePlayerIds.includes(player.id)) {
        return {
          ...adaptedPlayer,
          favorite: true,
        };
      }
      return adaptedPlayer;
    })
  );

  const { teams, lineupStatuses, sports } = draftingConfig;

  let constructedPickEmOverUnderLineAppearances = appearances
    ? pickEmOverUnderAppearancesConstructor({
        appearances,
        games,
        lineupStatuses,
        overUnderLines: overUnderLinesArray,
        players,
        soloGames,
        sports,
        teams,
      })
    : null;

  if (constructedPickEmOverUnderLineAppearances && !isAdmin && pickEmStateConfig.visibleSports) {
    constructedPickEmOverUnderLineAppearances = constructedPickEmOverUnderLineAppearances.filter(
      (cPEOUA) =>
        pickEmStateConfig.visibleSports.includes(cPEOUA.match?.sportId) ||
        pickEmStateConfig.visibleSports.includes(cPEOUA.soloGame?.sportId)
    );
  }

  const overUnderLines = arrayToObjectIdKeys(overUnderLinesArray);

  // find all sweepstakes boosts that aren't attached to a projection
  const comingSoonBoosts = featuredOverUnders.boosts.map(boostAdapter).filter((boost) => {
    // only 'comingSoonEnabled' sweepstakes and discounts can be coming soon
    if (
      (boost.boostType !== 'sweepstakes' && boost.boostType !== 'discount') ||
      !boost.comingSoonEnabled
    ) {
      return false;
    }
    // check if boost is attached to any open lines
    const boostedOULExists = overUnderLinesResponse
      .map(overUnderLineAdapter)
      .find((oUL) => oUL.overUnder?.boost?.id === boost.id);
    return !boostedOULExists;
  });

  const featuredLobbyContent = featuredLobbyAdapter(featuredLobby);

  return {
    overUnderLines,
    appearances,
    games,
    players,
    soloGames,
    constructedPickEmOverUnderLineAppearances,
    comingSoonBoosts,
    featuredLobby: featuredLobbyContent,
  };
};

const remove = (state: PickEmOverUnder, { data }: { data: string[] }): PickEmOverUnder => {
  const newOverUnderLines = data.reduce((acc, overUnderId) => {
    if (state.overUnderLines[overUnderId]) {
      // line exists
      acc[overUnderId] = {
        ...state.overUnderLines[overUnderId],
        status: 'removed',
      };
    }
    return acc;
  }, {} as OverUnderLines);

  const newCPEOULAs = state.constructedPickEmOverUnderLineAppearances?.map(
    (cA: ConstructedPickEmOverUnderLineAppearance) => {
      const overUnderLines: OverUnderLine[] = cA.overUnderLines.map((OUL: OverUnderLine) => {
        if (data.includes(OUL.id) || data.includes(OUL.overUnder.boost?.id)) {
          // the 'remove' event is used to remove lines and boosts
          return {
            ...OUL,
            status: 'removed',
          };
        }
        return OUL;
      });
      return {
        ...cA,
        overUnderLines,
      };
    }
  );

  return {
    ...state,
    constructedPickEmOverUnderLineAppearances: newCPEOULAs,
    overUnderLines: {
      ...state.overUnderLines,
      ...newOverUnderLines,
    },
  };
};

const updateLiveOverUnderLineStatus = (
  state: PickEmOverUnder,
  {
    statusType,
    overUnderLineIds,
  }: { statusType: 'suspended' | 'unsuspended'; overUnderLineIds: string[] }
): PickEmOverUnder => {
  let newOverUnderLines;
  let newCPEOULAs;

  if (statusType === 'suspended') {
    newOverUnderLines = overUnderLineIds.reduce((acc, overUnderId) => {
      if (state.overUnderLines[overUnderId]) {
        // line exists
        acc[overUnderId] = {
          ...state.overUnderLines[overUnderId],
          status: 'suspended',
        };
      }
      return acc;
    }, {} as OverUnderLines);

    newCPEOULAs = state.constructedPickEmOverUnderLineAppearances?.map(
      (cA: ConstructedPickEmOverUnderLineAppearance) => {
        const overUnderLines: OverUnderLine[] = cA.overUnderLines.map((OUL: OverUnderLine) => {
          if (overUnderLineIds.includes(OUL.id)) {
            return {
              ...OUL,
              status: 'suspended',
            };
          }
          return OUL;
        });
        return {
          ...cA,
          overUnderLines,
        };
      }
    );
  }

  if (statusType === 'unsuspended') {
    newOverUnderLines = overUnderLineIds.reduce((acc, overUnderId) => {
      if (state.overUnderLines[overUnderId]) {
        // line exists
        acc[overUnderId] = {
          ...state.overUnderLines[overUnderId],
          status: 'active',
        };
      }
      return acc;
    }, {} as OverUnderLines);

    newCPEOULAs = state.constructedPickEmOverUnderLineAppearances?.map(
      (cA: ConstructedPickEmOverUnderLineAppearance) => {
        const overUnderLines: OverUnderLine[] = cA.overUnderLines.map((OUL: OverUnderLine) => {
          if (overUnderLineIds.includes(OUL.id)) {
            return {
              ...OUL,
              status: 'active',
            };
          }
          return OUL;
        });
        return {
          ...cA,
          overUnderLines,
        };
      }
    );
  }

  return {
    ...state,
    overUnderLines: newOverUnderLines,
    constructedPickEmOverUnderLineAppearances: newCPEOULAs,
  };
};

const swapOverUnderLine = (
  state: PickEmOverUnder,
  { data }: { data: OverUnderLineSwapResponse[] }
): PickEmOverUnder => {
  const adaptedOverUnderLineSwapArray = data.map(overUnderLineSwapAdapter);
  const oldIdsArray = adaptedOverUnderLineSwapArray.map((oULS) => oULS.oldId);
  const overUnderLineSwapByOldId = arrayToObjectIdKeys(adaptedOverUnderLineSwapArray, 'oldId');

  const newOverUnderLines = Object.keys(state.overUnderLines).reduce((result, overUnderLineId) => {
    if (oldIdsArray.includes(overUnderLineId)) {
      const replacementOverUnderLine = overUnderLineSwapByOldId[overUnderLineId];
      const { newId, newStat, options, multiplierSwap, oldStat, oldLineOptions } =
        replacementOverUnderLine;
      const splitLine =
        options?.length > 1 && options.some((option) => option.payoutMultiplier !== 1);
      // eslint-disable-next-line no-param-reassign
      result[replacementOverUnderLine.newId] = {
        ...state.overUnderLines[overUnderLineId],
        id: newId,
        statValue: newStat,
        options,
        oldOverUnderLineId: overUnderLineId,
        oldStatValue: state.overUnderLines[overUnderLineId].statValue,
        needsAcceptanceForStatChange: multiplierSwap && newStat !== oldStat,
        oldLineOptions: multiplierSwap && oldLineOptions,
        splitLine,
        status: 'active',
      };
    }
    if (!oldIdsArray.includes(overUnderLineId)) {
      // eslint-disable-next-line no-param-reassign
      result[overUnderLineId] = state.overUnderLines[overUnderLineId];
    }
    return result;
  }, {} as OverUnderLines);

  const newCPEOULAs = state.constructedPickEmOverUnderLineAppearances?.map(
    (cA: ConstructedPickEmOverUnderLineAppearance) => {
      if (oldIdsArray.length === 0) return cA;
      const overUnderLines: OverUnderLine[] = cA.overUnderLines.map((oUL: OverUnderLine) => {
        if (oldIdsArray.includes(oUL.id)) {
          const replacementOverUnderLine = overUnderLineSwapByOldId[oUL.id];
          oldIdsArray.splice(oldIdsArray.indexOf(oUL.id), 1); // remove this from the array, so that we can break out of this early with a `oldIdsArray.length === 0` check
          const { newId, newStat, options, multiplierSwap, oldStat, oldLineOptions } =
            replacementOverUnderLine;
          const splitLine =
            options?.length > 1 && options.some((option) => option.payoutMultiplier !== 1);
          return {
            ...oUL,
            id: newId,
            statValue: newStat,
            options,
            oldOverUnderLineId: oUL.id,
            oldStatValue: oUL.statValue,
            needsAcceptanceForStatChange: multiplierSwap && newStat !== oldStat,
            oldLineOptions: multiplierSwap && oldLineOptions,
            splitLine,
            status: 'active',
          };
        }
        return oUL;
      });
      return {
        ...cA,
        overUnderLines,
      };
    }
  );

  return {
    ...state,
    overUnderLines: newOverUnderLines,
    constructedPickEmOverUnderLineAppearances: newCPEOULAs,
  };
};

const swapFinishedOverUnderLine = (
  state: PickEmOverUnder,
  {
    overUnderLineIds,
  }: // we're just cleaning up the oldStat and oldId stuff
  { overUnderLineIds: string[] }
): PickEmOverUnder => {
  const newOverUnderLines = Object.keys(state.overUnderLines).reduce((result, overUnderLineId) => {
    if (overUnderLineIds.includes(overUnderLineId)) {
      // eslint-disable-next-line no-param-reassign
      result[overUnderLineId] = {
        ...state.overUnderLines[overUnderLineId],
        oldOverUnderLineId: null,
        oldStatValue: null,
        oldLineOptions: null,
      };
    }
    if (!overUnderLineIds.includes(overUnderLineId)) {
      // eslint-disable-next-line no-param-reassign
      result[overUnderLineId] = state.overUnderLines[overUnderLineId];
    }
    return result;
  }, {} as OverUnderLines);

  const newCPEOULAs = state.constructedPickEmOverUnderLineAppearances?.map(
    (cA: ConstructedPickEmOverUnderLineAppearance) => {
      if (overUnderLineIds.length === 0) return cA;
      const overUnderLines: OverUnderLine[] = cA.overUnderLines.map((oUL: OverUnderLine) => {
        if (overUnderLineIds.includes(oUL.id)) {
          overUnderLineIds.splice(overUnderLineIds.indexOf(oUL.id), 1); // remove this from the array, so that we can break out of this early with a `oldIdsArray.length === 0` check
          return {
            ...oUL,
            oldOverUnderLineId: null,
            oldStatValue: null,
            oldLineOptions: null,
          };
        }
        return oUL;
      });
      return {
        ...cA,
        overUnderLines,
      };
    }
  );

  return {
    ...state,
    overUnderLines: newOverUnderLines,
    constructedPickEmOverUnderLineAppearances: newCPEOULAs,
  };
};

const updateLiveEventStat = (
  state: PickEmOverUnder,
  {
    data,
    oULAppearanceStatId,
  }: {
    data: StatLineUpdateResponse;
    oULAppearanceStatId: string;
  }
) => {
  const newCPEOULAs = state.constructedPickEmOverUnderLineAppearances?.map((cA) => {
    const { owner_id: ownerId, scores, data: statLineData } = data.stat_line;

    // as we map through the overUnders, we only update the OverUnder we're looking for by
    // checking for its appearance ID
    if (ownerId === cA.id) {
      const newOverUnderLines = cA.overUnderLines.map((oUL) => {
        const { appearanceStat, scoringTypeId } = oUL.overUnder;

        // update only the relevant line's appearance stat
        if (appearanceStat.id === oULAppearanceStatId) {
          const updatedLiveEventStat =
            appearanceStat.stat === 'fantasy_points'
              ? scores.find((score) => score.scoring_type_id === scoringTypeId)?.points
              : statLineData[appearanceStat.stat];
          // then we replace the liveEventStat with the updated value
          // we default to the current oUL's liveEventStat value in case of any errors
          return {
            ...oUL,
            liveEventStat: updatedLiveEventStat || oUL.liveEventStat,
          };
        }

        return oUL;
      });

      return {
        ...cA,
        overUnderLines: newOverUnderLines,
      };
    }

    return cA;
  });

  return {
    ...state,
    constructedPickEmOverUnderLineAppearances: newCPEOULAs,
  };
};

export const acceptOverUnderSwap = (
  state: PickEmOverUnder,
  {
    overUnderLineIds,
  }: {
    overUnderLineIds: string[];
  }
): PickEmOverUnder => {
  const newOverUnderLines = overUnderLineIds.reduce((acc, overUnderId) => {
    if (state.overUnderLines[overUnderId]) {
      // line exists
      acc[overUnderId] = {
        ...state.overUnderLines[overUnderId],
        needsAcceptanceForStatChange: false,
      };
    }
    return acc;
  }, {} as OverUnderLines);

  const newCPEOULAs = state.constructedPickEmOverUnderLineAppearances?.map(
    (cA: ConstructedPickEmOverUnderLineAppearance) => {
      if (overUnderLineIds.length === 0) return cA;
      const overUnderLines: OverUnderLine[] = cA.overUnderLines.map((oUL: OverUnderLine) => {
        if (overUnderLineIds.includes(oUL.id)) {
          overUnderLineIds.splice(overUnderLineIds.indexOf(oUL.id), 1); // remove this from the array, so that we can break out of this early with a `oldIdsArray.length === 0` check
          return {
            ...oUL,
            needsAcceptanceForStatChange: false,
          };
        }
        return oUL;
      });
      return {
        ...cA,
        overUnderLines,
      };
    }
  );

  return {
    ...state,
    overUnderLines: newOverUnderLines,
    constructedPickEmOverUnderLineAppearances: newCPEOULAs,
  };
};

// TODO [FAN-2282]: look into this it doesn't feel very efficient
const addFavoritePlayer = (
  state: PickEmOverUnder,
  { player }: { player: FavoritePlayer }
): PickEmOverUnder => {
  // this is to stop accidentally adding inactive players to the redux for open pick'ems
  // this could happen if you 'undo' removing an inactive pick
  if (player.isInactive) {
    return state;
  }

  // eslint-disable-next-line max-len
  const newCPEOULAs: ConstructedEntrySlipPickEmOverUnderAppearance[] =
    state.constructedPickEmOverUnderLineAppearances.map(
      (cA: ConstructedEntrySlipPickEmOverUnderAppearance) => {
        if (cA.player.id === player.id) {
          return {
            ...cA,
            player: {
              ...cA.player,
              favorite: true,
            },
          };
        }
        return cA;
      }
    );

  const newPlayer = {
    ...player,
    favorite: true,
  };

  return {
    ...state,
    players: {
      ...state.players,
      [newPlayer.id]: newPlayer,
    },
    constructedPickEmOverUnderLineAppearances: newCPEOULAs,
  };
};

// TODO [FAN-2282]: look into this it doesn't feel very efficient
const removeFavoritePlayer = (
  state: PickEmOverUnder,
  { playerId }: { playerId: string }
): PickEmOverUnder => {
  const newPlayers = { ...state.players };

  if (!newPlayers[playerId]) {
    return state;
  }

  newPlayers[playerId] = {
    ...newPlayers[playerId],
    favorite: false,
  };

  // eslint-disable-next-line max-len
  const newCPEOULAs: ConstructedEntrySlipPickEmOverUnderAppearance[] =
    state.constructedPickEmOverUnderLineAppearances.map(
      (cA: ConstructedEntrySlipPickEmOverUnderAppearance) => {
        if (cA.player.id === playerId) {
          return {
            ...cA,
            player: {
              ...cA.player,
              favorite: false,
            },
          };
        }
        return cA;
      }
    );

  return {
    ...state,
    players: newPlayers,
    constructedPickEmOverUnderLineAppearances: newCPEOULAs,
  };
};

export const pickEmOverUnderLineReducer = (
  state: State = initialState,
  action: PickEmOverUnderActions = {} as PickEmOverUnderActions
): State => {
  switch (action.type) {
    case SET_PICK_EM_OVER_UNDER_LINES:
      return set(action.payload);
    case REMOVE_PICK_EM_OVER_UNDER_LINE:
      return remove(state, action.payload);
    case UPDATE_PICK_EM_OVER_UNDER_LINE_STATUS:
      return updateLiveOverUnderLineStatus(state, action.payload);
    case SWAP_PICK_EM_OVER_UNDER_LINE:
      return swapOverUnderLine(state, action.payload);
    case SWAP_FINISHED_PICK_EM_OVER_UNDER_LINE:
      return swapFinishedOverUnderLine(state, action.payload);
    case UPDATE_PICK_EM_LIVE_EVENT_STAT:
      return updateLiveEventStat(state, action.payload);
    case ACCEPT_OVER_UNDER_LINE_SWAP:
      return acceptOverUnderSwap(state, action.payload);
    case ADD_FAVORITE_PICK_EM_PLAYER:
      return addFavoritePlayer(state, action.payload);
    case REMOVE_FAVORITE_PICK_EM_PLAYER:
      return removeFavoritePlayer(state, action.payload);
    default:
      return state;
  }
};

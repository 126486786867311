import { RENAME_DRAFT_ENTRY, renameDraftEntry } from '@/store/modules/drafts/actions';

import { ActiveDraft, ActiveDraftsResponse } from '@/interfaces/active-drafts';
import {
  AutoPickToggleResponse,
  DraftResponse,
  NewDraftEntryResponse,
  PickMadeResponse,
  RenameDraftEntry,
} from '@/interfaces/drafts';

import {
  REMOVE_ACTIVE_DRAFT,
  removeActiveDraft,
  SET_ACTIVE_DRAFTS,
  setActiveDrafts,
  UPDATE_ACTIVE_DRAFT_ENTRY_COUNT,
  UPDATE_ACTIVE_DRAFT_PICK,
  UPDATE_ACTIVE_DRAFT_STATUS,
  UPDATE_DRAFT_AUTO_PICK_STATUS,
  updateActiveDraftEntryCount,
  updateActiveDraftPick,
  updateActiveDraftStatus,
  updateDraftEntryAutoPickStatus,
} from '../actions';
import activeDraftsAdapter from '../adapters/active-drafts';

type State = ActiveDraft[] | null;

type ActiveDraftsActions = ReturnType<
  | typeof setActiveDrafts
  | typeof updateActiveDraftStatus
  | typeof updateActiveDraftEntryCount
  | typeof updateDraftEntryAutoPickStatus
  | typeof updateActiveDraftPick
  | typeof removeActiveDraft
  | typeof renameDraftEntry
>;

const initialState: ActiveDraft[] = [];

const set = (data: ActiveDraftsResponse): ActiveDraft[] => {
  const activeDrafts = data.drafts.map(activeDraftsAdapter);

  return activeDrafts;
};

const updateStatus = (
  state: ActiveDraft[],
  { draft, userId }: { draft: DraftResponse['draft']; userId: string }
) =>
  state
    .filter((activeDraft) => !(draft.status === 'completed' && activeDraft.id === draft.id))
    .map((activeDraft: ActiveDraft) => {
      if (activeDraft.id === draft.id) {
        let pickOrder = activeDraft.userPickOrder;
        if (!activeDraft.userPickOrder && draft.draft_entries) {
          pickOrder = draft.draft_entries.find((dE) => dE.user_id === userId).pick_order;
        }
        return {
          ...activeDraft,
          status: draft.status,
          autoPickAt: draft.auto_pick_at,
          draftAt: draft.draft_at,
          userPickOrder: pickOrder,
          pickCount: (draft.picks && draft.picks.length) || activeDraft.pickCount,
        };
      }
      return activeDraft;
    });

const updateEntryCount = (state: ActiveDraft[], data: NewDraftEntryResponse) =>
  state.map((activeDraft: ActiveDraft): ActiveDraft => {
    if (activeDraft.id === data.id) {
      return {
        ...activeDraft,
        status: data.status,
        entryCount: data.entry_count,
      };
    }
    return activeDraft;
  });

const updateAutoPickStatus = (state: ActiveDraft[], data: AutoPickToggleResponse) =>
  state.map((activeDraft: ActiveDraft) => {
    if (activeDraft.id === data.draft_id) {
      return {
        ...activeDraft,
        userAutoPick: data.auto_pick,
      };
    }
    return activeDraft;
  });

const updatePick = (state: ActiveDraft[], { updatedPick }: { updatedPick: PickMadeResponse }) =>
  state.map((activeDraft: ActiveDraft) => {
    if (activeDraft.id === updatedPick.id) {
      return {
        ...activeDraft,
        pickCount: updatedPick.pick.number,
        autoPickAt: updatedPick.auto_pick_at,
      };
    }
    return activeDraft;
  });

const remove = (state: ActiveDraft[], { draftId }: { draftId: string }) =>
  state.filter((activeDraft) => activeDraft.id !== draftId);

const rename = (state: ActiveDraft[], data: RenameDraftEntry) =>
  state.map((activeDraft: ActiveDraft) => {
    if (activeDraft.id === data.id) {
      return {
        ...activeDraft,
        title: data.draftEntryTitle,
      };
    }
    return activeDraft;
  });

export const activeDraftsReducer = (
  state: State = initialState,
  action: ActiveDraftsActions = {} as ActiveDraftsActions
): State => {
  switch (action.type) {
    case SET_ACTIVE_DRAFTS:
      return set(action.payload);
    case UPDATE_ACTIVE_DRAFT_STATUS:
      return updateStatus(state, action.payload);
    case UPDATE_ACTIVE_DRAFT_ENTRY_COUNT:
      return updateEntryCount(state, action.payload);
    case UPDATE_DRAFT_AUTO_PICK_STATUS:
      return updateAutoPickStatus(state, action.payload);
    case UPDATE_ACTIVE_DRAFT_PICK:
      return updatePick(state, action.payload);
    case REMOVE_ACTIVE_DRAFT:
      return remove(state, action.payload);
    case RENAME_DRAFT_ENTRY:
      return rename(state, action.payload);
    default:
      return state;
  }
};

import request from '@request';
import { apiErrorTracking, AppError } from '@/utilities/errors';

// TODO [FAN-2198]: work out an appropriate return type for this function
// if there is a page, then send it
export const getTransactions = async ({ page }: { page?: number }): Promise<any> => {
  try {
    const response = await request({
      method: 'GET',
      url: '/user/transactions',
      params: {
        page,
      },
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

import { Sports, SportStatus } from '@/interfaces/drafting-config';

// See _variables.scss if this needs to be updated
export const ALTERNATIVE_HOME_ROUTE = '/pick-em';
export const AMPLITUDE_DEVICE_ID = 'amplitude_device_id';
export const APPEARANCES_UPDATE_TIME = 120000; // 2 min in ms
export const AUTH0_SESSION_REFRESH = 'session_refresh';
export const BADGES_HOST = 'https://assets.underdogfantasy.com/badges';
export const CDN_HOST = 'https://assets.underdogfantasy.com/web';
export const DEFAULT_ROUTE = '/lobby';
export const DEFAULT_MAX_PAYOUT_MODIFIER = 100;
export const DEFAULT_MAX_INSURED_PAYOUT_MODIFIER = 50;
export const MIN_STANDARD_PICKS = 2;
export const MIN_INSURED_PICKS = 3;
export const MOBILE_UD_CLIENT_TYPE = 'ud_client_type';
export const MOBILE_UD_CLIENT_VERSION = 'ud_client_version';
export const MOBILE_UD_REF = 'ud_referring_link';
export const UD_BRANCH_DISMISSED = 'ud_branch_dismissed';
export const UD_DEPOSIT_PROMO_DISMISSED = 'ud_deposit_promo_dismissed';
export const UD_DEVICE_ID = 'ud-device-id';
export const UD_FEATURE_FLAGS = 'ud_feature_flags';
export const UD_INSTANT_MODAL_DISMISSED = 'ud_instant_modal_dismissed';
export const UD_LAT = 'underdog-latitude';
export const UD_LOCATION_STATE = 'ud_location_state';
export const UD_LOCATION_TOKEN = 'ud_location_token';
export const UD_LONG = 'underdog-longitude';
export const UD_OVER_UNDER_CLOSED_ACCORDIONS = 'ud_over_under_closed_accordions';
export const UD_PICK_EM = 'ud_pick_em';
// eslint-disable-next-line max-len
export const UD_PICK_EM_SUPPORT_LINK_QUERY =
  '?ticket_form_id=4532261056539&tf_6220294635163=pick_em';
export const UD_MAX_PAYOUT_MULTIPLIER_MODAL_DISMISSED = 'ud_max_payout_multiplier_modal_dismissed';
export const UD_PROMO_MODAL_DISMISSED = 'ud_promo_modal_dismissed';
export const UD_SCORCHER_INFO_MODAL_DISMISSED = 'ud_scorcher_modal_dismissed';
export const UD_REF = 'underdog-referring-link';

export const CUSTOM_SPORTS: Sports = {
  UDNEWS: {
    id: 'UDNEWS',
    color: '', // leave blank, won't have to override with !important
    gameType: '',
    manual: true,
    name: 'UD',
    overUndersEnabled: false,
    periods: 0,
    periodLabel: '',
    periodsLabel: '',
    rank: Infinity, // always last
    rivalsEnabled: false,
    status: SportStatus.DISABLED,
    draftStatus: SportStatus.HIDDEN,
    pickemStatus: SportStatus.HIDDEN,
    newsStatus: SportStatus.ACTIVE,
    teamSport: false,
  },
};

import { DraftPool } from '@/interfaces/draft-pools';
import {
  ContestsResponse,
  EntryCountUpdate,
  SlateSitAndGoResponse,
  SportLobby,
} from '@/interfaces/lobbies';
import { SlatesResponse } from '@/interfaces/slates';
import { SportId } from '@/interfaces/types';
import { WeeklyWinner } from '@/interfaces/weekly-winners';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import {
  ERROR_CONTESTS_FOR_SLATE,
  errorContestsForSlate,
  REMOVE_LOBBY_DRAFT_POOL,
  REMOVE_LOBBY_TOURNAMENT,
  REMOVE_LOBBY_WEEKLY_WINNER,
  REMOVE_SLATE_SIT_AND_GOS,
  removeLobbyDraftPool,
  removeLobbyTournament,
  removeLobbyWeeklyWinner,
  removeSlateSitAndGos,
  SET_CONTESTS_FOR_SLATE,
  SET_SLATES_FOR_SPORT,
  setContestsForSlate,
  setSlatesForSport,
  UPDATE_DRAFT_POOL_COUNT,
  UPDATE_SLATE_SIT_AND_GO_COUNT,
  UPDATE_TOURNAMENT_COUNT,
  UPDATE_WEEKLY_WINNER_COUNT,
  updateDraftPoolCount,
  updateSlateSitAndGoCount,
  updateTournamentCount,
  updateWeeklyWinnerCount,
} from '../actions';
import contestsAdapter from '../adapters/contests';
import slateSitAndGosAdapter from '../adapters/slate-sit-and-gos';
import slateAdapter from '../adapters/slates';

type State = SportLobby;

type LobbiesActions = ReturnType<
  | typeof setSlatesForSport
  | typeof setContestsForSlate
  | typeof errorContestsForSlate
  | typeof removeLobbyDraftPool
  | typeof removeSlateSitAndGos
  | typeof removeLobbyTournament
  | typeof removeLobbyWeeklyWinner
  | typeof updateSlateSitAndGoCount
  | typeof updateTournamentCount
  | typeof updateDraftPoolCount
  | typeof updateWeeklyWinnerCount
>;

const initialSportLobbyState: SportLobby = {
  currentSportId: null,
  slates: null,
  error: null,
};

/**
* Sample state for SportLobby
{
  draftPools: DraftPool[],
  slates: Slates,
  slate: Slate,
  slateSitAndGos: SlateSitAndGo[],
  tournaments: Tournament[],
  weeklyWinners: WeeklyWinner[],
}
*/
const setSlates = ({
  slates,
  sportId,
}: {
  slates: SlatesResponse;
  sportId: SportId;
}): SportLobby => {
  const adaptedSlates = arrayToObjectIdKeys(slates?.slates?.map(slateAdapter));

  return {
    currentSportId: sportId.toUpperCase() as SportId,
    slates: adaptedSlates,
    error: null,
  };
};

const setContests = (
  state: SportLobby,
  { contests }: { contests: ContestsResponse['contests'] }
): SportLobby => {
  const adaptedContests = contestsAdapter({ contests });
  return {
    ...state,
    error: null,
    slateSitAndGos: adaptedContests.slateSitAndGos,
    tournaments: adaptedContests.tournaments,
    draftPools: adaptedContests.draftPools,
    weeklyWinners: adaptedContests.weeklyWinners,
  };
};

const errorContests = (state: SportLobby): SportLobby => ({
  ...state,
  error: true,
});

const removeSlateSngs = (
  state: SportLobby,
  { slateSitAndGos }: { slateSitAndGos: SlateSitAndGoResponse[] }
) => {
  const filteredSlateSitAndGos = state.slateSitAndGos
    ? state.slateSitAndGos.filter((stateSsng) => {
        if (!slateSitAndGos || slateSitAndGos.length === 0) return true;
        return slateSitAndGos.find((slateSitAndGo) => {
          if (!slateSitAndGo.sit_and_go_id) return true;
          return slateSitAndGo.sit_and_go_id !== stateSsng.sitAndGoId;
        });
      })
    : state.slateSitAndGos;

  return {
    ...state,
    slateSitAndGos: filteredSlateSitAndGos,
  };
};

const removeTournament = (
  state: SportLobby,
  { tournamentId }: { tournamentId: string }
): SportLobby => {
  const filteredTournaments = state.tournaments
    ? state.tournaments.filter((tournament) => tournament.id !== tournamentId)
    : state.tournaments;

  return {
    ...state,
    tournaments: filteredTournaments,
  };
};

const updateEntryCount = (
  state: SportLobby,
  { slateSitAndGos }: { slateSitAndGos: SlateSitAndGoResponse[] }
): SportLobby => {
  const adaptedSitAndGos = slateSitAndGosAdapter(slateSitAndGos);
  const updatedSlateSitAndGos = state.slateSitAndGos
    ? state.slateSitAndGos.map((stateSsng) => {
        const newSlateSitAndGo = adaptedSitAndGos.find(
          (adaptedSitAndGo) =>
            adaptedSitAndGo.sitAndGoId === stateSsng.sitAndGoId &&
            adaptedSitAndGo.slateId === stateSsng.slateId
        );
        if (newSlateSitAndGo) {
          return newSlateSitAndGo;
        }
        return stateSsng;
      })
    : state.slateSitAndGos;

  return {
    ...state,
    slateSitAndGos: updatedSlateSitAndGos,
  };
};

const tournamentCount = (state: SportLobby, payload: EntryCountUpdate): SportLobby => {
  const { tournamentId, entryCount, status } = payload;
  const updatedTournaments =
    state.tournaments &&
    state.tournaments
      .filter((tournament) => !(status === 'filled' && tournament.id === tournamentId))
      .map((tournament) => {
        if (tournament.id === tournamentId) {
          return { ...tournament, entryCount };
        }
        return tournament;
      });

  return {
    ...state,
    tournaments: updatedTournaments,
  };
};

const draftPoolCount = (state: SportLobby, payload: EntryCountUpdate): SportLobby => {
  const { draftPoolId, entryCount, status } = payload;
  const updatedDraftPools =
    state.draftPools &&
    state.draftPools
      .filter((draftPool) => !(status === 'filled' && draftPool.id === draftPoolId))
      .map((draftPool) => {
        if (draftPool.id === draftPoolId) {
          return { ...draftPool, entryCount };
        }
        return draftPool;
      });

  return {
    ...state,
    draftPools: updatedDraftPools,
  };
};

const removeDraftPool = (
  state: SportLobby,
  {
    draftPoolId,
  }: {
    draftPoolId: string;
  }
): SportLobby => {
  const filteredDraftPools = state?.draftPools?.filter(
    (draftPool: DraftPool) => draftPool.id !== draftPoolId
  );

  return {
    ...state,
    ...(state.draftPools && { draftPools: filteredDraftPools }),
  };
};

const removeWeeklyWinner = (
  state: SportLobby,
  {
    weeklyWinnerId,
  }: {
    weeklyWinnerId: string;
  }
): SportLobby => {
  const filteredWeeklyWinners = state?.weeklyWinners?.filter(
    (weeklyWinner: WeeklyWinner) => weeklyWinner.id !== weeklyWinnerId
  );

  return {
    ...state,
    ...(state.weeklyWinners && { weeklyWinners: filteredWeeklyWinners }),
  };
};

const weeklyWinnerCount = (state: SportLobby, payload: EntryCountUpdate): SportLobby => {
  const { weeklyWinnerId, entryCount, status } = payload;
  const updatedWeeklyWinners =
    state.weeklyWinners &&
    state.weeklyWinners
      .filter((weeklyWinner) => !(status === 'filled' && weeklyWinner.id === weeklyWinnerId))
      .map((weeklyWinner) => {
        if (weeklyWinner.id === weeklyWinnerId) {
          return { ...weeklyWinner, entryCount };
        }
        return weeklyWinner;
      });

  return {
    ...state,
    weeklyWinners: updatedWeeklyWinners,
  };
};

export const sportLobbyReducer = (
  state: State = initialSportLobbyState,
  action: LobbiesActions = {} as LobbiesActions
): State => {
  switch (action.type) {
    case SET_SLATES_FOR_SPORT:
      return setSlates(action.payload);
    case SET_CONTESTS_FOR_SLATE:
      return setContests(state, action.payload);
    case REMOVE_LOBBY_DRAFT_POOL:
      return removeDraftPool(state, action.payload);
    case REMOVE_SLATE_SIT_AND_GOS:
      return removeSlateSngs(state, action.payload);
    case REMOVE_LOBBY_TOURNAMENT:
      return removeTournament(state, action.payload);
    case REMOVE_LOBBY_WEEKLY_WINNER:
      return removeWeeklyWinner(state, action.payload);
    case UPDATE_SLATE_SIT_AND_GO_COUNT:
      return updateEntryCount(state, action.payload);
    case UPDATE_TOURNAMENT_COUNT:
      return tournamentCount(state, action.payload);
    case UPDATE_DRAFT_POOL_COUNT:
      return draftPoolCount(state, action.payload);
    case UPDATE_WEEKLY_WINNER_COUNT:
      return weeklyWinnerCount(state, action.payload);
    case ERROR_CONTESTS_FOR_SLATE:
      return errorContests(state);
    default:
      return state;
  }
};

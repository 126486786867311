import axios, { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import Cookies from 'js-cookie';

import { OAuthTokenResponse } from '@/interfaces/user';
import { AUTH0_SESSION_REFRESH } from '@/utilities/constants';

export const auth0Service = (() => {
  let accessToken: string;
  let accessTokenExpiration: string;
  let isAuth0Active: boolean = false;
  let pendingRefresh: Promise<null>;

  const getAccessToken = (): string => accessToken;

  const setAccessToken = (token: string): void => {
    // this is a Bearer token, but auth0 doesn't require the prefix
    accessToken = token;
  };

  const isAccessTokenExpired = (): boolean => {
    const currentDate = dayjs();
    const isTokenExpired = currentDate.isAfter(accessTokenExpiration);
    return isTokenExpired;
  };

  const setAccessTokenExpiration = (value: number): void => {
    const currentDate = dayjs();
    const futureDate = currentDate.add(value, 'second');
    accessTokenExpiration = futureDate.format();
  };

  const removeRefreshToken = (): void => Cookies.remove(AUTH0_SESSION_REFRESH);

  const getRefreshToken = (): string => Cookies.get(AUTH0_SESSION_REFRESH);

  const setRefreshToken = (token: string): void => {
    Cookies.set(AUTH0_SESSION_REFRESH, token);
  };

  const getIsAuth0Active = (): boolean => isAuth0Active;

  // auth0 becomes 'active' when a user signs in with the /oauth/token endpoint
  // there are three different auth0 FF, so this needs to be handled separately
  const setIsAuth0Active = (value: boolean): void => {
    isAuth0Active = value;
  };

  const getAuth = async (): Promise<string> => {
    const refreshToken = getRefreshToken();

    if (refreshToken) {
      // check if access token is still valid
      const isTokenExpired = auth0Service.isAccessTokenExpired();
      if (accessToken && !isTokenExpired) {
        return accessToken;
      }
      // otherwise fetch and replace it
      await refreshAccessToken();
      return accessToken;
    }

    // unauthenticated
    return null;
  };

  const refreshAccessToken = async () => {
    if (pendingRefresh) {
      // pendingRefresh pauses all subsequent api requests until the new token exists
      return pendingRefresh;
    }

    // fetch new access token from auth0
    // failed requests to this auth0 api are handled in the axios error interceptor
    pendingRefresh = axios.request({
      method: 'POST',
      url: `${process.env.AUTH0_DOMAIN}/oauth/token`,
      data: {
        audience: process.env.AUTH0_AUDIENCE,
        client_id: process.env.AUTH0_CLIENT_ID,
        grant_type: 'refresh_token',
        refresh_token: getRefreshToken(),
        scope: 'offline_access',
      },
    });

    const response: AxiosResponse<OAuthTokenResponse> = await pendingRefresh;

    if (response?.data?.access_token) {
      setIsAuth0Active(true);
      setAccessToken(response.data.access_token);
      setRefreshToken(response.data.refresh_token);
      setAccessTokenExpiration(response.data.expires_in);
    }

    pendingRefresh = null;
    return null;
  };

  const resetPendingRefresh = () => {
    pendingRefresh = null;
  };

  return {
    getAccessToken,
    getAuth,
    getIsAuth0Active,
    getRefreshToken,
    isAccessTokenExpired,
    removeRefreshToken,
    refreshAccessToken,
    resetPendingRefresh,
    setAccessToken,
    setAccessTokenExpiration,
    setIsAuth0Active,
    setRefreshToken,
  };
})();

import client from '@/api-contentful/contentful-request';

export const getContentfulTeams = async (): Promise<any> => {
  const response = await client.getEntries({
    content_type: 'team',
  });

  return response;
};

export const getContentfulTeamMembers = async (): Promise<any> => {
  const response = await client.getEntries({
    content_type: 'teamMember',
  });

  return response;
};

export const getContentTypes = async (): Promise<any> => {
  const response = await client.getContentTypes();

  return response;
};

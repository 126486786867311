import { Player } from '@/interfaces/constructed-interfaces/constructed-slates';
import { Sport, Team } from '@/interfaces/drafting-config';

/**
 * This data comes from Contentful.
 * These are fallbacks and should be kept up to date.
 * https://www.notion.so/underdogfantasy/Modifying-Landing-Page-Content-804bf16727314acc9f5585bd9b266f76
 */

export const draftStates = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'DC',
  'Florida',
  'Georgia',
  'Illinois',
  'Indiana',
  'Kansas',
  'Kentucky',
  'Maine',
  'Maryland',
  'Massachusetts',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Nebraska',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Pennsylvania',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const draftCanadaNote =
  'Also available in all Canadian provinces and territories, except Ontario.';

export const pickEmStates = [
  'Alabama',
  'Alaska',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'DC',
  'Florida (Free to Play)',
  'Georgia',
  'Illinois',
  'Indiana',
  'Kentucky',
  'Massachusetts',
  'Minnesota',
  'Mississippi (Free to Play)',
  'Missouri',
  'Nebraska',
  'New Mexico',
  'New York',
  'North Carolina (Free to Play)',
  'North Dakota',
  'Oklahoma',
  'Oregon',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virginia',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
];

export const pickEmCanadaNote =
  'Also available in all Canadian provinces and territories, except Ontario.';

export const pickEmPlayers = [
  {
    name: 'Nikola Jokic',
    stat: '15.5 Points',
    player: {
      id: '54a70cec-9c34-4113-bd3e-17b7531f06f5',
      firstName: 'Nikola',
      lastName: 'Jokic',
      // eslint-disable-next-line max-len
      imageUrl:
        'https://assets.underdogfantasy.com/player-images/nba/f2625432-3903-4f90-9b0b-2e4f63856bb0.png',
      sportId: 'NBA',
      teamId: '5d478d55-84f7-4786-beb9-9c009c5814f4',
      positionId: '',
    } as Player,
    team: {
      id: '5d478d55-84f7-4786-beb9-9c009c5814f4',
      abbr: 'DEN',
      name: 'Denver Nuggets',
      primaryColor: '#0E2240',
      secondaryColor: '#FEC524',
      primaryColorDarkMode: '#2A5CBE',
      primaryColorLightMode: '#FEC524',
      secondaryColorDarkMode: '#FEC524',
      secondaryColorLightMode: '#0E2240',
      sportId: 'NBA',
    } as Team,
    sport: {
      id: 'NBA',
      color: '#FA6705',
      gameType: 'Game',
      manual: false,
      name: 'NNBA',
      overUndersEnabled: true,
      periods: 4,
      periodLabel: 'Quarter',
      periodsLabel: 'quarters',
      rank: 10,
      rivalsEnabled: true,
      status: 'active',
      draftStatus: 'active',
      pickemStatus: 'active',
      newsStatus: 'active',
      teamSport: true,
    } as Sport,
    options: [
      {
        id: 'jokic-higher',
        player: 'jokic',
        text: 'Higher',
      },
      {
        id: 'jokic-lower',
        player: 'jokic',
        text: 'Lower',
      },
    ],
  },
  {
    name: 'Lionel Messi',
    stat: '0.5 Goals',
    player: {
      id: '7358a52d-0191-43fe-b847-ad4097afd451',
      firstName: 'Lionel',
      lastName: 'Messi',
      // eslint-disable-next-line max-len
      imageUrl: 'https://assets.underdogfantasy.com/player-images/soccer/1235351800.png',
      sportId: 'FIFA',
      teamId: 'f04ad6f5-6b69-4049-ac75-105614c833f0',
      positionId: '',
    } as Player,
    team: {
      id: 'f04ad6f5-6b69-4049-ac75-105614c833f0',
      abbr: 'MIA',
      name: 'Inter Miami CF',
      primaryColor: '#F7B5CD',
      secondaryColor: '#231F20',
      primaryColorDarkMode: '#F7B5CD',
      primaryColorLightMode: '#F7B5CD',
      secondaryColorDarkMode: '#FFFFFF',
      secondaryColorLightMode: '#231F20',
      sportId: 'FIFA',
    } as Team,
    sport: {
      id: 'FIFA',
      color: '#FA6705',
      gameType: 'Game',
      manual: false,
      name: 'FIFA',
      overUndersEnabled: true,
      periods: 4,
      periodLabel: 'Quarter',
      periodsLabel: 'quarters',
      rank: 10,
      rivalsEnabled: true,
      status: 'active',
      draftStatus: 'active',
      pickemStatus: 'active',
      newsStatus: 'active',
      teamSport: true,
    } as Sport,
    options: [
      {
        id: 'messi-higher',
        player: 'messi',
        text: 'Higher',
      },
      {
        id: 'messi-lower',
        player: 'messi',
        text: 'Lower',
      },
    ],
  },
  {
    name: 'Joe Burrow',
    stat: '1.5 Touchdowns',
    player: {
      id: 'a0fa2a74-640b-4cc1-a5d8-841ab9557fe0',
      firstName: 'Joe',
      lastName: 'Burrow',
      // eslint-disable-next-line max-len
      imageUrl:
        'https://assets.underdogfantasy.com/player-images/nfl/3023ac10-4e7f-425f-9fc5-2b8e6332c92e.png',
      sportId: 'NFL',
      teamId: 'ab235cf0-a041-5d36-8241-90a90f0dcb5e',
      positionId: '',
    } as Player,
    team: {
      id: 'ab235cf0-a041-5d36-8241-90a90f0dcb5e',
      abbr: 'CIN',
      name: 'Cincinnati Bengals',
      primaryColor: '#1b1b1b',
      secondaryColor: '#FB4F14',
      primaryColorDarkMode: '#FB4F14',
      primaryColorLightMode: '#FB4F14',
      secondaryColorDarkMode: '#F0F0F0',
      secondaryColorLightMode: '#1B1B1B',
      sportId: 'NFL',
    } as Team,
    sport: {
      id: 'NFL',
      color: '#175FC7',
      gameType: 'Game',
      manual: false,
      name: 'NFL',
      overUndersEnabled: true,
      periods: 4,
      periodLabel: 'Quarter',
      periodsLabel: 'quarters',
      rank: 120,
      rivalsEnabled: true,
      status: 'active',
      draftStatus: 'active',
      pickemStatus: 'active',
      newsStatus: 'active',
      teamSport: true,
    } as Sport,
    options: [
      {
        id: 'burrow-higher',
        player: 'burrow',
        text: 'Higher',
      },
      {
        id: 'burrow-lower',
        player: 'burrow',
        text: 'Lower',
      },
    ],
  },
  {
    name: 'Lewis Hamilton',
    stat: '3.5 Finishing Position',
    player: {
      id: 'ff4b4210-c36f-46f2-8590-b494a22a2118',
      firstName: 'Lewis',
      lastName: 'Hamilton',
      // eslint-disable-next-line max-len
      imageUrl:
        'https://assets.underdogfantasy.com/player-images/f1/a1f3d449-173e-407a-bca0-7a1dbe230cfa.png',
      sportId: 'RACING',
      teamId: '8c36d648-32e3-4238-b631-68da2b57b095',
      positionId: '',
    } as Player,
    team: {
      id: '8c36d648-32e3-4238-b631-68da2b57b095',
      abbr: 'MERC',
      name: 'Mercedes',
      primaryColor: '#1B1B1B',
      secondaryColor: '#00D2BE',
      primaryColorDarkMode: '#2C2C2C',
      primaryColorLightMode: '#1B1B1B',
      secondaryColorDarkMode: '#00D2BE',
      secondaryColorLightMode: '#00D2BE',
      sportId: 'RACING',
    } as Team,
    sport: {
      id: 'RACING',
      color: '#E83C24',
      gameType: 'Game',
      manual: false,
      name: 'RACING',
      overUndersEnabled: true,
      periods: 4,
      periodLabel: 'Quarter',
      periodsLabel: 'quarters',
      rank: 10,
      rivalsEnabled: true,
      status: 'active',
      draftStatus: 'active',
      pickemStatus: 'active',
      newsStatus: 'active',
      teamSport: true,
    } as Sport,
    options: [
      {
        id: 'hamilton-higher',
        player: 'hamilton',
        text: 'Better',
      },
      {
        id: 'hamilton-lower',
        player: 'hamilton',
        text: 'Worse',
      },
    ],
  },
  {
    name: 'Skylar Diggins-Smith',
    stat: '16.5 Points',
    player: {
      id: 'a0fa2a74-640b-4cc1-a5d8-841ab9557fe0',
      firstName: 'Skylar',
      lastName: 'Diggins-Smith',
      // eslint-disable-next-line max-len
      imageUrl: 'https://assets.underdogfantasy.com/player-images/wnba/SkylarDiggins-Smith.png',
      sportId: 'WNBA',
      teamId: 'bd252434-828d-48d8-9884-6b0c7dd1b910',
      positionId: '',
    } as Player,
    team: {
      id: 'bd252434-828d-48d8-9884-6b0c7dd1b910',
      abbr: 'PHO',
      name: 'Phoenix Mercury',
      primaryColor: '#201747',
      secondaryColor: '#CB6015',
      primaryColorDarkMode: '#CB6015',
      primaryColorLightMode: '#201747',
      secondaryColorDarkMode: '#B1B3B3',
      secondaryColorLightMode: '#CB6015',
      sportId: 'WNBA',
    } as Team,
    sport: {
      id: 'WNBA',
      color: '#FA6705',
      gameType: 'Game',
      manual: false,
      name: 'WNBA',
      overUndersEnabled: true,
      periods: 4,
      periodLabel: 'Quarter',
      periodsLabel: 'quarters',
      rank: 10,
      rivalsEnabled: true,
      status: 'active',
      draftStatus: 'active',
      pickemStatus: 'active',
      newsStatus: 'active',
      teamSport: true,
    } as Sport,
    options: [
      {
        id: 'skylar-higher',
        player: 'skylar',
        text: 'Higher',
      },
      {
        id: 'skylar-lower',
        player: 'skylar',
        text: 'Lower',
      },
    ],
  },
];

export const multipliers = ['', '3x', '6x', '10x', '20x'];

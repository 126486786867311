/* eslint-disable camelcase */
import { FeatureFlags, FeatureResponse, UserFeatureResponseKeys } from '@/interfaces/feature-flags';

const featureResponseToObject = (
  features: FeatureResponse['features']
): Record<UserFeatureResponseKeys, boolean> =>
  features.reduce(
    (acc, { key, status }) => {
      acc[key] = status;

      return acc;
    },
    {} as Record<UserFeatureResponseKeys, boolean>
  );

export const featuresAdapter = (features: FeatureResponse['features']): FeatureFlags => {
  const {
    auth0_rollout_sign_in,
    auth0_rollout_sign_up,
    force_classic_pickem_visible,
    force_pickem_pools_visible,
    geo_comply,
    lobby_switch,
    paysafe_deposit,
    pick_em,
    pick_em_insurance,
    sift,
    sportsbook_pre_registration,
    swish_web,
    ud_news,
    web_auth0,
  } = featureResponseToObject(features);

  return {
    auth0RolloutSignIn: auth0_rollout_sign_in,
    auth0RolloutSignUp: auth0_rollout_sign_up,
    forceClassicPickemVisible: force_classic_pickem_visible,
    forcePickemPoolsVisible: force_pickem_pools_visible,
    geoComply: geo_comply,
    lobbySwitch: lobby_switch,
    paysafeDeposit: paysafe_deposit,
    pickEm: pick_em,
    pickEmInsurance: pick_em_insurance,
    sift,
    sportsbookPreRegistration: sportsbook_pre_registration,
    swishWeb: swish_web,
    udNews: ud_news,
    webAuth0: web_auth0,
  };
};

/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { lazy, Suspense, useCallback, useContext, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
// get initial config state
import { connect } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import * as braze from '@braze/web-sdk';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum, RumEvent } from '@datadog/browser-rum';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

import { RootState } from '@/store';
import { updateDraftEntryAutoPickStatus as updateDraftEntryAutoPickStatusAction } from '@/store/modules/active-drafts/actions';
import { getGlobalFeatureFlags as getGlobalFeatureFlagsAction } from '@/store/modules/feature-flags/actions';
import { getStateConfigurations as getStateConfigurationsAction } from '@/store/modules/state-configuration/actions';
import {
  getUserStateConfig as getUserStateConfigAction,
  setUserRegistrationSource as setUserRegistrationSourceAction,
  updateUserBonusCash as updateUserBonusCashAction,
  updateUserIdStatus as updateUserIdStatusAction,
  updateUserWallet as updateUserWalletAction,
} from '@/store/modules/user/actions';

import ErrorFallback from '@/components/atoms/error-fallback';
import Loader from '@/components/atoms/loader';
import { HandleCloseModal, useModal } from '@/components/atoms/modal';
import BranchContext from '@/components/contexts/branch';
import { initPusher, subscribeToPrivateChannels } from '@/components/contexts/pusher-events';
// LAYOUTS
import FullPageLayout from '@/components/layouts/full-page';
import ContentfulPromoInfo from '@/components/molecules/contentful-promo-info';
import DialogModal from '@/components/molecules/dialog-modal';
import { FeatureFlags } from '@/interfaces/feature-flags';
import {
  BonusBalanceUpdateResponse,
  IdentificationStatusChangeResponse,
  RegistrationSourceResponse,
  User,
  WalletBalanceUpdateResponse,
} from '@/interfaces/user';
import { identifyAmplitudeUser, loadAmplitude } from '@/utilities/amplitude';
import geoService from '@/utilities/location/geocomply';
import { AuthRoute, ProtectedMobileRoute, ProtectedRoute } from '@/utilities/routes';

import ZendeskLauncher from './components/atoms/zendesk-launcher';
import LocationModal from './components/molecules/location-modal';
import { AutoPickToggleResponse } from './interfaces/drafts';
import { AllStateConfigurations } from './interfaces/state-configuration';
import { getFeatureFlagFromLocalStorage } from './store/modules/feature-flags/utils';
import { experiment } from './utilities/amplitude/experiment';
import { ALTERNATIVE_HOME_ROUTE, DEFAULT_ROUTE, UD_LOCATION_TOKEN } from './utilities/constants';
import { checkLocationPermissionsGranted } from './utilities/location';

import './styles/global.scss';

// PAGES
const AccountPage = lazy(
  () =>
    import(
      /* webpackChunkName: "account" */
      '@/components/pages/account'
    )
);
const AccountUnblockedPage = lazy(
  () =>
    import(
      /* webpackChunkName: "account-unblocked" */
      '@/components/pages/account-unblocked'
    )
);
const AccountUnlockPage = lazy(
  () =>
    import(
      /* webpackChunkName: "account-unlock" */
      '@/components/pages/account-unlock'
    )
);
const ActivePage = lazy(
  () =>
    import(
      /* webpackChunkName: "active" */
      '@/components/pages/active'
    )
);
const AverageResultsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "average-results" */
      '@/components/pages/average-results'
    )
);
const CareersPage = lazy(
  () =>
    import(
      /* webpackChunkName: "careers" */
      '@/components/pages/careers'
    )
);
const CareersFaqsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "careers-faqs" */
      '@/components/pages/careers-faqs'
    )
);
const ContestLegalityPage = lazy(
  () =>
    import(
      /* webpackChunkName: "contest-legality" */
      '@/components/pages/contest-legality'
    )
);
const CompletedPage = lazy(
  () =>
    import(
      /* webpackChunkName: "completed" */
      '@/components/pages/completed'
    )
);
const DashboardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "dashboard" */
      '@/components/pages/dashboard'
    )
);
const ToTheUnderdogsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "to-the-underdogs" */
      '@/components/pages/to-the-underdogs'
    )
);
const MobileDepositPage = lazy(
  () =>
    import(
      /* webpackChunkName: "mobile-deposit" */
      '@/components/pages/mobile-deposit'
    )
);
const DepositPage = lazy(
  () =>
    import(
      /* webpackChunkName: "deposit" */
      '@/components/pages/deposit'
    )
);
const DepositSuccessPage = lazy(
  () =>
    import(
      /* webpackChunkName: "deposit-success" */
      '@/components/pages/deposit-success'
    )
);
const DraftPage = lazy(
  () =>
    import(
      /* webpackChunkName: "draft" */
      '@/components/pages/draft'
    )
);
const DraftBoardPage = lazy(
  () =>
    import(
      /* webpackChunkName: "draft-board" */
      '@/components/pages/draft-board'
    )
);
const EmailConfirmationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "email-confirmation" */
      '@/components/pages/email-confirmation'
    )
);
const LoginConfirmationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "login-confirmation" */
      '@/components/pages/login-confirmation'
    )
);
const ForgotPasswordPage = lazy(
  () =>
    import(
      /* webpackChunkName: "forgot-password" */
      '@/components/pages/forgot-password'
    )
);
const GuardDogPage = lazy(
  () =>
    import(
      /* webpackChunkName: "guard-dog" */
      '@/components/pages/guard-dog'
    )
);
const LandingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "landing" */
      '@/components/pages/landing'
    )
);
const LivePage = lazy(
  () =>
    import(
      /* webpackChunkName: "live" */
      '@/components/pages/live'
    )
);
const LoginPage = lazy(
  () =>
    import(
      /* webpackChunkName: "login" */
      '@/components/pages/login'
    )
);
const ExposurePage = lazy(
  () =>
    import(
      /* webpackChunkName: "exposure" */
      '@/components/pages/exposure'
    )
);
const LobbyPage = lazy(
  () =>
    import(
      /* webpackChunkName: "lobby" */
      '@/components/pages/lobby'
    )
);
const NewsFeedPage = lazy(
  () =>
    import(
      /* webpackChunkName: "news-feed" */
      '@/components/pages/news-feed'
    )
);
const PickEmPage = lazy(
  () =>
    import(
      /* webpackChunkName: "pick-em" */
      '@/components/pages/pick-em'
    )
);
const PromosPage = lazy(
  () =>
    import(
      /* webpackChunkName: "promos" */
      '@/components/pages/promos'
    )
);
const RankingsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "rankings" */
      '@/components/pages/rankings'
    )
);
const RegisterPage = lazy(
  () =>
    import(
      /* webpackChunkName: "register" */
      '@/components/pages/register'
    )
);
const ResetCachePage = lazy(
  () =>
    import(
      /* webpackChunkName: "reset-cache" */
      '@/components/pages/reset-cache'
    )
);
const ResetPasswordPage = lazy(
  () =>
    import(
      /* webpackChunkName: "reset-password" */
      '@/components/pages/reset-password'
    )
);
const ResponsibleGamingResourcesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "responsible-gaming-resources" */
      '@/components/pages/responsible-gaming-resources'
    )
);
const ResultsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "results" */
      '@/components/pages/results'
    )
);
const RulesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "rules" */
      '@/components/pages/rules'
    )
);
const RulesRoutes = lazy(
  () =>
    import(
      /* webpackChunkName: "rules" */
      '@/components/pages/rules/components/rules-routes'
    )
);
const SplashPage = lazy(
  () =>
    import(
      /* webpackChunkName: "splash" */
      '@/components/pages/splash'
    )
);
const SweepstakeTermsAndConditionsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "sweepstake-terms-and-conditions" */
      '@/components/pages/sweepstake-terms-and-conditions'
    )
);
const TaxPage = lazy(
  () =>
    import(
      /* webpackChunkName: "tax" */
      '@/components/pages/tax'
    )
);
const TrustlyCancelDepositPage = lazy(
  () =>
    import(
      /* webpackChunkName: "trustly-cancel-deposit" */
      '@/components/pages/trustly-cancel-deposit'
    )
);
const TrustlyLoadingDepositPage = lazy(
  () =>
    import(
      /* webpackChunkName: "trustly-loading-deposit" */
      '@/components/pages/trustly-loading-deposit'
    )
);
const VerificationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "verification" */
      '@/components/pages/verification'
    )
);
const WithdrawalConfirmationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "withdraw-confirmation" */
      '@/components/pages/withdrawal-confirmation'
    )
);

interface AppProps {
  featureFlags: FeatureFlags;
  getGlobalFeatureFlags: () => void;
  getStateConfigurations: () => void;
  getUserStateConfig: () => void;
  setUserRegistrationSource: (data: RegistrationSourceResponse) => void;
  stateConfigurations: AllStateConfigurations;
  updateUserIdStatus: (data: IdentificationStatusChangeResponse) => void;
  updateUserWallet: (data: WalletBalanceUpdateResponse) => void;
  updateUserBonusCash: (data: BonusBalanceUpdateResponse) => void;
  updateDraftEntryAutoPickStatus: (data: AutoPickToggleResponse) => void;
  user: User;
}

// Loads amplitude
loadAmplitude();

const App = (props: AppProps): JSX.Element => {
  const {
    featureFlags,
    getGlobalFeatureFlags,
    getStateConfigurations,
    getUserStateConfig,
    setUserRegistrationSource,
    stateConfigurations,
    updateUserIdStatus,
    updateUserWallet,
    updateUserBonusCash,
    updateDraftEntryAutoPickStatus,
    user,
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const branch = useContext(BranchContext);
  const openModal = useModal();

  const isOnDraftPage = /^\/draft\/([a-zA-Z]?[0-9]?-?)*/.test(location.pathname);

  const isFeatureFlagsLoaded = Object.keys(featureFlags).length > 0;
  const isMobilePage = /^\/m\//.test(location.pathname) || /^\/splash\/m\//.test(location.pathname);

  const handleAutoAutoPilotModal = useCallback((data: AutoPickToggleResponse) => {
    openModal(({ handleCloseModal }: HandleCloseModal) => (
      <DialogModal
        handleCloseModal={handleCloseModal}
        title="Autopilot notice"
        content={
          <p>
            Heads up! Autopilot has been enabled because you&apos;ve missed two consecutive picks.
            You can turn it off at any time from inside the draft room.
          </p>
        }
        confirmText="Go to draft"
        confirmAction={() => navigate(`active/${data.draft_id}`)}
        dismissText="Ok"
      />
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isSiftFeatureEnabled = getFeatureFlagFromLocalStorage('sift');
    if (isSiftFeatureEnabled && window._sift) {
      const siftBeacon = process.env.APP_ENV === 'production' ? 'a91e4ccf57' : '266433fe52';
      window._sift.push(['_setUserId', '']);
      window._sift.push(['_setAccount', siftBeacon]);
      window._sift.push(['_setSessionId', uuidv4()]);
    }
  }, []);

  useEffect(() => {
    const userId = user?.id;
    const registrationSource = user?.registrationSource;

    identifyAmplitudeUser({ userId, registrationSource });
  }, [user.id, user.registrationSource]);

  useEffect(() => {
    experiment.fetch();
  }, [user.id]);

  useEffect(() => {
    if (user?.id) {
      initPusher();
      const privateChannel = subscribeToPrivateChannels(user.id);
      privateChannel.bind(
        'identification_status_change',
        (data: IdentificationStatusChangeResponse) => {
          updateUserIdStatus(data);
        }
      );
      privateChannel.bind('balance_update', (data: WalletBalanceUpdateResponse) => {
        updateUserWallet(data);
      });
      privateChannel.bind('bonus_wallet_balance_update', (data: BonusBalanceUpdateResponse) => {
        updateUserBonusCash(data);
      });
      privateChannel.bind('auto_pick_enabled', (data: AutoPickToggleResponse) => {
        updateDraftEntryAutoPickStatus(data);
        if (data.auto_pick === 'system') {
          handleAutoAutoPilotModal(data);
        }
      });
      privateChannel.bind('registration_acquisition', (data: RegistrationSourceResponse) => {
        setUserRegistrationSource(data);
      });
    }
  }, [
    handleAutoAutoPilotModal,
    setUserRegistrationSource,
    updateDraftEntryAutoPickStatus,
    updateUserBonusCash,
    updateUserIdStatus,
    updateUserWallet,
    user?.id,
  ]); // needs to be user?.id with the ?

  useEffect(() => {
    if (user?.id) {
      if (braze) {
        braze.changeUser(user.id);
        braze.openSession();
      }

      if (datadogRum) {
        datadogRum.setUserProperty('id', user.id);
      }

      if (featureFlags.sift && window._sift) {
        window._sift.push(['_setUserId', user.id]);
      }
    }
  }, [featureFlags.sift, user?.id]);

  useEffect(() => {
    const enableLocalLogging = false; // toggle this to send local errors to datadog

    if (process.env.APP_ENV === 'production' || enableLocalLogging) {
      datadogRum.init({
        beforeSend: (event: RumEvent) => {
          if (event?.type === 'error') {
            const message = event?.error?.message;
            if (message?.includes('/v1/usernames')) return false;
            if (message?.includes('/v1/promotions/promo_code')) return false;
            if (message?.includes('/v1/promotions/user_referral')) return false;
            return true;
          }
          return true;
        },
        applicationId: process.env.DATADOG_APPLICATION_ID,
        clientToken: 'pubd3c63a4b58fa5f1c43894daefa6a606c',
        site: 'datadoghq.com',
        service: 'web-app',
        env: enableLocalLogging ? 'dev' : 'production', // always 'dev' or 'production'
        version: process.env.SHORT_SHA, // needs to match sourcemap upload
        sessionSampleRate: 100, // monitor 100% user sessions
        sessionReplaySampleRate: 1, // collect 1% replay sessions
        trackUserInteractions: true,
        defaultPrivacyLevel: 'mask-user-input', // only hide user input
      });

      if (process.env.APP_ENV === 'production') {
        datadogRum.startSessionReplayRecording();
      }
    }
  }, []);

  useEffect(() => {
    const enableLocalLogging = false; // toggle this to send local info logs to datadog

    datadogLogs.init({
      clientToken: process.env.DATADOG_BROWSER_LOG_API_KEY,
      forwardErrorsToLogs: false, // don't send errors, rely on RUM for that
      sessionSampleRate: 100,
      env: process.env.APP_ENV === 'production' ? 'production' : 'staging',
      version: process.env.SHORT_SHA || 'development',
      service: 'web-app',
      site: 'datadoghq.com',
      beforeSend: (event: any) => {
        // only send messages to datadog if not in a 'local' environment.
        // and if in local, log the message to the console for visibility.
        // IMPORTANT: do _not_ use `console.error` or `console.warn` in this function,
        // doing so will cause an infinite loop because the datadog client is configured
        // to forward console errors/warnings to datadog. So if you use console.error/warn
        // here, it will be captured by the logger and run the `beforeSend` function ad infinitum.
        if (process.env.APP_ENV !== 'production' && !enableLocalLogging) {
          // eslint-disable-next-line no-console
          console.log('not sending log message to datadog because env is local', event);

          return false;
        }
        return true;
      },
    });
  }, []);

  useEffect(() => {
    const isGeoComplyFeatureEnabled = getFeatureFlagFromLocalStorage('geoComply');
    if (isGeoComplyFeatureEnabled && user?.id) {
      checkLocationPermissionsGranted().then((permissionGranted) => {
        // if it's a mobile page and we have the location cookie, we don't need to do this
        // if the user sits here too long though, it will error
        if (!(isMobilePage && Cookies.get(UD_LOCATION_TOKEN))) {
          if (permissionGranted) {
            geoService.init({ userId: user.id });
          } else {
            openModal(({ handleCloseModal }: HandleCloseModal) => (
              <LocationModal
                handleCloseModal={handleCloseModal}
                confirmAction={async () => geoService.init({ userId: user.id })}
                errorContext={{ location: 'app.tsx' }}
              />
            ));
          }
        }
      });
    }
  }, [isMobilePage, openModal, user?.id]);

  useEffect(() => {
    if (!isFeatureFlagsLoaded) {
      getGlobalFeatureFlags();
    }
  }, [getGlobalFeatureFlags, isFeatureFlagsLoaded]);

  // TODO [FAN-1146] Currently we aren't refetching the state config without a refresh if the user disables
  // and then reenables location, so this will be handled in a later ticket (see ticket for more details)
  useEffect(() => {
    if (user?.id && !user?.stateConfig) {
      getUserStateConfig();
    }
  }, [getUserStateConfig, user?.id, user?.stateConfig]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (branch?.branchData?.data?.route === 'draft' && !isOnDraftPage && user?.id) {
      const objectId = branch?.branchData?.data?.objectId;
      // clear the route prop so that this doesn't accidentally force a loop
      branch.setBranchData({
        data: {
          ...branch.branchData?.data,
          route: null,
        },
        error: branch.branchData?.error,
      });
      if (objectId) {
        navigate(`/draft/${objectId}`);
      }
    }

    if (branch?.branchData?.data?.route === 'entry_slip' && user?.id) {
      const objectId = branch?.branchData?.data?.objectId;
      // clear the route prop so that this doesn't accidentally force a loop
      branch.setBranchData({
        data: {
          ...branch.branchData?.data,
          route: null,
        },
        error: branch.branchData?.error,
      });
      if (objectId) {
        navigate(`/pick-em/higher-lower?entrySlipShareId=${objectId}`);
      }
    }
  }, [branch, navigate, isOnDraftPage, user?.id]);

  useEffect(() => {
    if (window.Socure) {
      // this invalidates the temporary socure token (kills status events in network tab)
      // important in case use leaves the doc upload flow in middle of process
      window.Socure.cleanup();
      delete window.Socure;
    }

    if (window._sift) {
      window._sift.push(['_trackPageview']);
    }
  }, [location.pathname]);

  useEffect(() => {
    const isStateConfigEmpty = Object.keys(stateConfigurations).length > 0;
    if (!isStateConfigEmpty) {
      getStateConfigurations();
    }
  }, [getStateConfigurations, stateConfigurations]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <ZendeskLauncher isMobilePage={isMobilePage} pathname={location.pathname} />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path="/account-unblocked"
            element={
              <AuthRoute>
                <AccountUnblockedPage />
              </AuthRoute>
            }
          />
          <Route path="/account-unlock" element={<AccountUnlockPage />} />
          <Route path="/average-results" element={<AverageResultsPage />} />
          <Route path="/m/average-results" element={<AverageResultsPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/m/careers" element={<CareersPage />} />
          <Route path="/careers-faqs" element={<CareersFaqsPage />} />
          <Route path="/m/careers-faqs" element={<CareersFaqsPage />} />
          <Route path="/to-the-underdogs" element={<ToTheUnderdogsPage />} />
          <Route path="/legality-underdog-contests" element={<ContestLegalityPage />} />
          <Route path="/m/guarddog" element={<GuardDogPage />} />
          <Route path="/guarddog" element={<GuardDogPage />} />
          <Route path="/email-confirmation" element={<EmailConfirmationPage />} />
          <Route path="/withdrawal-confirmation" element={<WithdrawalConfirmationPage />} />
          {/* TODO: remove this after mobile sets up contentful */}
          <Route
            path="/m/promo-info/:contentfulInfoId"
            element={<ContentfulPromoInfo contentfulInfoId="" handleCloseModal={null} />}
          />
          <Route path="/rules/*" element={<RulesPage />}>
            <Route path="*" element={<RulesRoutes />} />
          </Route>
          <Route path="/m/rules/*" element={<RulesPage />}>
            <Route path="*" element={<RulesRoutes />} />
          </Route>
          <Route path="/splash/*" element={<SplashPage />} />
          <Route
            path="/responsible-gaming-resources"
            element={
              <FullPageLayout hasNav hasFooter layout="wide">
                <ResponsibleGamingResourcesPage />
              </FullPageLayout>
            }
          />
          <Route
            path="/m/responsible-gaming-resources"
            element={
              <FullPageLayout hasFooter layout="wide">
                <ResponsibleGamingResourcesPage />
              </FullPageLayout>
            }
          />
          <Route path="/reset-cache" element={<ResetCachePage />} />
          <Route
            path="/terms-and-conditions/:slug"
            element={<SweepstakeTermsAndConditionsPage />}
          />

          {/* Registration and login routes */}
          <Route
            path="/"
            element={
              <AuthRoute>
                <LandingPage />
              </AuthRoute>
            }
          />
          <Route
            path="/promos"
            element={
              <AuthRoute>
                <PromosPage />
              </AuthRoute>
            }
          />
          <Route
            path="/register"
            element={
              <AuthRoute>
                <RegisterPage />
              </AuthRoute>
            }
          />
          <Route
            path="/login"
            element={
              <AuthRoute>
                <LoginPage />
              </AuthRoute>
            }
          />
          <Route
            path="/reset-password"
            element={
              <AuthRoute>
                <ResetPasswordPage />
              </AuthRoute>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <AuthRoute>
                <ForgotPasswordPage />
              </AuthRoute>
            }
          />
          <Route
            path="/login-confirmation"
            element={
              <AuthRoute>
                <LoginConfirmationPage />
              </AuthRoute>
            }
          />
          {/* Protected routes where you have to be logged in */}
          <Route
            path="/account/*"
            element={
              <ProtectedRoute>
                <AccountPage />
              </ProtectedRoute>
            }
          >
            {/* <Route path="*" element={(<ProtectedRoute><AccountPage /></ProtectedRoute>)} /> */}
          </Route>
          <Route
            path="/active"
            element={
              <ProtectedRoute>
                <ActivePage />
              </ProtectedRoute>
            }
          >
            <Route
              path=":draftId"
              element={
                <ProtectedRoute>
                  <ActivePage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/deposit"
            element={
              <ProtectedRoute>
                <DepositPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/deposit-success/:amount"
            element={
              <ProtectedRoute>
                <DepositSuccessPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/draft"
            element={
              <ProtectedRoute>
                <DraftPage />
              </ProtectedRoute>
            }
          >
            <Route
              path=":draftId"
              element={
                <ProtectedRoute>
                  <DraftPage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/draft-board/:draftId"
            element={
              <ProtectedRoute hideNav>
                <DraftBoardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/completed"
            element={
              <ProtectedRoute>
                <CompletedPage />
              </ProtectedRoute>
            }
          >
            <Route
              path=":slateId"
              element={
                <ProtectedRoute>
                  <CompletedPage />
                </ProtectedRoute>
              }
            >
              {/* draftType is (sng|tr|br) */}
              <Route
                path=":draftType"
                element={
                  <ProtectedRoute>
                    <CompletedPage />
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":selectedId"
                  element={
                    <ProtectedRoute>
                      <CompletedPage />
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Route>
          </Route>
          <Route
            path="/live"
            element={
              <ProtectedRoute>
                <LivePage />
              </ProtectedRoute>
            }
          >
            {/* gameType is (daily|best-ball|pick-em) */}
            <Route
              path=":gameType"
              element={
                <ProtectedRoute>
                  <LivePage />
                </ProtectedRoute>
              }
            >
              <Route
                path=":sportId"
                element={
                  <ProtectedRoute>
                    <LivePage />
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":slateId"
                  element={
                    <ProtectedRoute>
                      <LivePage />
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Route>
          </Route>
          <Route
            path="/exposure"
            element={
              <ProtectedRoute>
                <ExposurePage />
              </ProtectedRoute>
            }
          >
            <Route
              path=":slateId"
              element={
                <ProtectedRoute>
                  <ExposurePage />
                </ProtectedRoute>
              }
            >
              <Route
                path=":contestStyleId"
                element={
                  <ProtectedRoute>
                    <ExposurePage />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
          <Route
            path="/lobby"
            element={
              <ProtectedRoute>
                <LobbyPage />
              </ProtectedRoute>
            }
          >
            <Route
              path=":sportId"
              element={
                <ProtectedRoute>
                  <LobbyPage />
                </ProtectedRoute>
              }
            >
              <Route
                path=":slateId"
                element={
                  <ProtectedRoute>
                    <LobbyPage />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
          <Route
            path="/news-feed"
            element={
              <ProtectedRoute>
                <NewsFeedPage />
              </ProtectedRoute>
            }
          >
            <Route
              path=":sportId"
              element={
                <ProtectedRoute>
                  <NewsFeedPage />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/pick-em"
            element={
              <ProtectedRoute>
                <PickEmPage />
              </ProtectedRoute>
            }
          >
            {/* pageType is (higher-lower|rivals|favorites) react-router-dom won't let us specify this */}
            <Route
              path=":pageType"
              element={
                <ProtectedRoute>
                  <PickEmPage />
                </ProtectedRoute>
              }
            >
              <Route
                path=":pickTiming"
                element={
                  <ProtectedRoute>
                    <PickEmPage />
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":sportId"
                  element={
                    <ProtectedRoute>
                      <PickEmPage />
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Route>
          </Route>
          <Route
            path="/rankings"
            element={
              <ProtectedRoute>
                <RankingsPage />
              </ProtectedRoute>
            }
          >
            <Route
              path=":sportId"
              element={
                <ProtectedRoute>
                  <RankingsPage />
                </ProtectedRoute>
              }
            >
              <Route
                path=":slateId"
                element={
                  <ProtectedRoute>
                    <RankingsPage />
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
          <Route
            path="/results"
            element={
              <ProtectedRoute>
                <ResultsPage />
              </ProtectedRoute>
            }
          >
            {/* gameType is (drafts|pick-em) */}
            <Route
              path=":gameType"
              element={
                <ProtectedRoute>
                  <ResultsPage />
                </ProtectedRoute>
              }
            >
              <Route
                path=":sportId"
                element={
                  <ProtectedRoute>
                    <ResultsPage />
                  </ProtectedRoute>
                }
              >
                <Route
                  path=":slateId"
                  element={
                    <ProtectedRoute>
                      <ResultsPage />
                    </ProtectedRoute>
                  }
                />
              </Route>
            </Route>
          </Route>
          <Route
            path="/tax"
            element={
              <ProtectedRoute>
                <FullPageLayout layout="narrow">
                  <TaxPage />
                </FullPageLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/trustly-cancel-deposit"
            element={
              <ProtectedRoute>
                <TrustlyCancelDepositPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="trustly-loading-deposit"
            element={
              <ProtectedRoute>
                <TrustlyLoadingDepositPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/verification"
            element={
              <ProtectedRoute>
                <FullPageLayout layout="narrow">
                  <VerificationPage />
                </FullPageLayout>
              </ProtectedRoute>
            }
          />

          {/* Mobile routes */}
          <Route
            path="/m/credit-card-deposit"
            element={
              <ProtectedMobileRoute>
                <MobileDepositPage />
              </ProtectedMobileRoute>
            }
          />
          <Route
            path="/m/deposit"
            element={
              <ProtectedMobileRoute>
                <DepositPage />
              </ProtectedMobileRoute>
            }
          />
          <Route
            path="/m/deposit-success/:amount"
            element={
              <ProtectedMobileRoute>
                <DepositSuccessPage />
              </ProtectedMobileRoute>
            }
          />
          <Route
            path="/m/tax"
            element={
              <ProtectedMobileRoute>
                <FullPageLayout layout="narrow">
                  <TaxPage />
                </FullPageLayout>
              </ProtectedMobileRoute>
            }
          />
          <Route
            path="/m/verification"
            element={
              <ProtectedMobileRoute>
                <FullPageLayout layout="narrow">
                  <VerificationPage />
                </FullPageLayout>
              </ProtectedMobileRoute>
            }
          />
          <Route
            path="/m/news-feed/:sportId"
            element={
              <ProtectedMobileRoute>
                <NewsFeedPage />
              </ProtectedMobileRoute>
            }
          />
          {/* Redirect 404s to the lobby */}
          <Route
            path="*"
            element={
              <Navigate
                to={featureFlags.lobbySwitch ? ALTERNATIVE_HOME_ROUTE : DEFAULT_ROUTE}
                replace
                state="redirected"
              />
            }
          />
        </Routes>
      </Suspense>
      <ZendeskLauncher isMobilePage={isMobilePage} pathname={location.pathname} />
    </ErrorBoundary>
  );
};

export default connect(
  (state: RootState) => ({
    featureFlags: state.featureFlags,
    stateConfigurations: state.stateConfigurations,
    user: state.user,
  }),
  (dispatch) => ({
    getGlobalFeatureFlags: () => dispatch(getGlobalFeatureFlagsAction()),
    getStateConfigurations: () => dispatch(getStateConfigurationsAction()),
    getUserStateConfig: () => dispatch(getUserStateConfigAction()),
    setUserRegistrationSource: (data: RegistrationSourceResponse) =>
      dispatch(setUserRegistrationSourceAction(data)),
    updateUserIdStatus: (data: IdentificationStatusChangeResponse) =>
      dispatch(updateUserIdStatusAction(data)),
    updateUserWallet: (data: WalletBalanceUpdateResponse) => dispatch(updateUserWalletAction(data)),
    updateUserBonusCash: (data: BonusBalanceUpdateResponse) =>
      dispatch(updateUserBonusCashAction(data)),
    updateDraftEntryAutoPickStatus: (data: AutoPickToggleResponse) =>
      dispatch(updateDraftEntryAutoPickStatusAction(data)),
  })
)(App);

import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { RootState } from '@/store';

import LinkWrapper from '@/components/atoms/link-wrapper';
import SupportLink from '@/components/atoms/support-link';
import { ThemeContext } from '@/components/contexts/theme';
import ResponsibleGamingCard from '@/components/molecules/responsible-gaming-card';
import { User } from '@/interfaces/user';
import { CDN_HOST } from '@/utilities/constants';

import styles from './styles.scss';

interface FooterProps {
  user: User;
}

const Footer = (props: FooterProps) => {
  const { user } = props;
  const { theme } = useContext(ThemeContext);

  const location = useLocation();
  const isLoggedIn = !!user?.id;

  // `isMobilePage` refers to  whether this was linked to from a mobile device
  const isMobilePage = /^\/m\//.test(location.pathname);
  const year = new Date().getFullYear();

  // Show the responsible gaming text on the landing page and lobby
  const isLandingPage = location.pathname === '/';
  const isLobby = location.pathname === '/lobby';

  return (
    <div>
      <div className={styles.footer}>
        <div className={styles.logoCol}>
          <h1 className={styles.logoType}>Underdog Fantasy</h1>
          <p className={styles.madeIn}>Made in Brooklyn, NY</p>
          <p className={styles.copyright}>Copyright &copy; {year} All rights reserved</p>
          <p className={styles.gamingText}>
            If you or someone you know has a gambling problem, help is available. Call 1-800-GAMBLER
            or go to&nbsp;
            <a
              href="https://www.ncpgambling.org/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.gamingLink}
            >
              ncpgambling.org
            </a>
            .
          </p>
          <a href="https://underdogpromocode.com/" className={styles.backLink} tabIndex={-1}>
            Underdog promo page
          </a>
          <div className={styles.logoWrapper}>
            <span className={styles.ncpgLogoWrapper}>
              <img
                src={`${CDN_HOST}/logos/ncpg_silver-400.png`}
                alt="NCPG National Council on Problem Gaming"
              />
            </span>
            <span className={styles.rgLogoWrapper}>
              <LinkWrapper
                to="/responsible-gaming-resources"
                className=""
                element={<img src={`${CDN_HOST}/logos/RG_${theme}.png`} alt="Responsible Gaming" />}
              />
            </span>
          </div>
        </div>
        <div className={styles.aboutCol}>
          <p className={styles.colTitle}>About</p>
          <SupportLink className={styles.link} label="Contact support" />
          <a
            className={styles.link}
            href="mailto:media@underdogfantasy.com"
            rel="noopener noreferrer"
            title="media@underdogfantasy.com"
          >
            Media inquiries
            <span className={styles.subtext}>media@underdogfantasy.com</span>
          </a>
          <a
            className={styles.link}
            href="mailto:marketing@underdogfantasy.com"
            rel="noopener noreferrer"
            title="marketing@underdogfantasy.com"
          >
            Partnership inquiries
            <span className={styles.subtext}>marketing@underdogfantasy.com</span>
          </a>
          <LinkWrapper
            to={isMobilePage ? '/m/rules' : '/rules'}
            className={styles.link}
            element="Rules &amp; scoring"
          />
          <LinkWrapper
            className={styles.link}
            to={isMobilePage ? '/m/careers' : '/careers'}
            target="_self"
            element="Careers (We're hiring)"
          />
          {!isLoggedIn && !isMobilePage && (
            <LinkWrapper
              className={styles.link}
              to="/promos"
              target="_self"
              element="Underdog Fantasy Promo Code"
            />
          )}
          <LinkWrapper
            className={styles.link}
            to={isMobilePage ? '/m/guarddog' : '/guarddog'}
            target="_self"
            element="GuardDog"
          />
          <LinkWrapper
            className={styles.link}
            to={isMobilePage ? '/m/average-results' : '/average-results'}
            target="_self"
            element="Average Results"
          />
          <LinkWrapper
            to="/responsible-gaming-resources"
            className={styles.link}
            element="Responsible Gaming"
          />
          <LinkWrapper
            target={isMobilePage ? '_self' : '_blank'}
            to="/terms"
            className={styles.link}
            element="Terms"
          />
          <LinkWrapper
            target={isMobilePage ? '_self' : '_blank'}
            to="/privacy-policy"
            className={styles.link}
            element="Privacy Policy"
          />
        </div>
        <div className={styles.socialCol}>
          <p className={styles.colTitle}>Social</p>
          <a
            className={styles.link}
            href="https://twitter.com/UnderdogFantasy"
            target={isMobilePage ? '_self' : '_blank'}
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            className={styles.link}
            href="https://underdognetwork.com"
            target={isMobilePage ? '_self' : '_blank'}
            rel="noopener noreferrer"
          >
            Underdog Network
          </a>
          <a
            className={styles.link}
            href="https://www.youtube.com/channel/UC7fnz7139CGSdtHWaPMCpIw"
            target={isMobilePage ? '_self' : '_blank'}
            rel="noopener noreferrer"
          >
            YouTube
          </a>
          <a
            className={styles.link}
            href="https://facebook.com/underdogfantasy"
            target={isMobilePage ? '_self' : '_blank'}
            rel="noopener noreferrer"
          >
            Facebook
          </a>
          <a
            className={styles.link}
            href="https://www.instagram.com/underdogfantasy"
            target={isMobilePage ? '_self' : '_blank'}
            rel="noopener noreferrer"
          >
            Instagram
          </a>
          <a
            className={styles.link}
            href="https://www.linkedin.com/company/underdogfantasy"
            target={isMobilePage ? '_self' : '_blank'}
            rel="noopener noreferrer"
          >
            LinkedIn
          </a>
          <a
            className={styles.link}
            href="https://discord.com/invite/fE89fPvg6A"
            target={isMobilePage ? '_self' : '_blank'}
            rel="noopener noreferrer"
          >
            Discord
          </a>
        </div>
        <div className={styles.appsCol}>
          <p className={styles.colTitle}>Apps</p>
          <a
            className={styles.link}
            href="https://apps.apple.com/us/app/underdog-fantasy/id1514665962"
            target={isMobilePage ? '_self' : '_blank'}
            rel="noopener noreferrer"
          >
            iOS app
          </a>
          <a
            className={styles.link}
            href="https://play.google.com/store/apps/details?id=com.underdogsports.fantasy"
            target={isMobilePage ? '_self' : '_blank'}
            rel="noopener noreferrer"
          >
            Android app
          </a>
          <a
            className={styles.link}
            href="https://storage.googleapis.com/underdog/android/app.apk"
            rel="noopener noreferrer"
            title="Download"
          >
            Android app (Canada)
          </a>
        </div>
      </div>
      {(isLandingPage || isLobby) && (
        <div
          className={clsx(
            styles.responsibleGamingContainer,
            isLandingPage && styles.responsibleGamingContainerLanding
          )}
        >
          <ResponsibleGamingCard
            authenticated={!isLandingPage}
            iconPosition="left"
            textType={isLandingPage ? 'rgLandingText' : 'rgDraftText'}
            variant="large"
          />
        </div>
      )}
    </div>
  );
};

export default connect((state: RootState) => ({
  user: state.user,
}))(Footer);

import { ConstructedPickEmOverUnderLineAppearance } from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { Appearance } from '@/interfaces/constructed-interfaces/constructed-slates';
import { LineupStatuses, Sports, Teams } from '@/interfaces/drafting-config';
import { ByeWeek, Matches } from '@/interfaces/matches';
import { FavoritePlayers, OverUnderLine, SoloGames } from '@/interfaces/pick-em';

export const pickEmOverUnderAppearanceConstructor = ({
  appearance,
  games,
  lineupStatuses,
  players,
  overUnderLines: allOverUnderLines,
  soloGames,
  sports,
  teams,
}: {
  appearance: Appearance;
  games: Matches;
  lineupStatuses: LineupStatuses;
  players: FavoritePlayers;
  overUnderLines: OverUnderLine[];
  soloGames: SoloGames;
  sports: Sports;
  teams: Teams;
}): ConstructedPickEmOverUnderLineAppearance => {
  const overUnderLines = allOverUnderLines.filter(
    (oUL) => oUL.overUnder.appearanceStat.appearanceId === appearance.id
  );

  if (overUnderLines.length === 0) {
    return null;
  }
  const team = teams[appearance.teamId];
  const player = players[appearance.playerId];
  const sport = sports[player?.sportId];
  const lineupStatus = appearance.lineupStatusId ? lineupStatuses[appearance.lineupStatusId] : null;
  const match = appearance.matchType === 'Game' ? games[appearance?.matchId] : null;
  const soloGame = appearance.matchType === 'SoloGame' ? soloGames[appearance?.matchId] : null;
  const byeWeek = match && match.byeWeeks ? match.byeWeeks[appearance.teamId] : (null as ByeWeek);

  // sort over unders with boosts, sort by rank
  const featuredOverUnderLines = overUnderLines
    .filter((oUL) => oUL.overUnder.boost)
    .sort((a, b) => a.rank - b.rank);

  const playerOverUnderLines = overUnderLines
    .filter((oUL) => !oUL.overUnder?.boost)
    .sort((a, b) => a.rank - b.rank);
  const sortedOverUnderLines = [...featuredOverUnderLines, ...playerOverUnderLines];

  return {
    id: appearance.id,
    byeWeek,
    latestNewsItemUpdatedAt: appearance.latestNewsItemUpdatedAt,
    lineupStatus,
    match,
    matchType: appearance.matchType,
    overUnderLines: sortedOverUnderLines,
    player,
    projection: appearance.projection,
    positionId: appearance.positionId,
    rank: playerOverUnderLines[0]?.rank || sortedOverUnderLines[0]?.rank, // add rank first rank from regular lines
    score: appearance.score,
    soloGame,
    sport,
    team,
  };
};

export const pickEmOverUnderAppearancesConstructor = ({
  appearances,
  games,
  lineupStatuses,
  players,
  overUnderLines,
  soloGames,
  sports,
  teams,
}: {
  appearances: Appearance[];
  games: Matches;
  lineupStatuses: LineupStatuses;
  players: FavoritePlayers;
  overUnderLines: OverUnderLine[];
  soloGames: SoloGames;
  sports: Sports;
  teams: Teams;
}): ConstructedPickEmOverUnderLineAppearance[] =>
  appearances
    .map((appearance) =>
      pickEmOverUnderAppearanceConstructor({
        appearance,
        games,
        lineupStatuses,
        players,
        overUnderLines,
        soloGames,
        sports,
        teams,
      })
    )
    .filter((cA) => !!cA)
    .sort((a, b) => a.rank - b.rank);

import React from 'react';
import clsx from 'clsx';

import detailedIcons from './detailed-icons';
import allIcons from './icons';

import styles from './styles.scss';

export type IconTypes = keyof typeof allIcons | keyof typeof detailedIcons;

export const combinedIcons = { ...allIcons, ...detailedIcons };

export interface IconProps {
  className?: string;
  customStyles?: {
    fill?: string;
  };
  name: IconTypes;
  title?: string;
}

const Icon = ({ name, className, customStyles, title }: IconProps): JSX.Element => {
  const iconSVG = combinedIcons[name];
  if (!iconSVG) return null;
  return (
    <i
      className={clsx(styles.icon, className)}
      title={title}
      data-testid="test-icon"
      style={{
        fill: customStyles?.fill,
      }}
    >
      {iconSVG}
    </i>
  );
};

Icon.defaultProps = {
  className: '',
};

export default Icon;

import {
  IdentifyProperties,
  PickemEntryCreatedInsuredProperties,
  PickemEntryCreatedStandardProperties,
  PickemEntrySubmissionSharedProperties,
  PickemHigherLowerSelectionAddedProperties,
  PickemRivalsSelectionAddedProperties,
} from '@/ampli';
import { SelectedOverUnder } from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { SelectedRival } from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { OverUnderLine, PayoutOption, PickLocation, SelectedOption } from '@/interfaces/pick-em';
import { SportId } from '@/interfaces/types';
import { RegistrationSource } from '@/interfaces/user';

import langHelper from '../lang-helper';

interface AmpliBoost {
  id: string;
  boostType: 'none' | 'payout booster' | 'special' | 'sweepstakes' | 'discount';
}

interface SelectedLineTypes {
  selectedOverUnders: SelectedOverUnder[];
  selectedRivals: SelectedRival[];
}

interface NewSelection<T> {
  lobbyName?: SportId;
  pickLocation: PickLocation;
  selection: T;
}

interface PickEmEntrySlip {
  entryAmount: string;
  entrySlipId: string;
  payoutOptions: PayoutOption[];
  selectedOptions: SelectedOption[];
  selectedOverUnders: SelectedOverUnder[];
  selectedRivals: SelectedRival[];
  calculatedCoefficient?: number;
  correlationBlockersById?: { [id: string]: string[] };
}

interface SharedEventProperties {
  entry_amount: number;
  entry_id: string;
  number_of_picks_higher: number;
  number_of_picks_in_game: number;
  number_of_picks_lower: number;
  number_of_picks_rivals: number;
  number_of_picks_total: number;
  player_ids: string[];
  player_names: string[];
  sport_names: string[];
  shifted_payout_multiplier: number;
  has_shifted_payout: boolean;
  number_of_correlated_selections: number;
}

// formats selected overunderlines for amplitude
export const formatSOULine = ({
  lobbyName,
  selection,
  pickLocation,
}: NewSelection<SelectedOverUnder>): PickemHigherLowerSelectionAddedProperties => {
  const {
    constructedOverUnderAppearance: { overUnderLines, player },
    option,
  } = selection;
  const currentOU = overUnderLines.find((oUL) => oUL.id === option.overUnderLineId);
  const choice = option.choice as 'higher' | 'lower';
  const boost = getBoost(currentOU);
  const { displayStat, stat } = currentOU.overUnder.appearanceStat;

  return {
    boost_type: boost.boostType,
    boost_id: boost.id,
    display_stat_name: displayStat,
    higher_lower: choice,
    is_modified_projection: selection.option.payoutMultiplier !== 1,
    lobby_name: lobbyName,
    modifier_value: selection.option.payoutMultiplier,
    pick_display_choice: option.choiceDisplay,
    pick_location: pickLocation,
    pick_type: currentOU.liveEvent ? 'ingame' : 'pregame',
    player_id: player.id,
    player_name: langHelper.getPlayerFullName(player),
    projection_value: parseFloat(currentOU.statValue),
    sport_name: player.sportId,
    stat_name: stat,
  };
};

// formats selected rival lines for amplitude
export const formatSelectedRivalLine = ({
  lobbyName,
  selection,
  pickLocation,
}: NewSelection<SelectedRival>): PickemRivalsSelectionAddedProperties => {
  const {
    constructedPickEmRivalAppearance: { player },
    option,
  } = selection;

  const { appearanceStat } = option;

  return {
    adjustment_amount: parseFloat(option.spread),
    display_stat_name: appearanceStat.displayStat,
    lobby_name: lobbyName,
    pick_location: pickLocation,
    player_id: player.id,
    player_name: langHelper.getPlayerFullName(player),
    sport_name: player.sportId,
    stat_name: appearanceStat.stat,
  };
};

export const formatRegistrationSource = (
  registrationSource: RegistrationSource
): IdentifyProperties => {
  if (!registrationSource) return undefined;

  const registrationTypes = {
    Partner: 'referred_by_partner_id',
    User: 'referred_by_username',
    PromoCode: 'registration_promo_code',
  };

  const type = registrationTypes[registrationSource.type];
  return { [type]: registrationSource.value };
};

export const formatPickemEntrySlipStandard = (
  slip: Omit<PickEmEntrySlip, 'entrySlipId'> & Partial<PickEmEntrySlip>
): PickemEntryCreatedStandardProperties => {
  const { entryAmount, payoutOptions, selectedOptions, selectedOverUnders } = slip;
  const boost = getBoostFromSOU(selectedOverUnders);
  const payoutInfo = getPayoutInfo({ entryAmount, selectedOptions, payoutOptions });
  const sharedEventProperties = getSharedEventProperties(slip);
  const modifiedSelections = selectedOptions.filter(
    (option) => option.payoutMultiplier && option.payoutMultiplier > 1
  );

  return {
    boost_id: boost.id,
    boost_type: boost.boostType,
    contains_modifier: modifiedSelections.length > 0,
    number_of_modified_picks: modifiedSelections.length,
    payout_amount_standard: payoutInfo.payoutAmount,
    payout_multiplier_standard: payoutInfo.multiplier,
    ...sharedEventProperties,
  };
};

// formats submitted insured pick'em entry slips
export const formatPickemEntrySlipInsured = (
  slip: Omit<PickEmEntrySlip, 'entrySlipId'> & Partial<PickEmEntrySlip>
): PickemEntryCreatedInsuredProperties => {
  const { entryAmount, payoutOptions, selectedOptions } = slip;
  const sharedEventProperties = getSharedEventProperties(slip);
  const payoutInfo = getPayoutInfo({ entryAmount, selectedOptions, payoutOptions });
  const payoutWithLossInfo = getPayoutWithLossInfo({
    entryAmount,
    selectedOptions,
    payoutOptions,
  });
  const modifiedSelections = selectedOptions.filter(
    (option) => option.payoutMultiplier && option.payoutMultiplier > 1
  );

  return {
    contains_modifier: modifiedSelections.length > 0,
    number_of_modified_picks: modifiedSelections.length,
    payout_amount_insurance_max: payoutInfo.payoutAmount,
    payout_amount_insurance_with_loss: payoutWithLossInfo.payoutAmount,
    payout_multiplier_insurance_max: payoutInfo.multiplier,
    payout_multiplier_insurance_with_loss: payoutWithLossInfo.multiplier,
    ...sharedEventProperties,
  };
};

// formats outgoing shared entry slips
export const formatPickemEntryShareSubmission = ({
  entryAmount,
  entrySlipId,
  selectedOverUnders,
  selectedRivals,
}: {
  entryAmount: string;
  entrySlipId: string;
  selectedOverUnders: SelectedOverUnder[];
  selectedRivals: SelectedRival[];
}): PickemEntrySubmissionSharedProperties => {
  const playerDetails = getSLPlayerDetails({ selectedOverUnders, selectedRivals });
  const totalPicks = selectedOverUnders.length + selectedRivals.length;

  return {
    entry_amount: parseFloat(entryAmount),
    entry_id: entrySlipId,
    number_of_picks_total: totalPicks,
    player_ids: playerDetails.playerIds,
    player_names: playerDetails.playerNames,
    sport_names: playerDetails.sportNames,
  };
};

const ampliBoosts: { [key: string]: string } = {
  discount: 'discount',
  none: 'none',
  payoutBooster: 'payout booster',
  specialLine: 'special',
  sweepstakes: 'sweepstakes',
};

// retrieves boost from overunderline
export const getBoost = (oUL: OverUnderLine): AmpliBoost => {
  if (!oUL?.overUnder.boost) {
    return {
      id: null,
      boostType: 'none',
    };
  }

  const { boost } = oUL.overUnder;
  const ampliBoostType = ampliBoosts[boost.boostType];

  return {
    id: boost.id,
    boostType: ampliBoostType as AmpliBoost['boostType'],
  };
};

// retrieves boost from selected overunderlines
const getBoostFromSOU = (selectedOverUnders: SelectedOverUnder[]): AmpliBoost => {
  const boostedLine = selectedOverUnders
    .flatMap((sOU) => sOU.constructedOverUnderAppearance.overUnderLines)
    .find((sOU) => getBoost(sOU).id);

  return getBoost(boostedLine);
};

// gets the number of selected live lines on entry slip
const getInGameCount = ({ selectedOverUnders, selectedRivals }: SelectedLineTypes) => {
  const selected = [...selectedOverUnders, ...selectedRivals];

  const selectedLineOptions = selected.map((sLO) => {
    if ('constructedOverUnderAppearance' in sLO) {
      const { constructedOverUnderAppearance, overUnderLineId } = sLO;
      return constructedOverUnderAppearance.overUnderLines.find(
        (oUL) => oUL.id === overUnderLineId
      );
    }
    return sLO.constructedPickEmRivalLine;
  });

  return selectedLineOptions.filter((sO) => sO.liveEvent).length;
};

// retrieves details for all players on entry slip
export const getSLPlayerDetails = ({ selectedOverUnders, selectedRivals }: SelectedLineTypes) => {
  const selected = [...selectedOverUnders, ...selectedRivals];

  const selectedPlayers = selected.map((sLO) => {
    if ('constructedOverUnderAppearance' in sLO) {
      const { constructedOverUnderAppearance } = sLO;
      return constructedOverUnderAppearance.player;
    }
    return sLO.constructedPickEmRivalAppearance.player;
  });

  return selectedPlayers.reduce(
    (acc, player) => {
      acc.playerIds.push(player.id);
      acc.playerNames.push(langHelper.getPlayerFullName(player));
      acc.sportNames.push(player.sportId);

      return acc;
    },
    { playerIds: [], playerNames: [], sportNames: [] }
  );
};

const getPayoutInfo = ({
  entryAmount,
  payoutOptions,
  selectedOptions,
}: {
  entryAmount: string;
  payoutOptions: PayoutOption[];
  selectedOptions: SelectedOption[];
}) => {
  const multiplier = payoutOptions?.find((p) => p.selectionCount === selectedOptions.length)
    .multiplier;
  const payoutAmount = parseFloat(entryAmount) * parseFloat(multiplier);

  return {
    multiplier: parseFloat(multiplier),
    payoutAmount,
  };
};

// retrieves payout info for insured slips with one loss
const getPayoutWithLossInfo = ({
  entryAmount,
  payoutOptions,
  selectedOptions,
}: {
  entryAmount: string;
  payoutOptions: PayoutOption[];
  selectedOptions: SelectedOption[];
}) => {
  const withLossCount = selectedOptions.length - 1;
  const withLossMultiplier = payoutOptions?.find((p) => p.selectionCount === withLossCount)
    .multiplier;
  const withLossPayoutAmount = parseFloat(entryAmount) * parseFloat(withLossMultiplier);

  return {
    multiplier: parseFloat(withLossMultiplier),
    payoutAmount: withLossPayoutAmount,
  };
};

// properties shared between both creation events
const getSharedEventProperties = ({
  entryAmount,
  entrySlipId,
  selectedOptions,
  selectedOverUnders,
  selectedRivals,
  calculatedCoefficient,
  correlationBlockersById,
}: Omit<PickEmEntrySlip, 'entrySlipId'> & Partial<PickEmEntrySlip>): SharedEventProperties => {
  const higherCount = selectedOverUnders.filter((sOU) => sOU.option.choice === 'higher').length;
  const inGameCount = getInGameCount({ selectedOverUnders, selectedRivals });
  const lowerCount = selectedOverUnders.filter((sOU) => sOU.option.choice === 'lower').length;
  const playerDetails = getSLPlayerDetails({ selectedOverUnders, selectedRivals });

  return {
    entry_amount: parseFloat(entryAmount),
    ...(entrySlipId && { entry_id: entrySlipId }),
    number_of_picks_higher: higherCount,
    number_of_picks_in_game: inGameCount,
    number_of_picks_lower: lowerCount,
    number_of_picks_rivals: selectedRivals.length,
    number_of_picks_total: selectedOptions.length,
    player_ids: playerDetails.playerIds,
    player_names: playerDetails.playerNames,
    sport_names: playerDetails.sportNames,
    shifted_payout_multiplier: calculatedCoefficient,
    has_shifted_payout: calculatedCoefficient && calculatedCoefficient !== 1,
    number_of_correlated_selections: Object.keys(correlationBlockersById ?? {}).length,
  };
};

/* eslint-disable camelcase */
import { FlexPoolStyle, FlexPoolStyleResponse } from '@/interfaces/pick-em';

const flexPoolStyleAdapter = (flexPoolStyle: FlexPoolStyleResponse): FlexPoolStyle => {
  const { id, active, base_fee, fees, max_fee, prize_per_point, rake, title, total_fees } =
    flexPoolStyle;

  return {
    id,
    active,
    baseFee: base_fee,
    default: flexPoolStyle.default,
    fees,
    maxFee: max_fee,
    prizePerPoint: prize_per_point,
    rake,
    title,
    totalFees: total_fees,
  };
};

export default flexPoolStyleAdapter;

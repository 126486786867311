import React from 'react';
import dayjs from 'dayjs';
import advanced from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { CompletedSlate } from '@/interfaces/completed-slates';
import { ConstructedAppearance } from '@/interfaces/constructed-interfaces/constructed-appearance';
import {
  ConstructedEntrySlipPickEmOverUnderAppearance,
  ConstructedPickEmOverUnderLineAppearance,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import {
  ConstructedEntrySlipPickEmRivalAppearance,
  ConstructedPickEmRivalLineAppearance,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { Player, SlateInfo } from '@/interfaces/constructed-interfaces/constructed-slates';
import { Sport, Team, Teams } from '@/interfaces/drafting-config';
import { LiveSlate } from '@/interfaces/live';
import { LobbySlate } from '@/interfaces/lobbies';
import { Match } from '@/interfaces/matches';
import { SoloGame } from '@/interfaces/pick-em';
import { Slate } from '@/interfaces/slates';

import { numberToOrdinal } from './helpers';

export const LOW_SCORE_WINS_TEXT = `
  The player with the lower score will be graded as the winner. For example, a golfer
  with 72 strokes would be graded as the winner over a golfer with 74 strokes, a player with 2
  turnovers would be graded as the winner over a player with 5 turnovers, and so on.
`;

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

export default {
  getPlayerFullName: (player: Player): string => {
    if (!player) return '';
    return `${player.firstName ? `${player.firstName} ` : ''}${player.lastName || ''}`;
  },

  getPlayerInitialAndLast: (player: Player): string => {
    if (!player) return '';
    return `${player.firstName ? `${player.firstName?.substring(0, 1)}. ` : ''}${
      player.lastName || ''
    }`;
  },

  sentenceCase: (word: string): string => {
    if (!word) return null;
    return word[0].toUpperCase() + word.substring(1);
  },

  formatCash: (cash: string): string => {
    if (!cash) return null;
    const cents = (parseFloat(cash) * 100) % 100;
    if (cents !== 0) {
      return (
        parseFloat(cash)
          .toFixed(2)
          .toString()
          // There is a very specific case where a .99 decimal will round up to
          // he next integer value when using toFixed. We need to treat this case
          // as if it did not have a decimal value
          .replace('.00', '')
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      );
    }
    return parseFloat(cash)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },

  formatCashAbs: (cash: string): string => {
    if (!cash) return null;
    return Math.abs(parseFloat(cash))
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },

  formatNumber: (x: string | number): string => {
    if (!x && x !== 0) return null;
    // parseFloat to remove any decimal places on the string
    return parseFloat(x.toString())
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },

  formatNumberKM: (x: string | number): string => {
    if (!x && x !== 0) return 'null';
    const number = parseFloat(x.toString());
    if (number < 10000) {
      // 10,000
      return number.toString();
    }
    if (number < 999950) {
      // 100,000
      if (number % 1000 === 0) {
        return `${(number / 1000).toFixed(0)}k`;
      }
      return `${(number / 1000).toFixed(1)}k`;
    }
    if (number % 1000000 === 0) {
      return `${(number / 1000000).toFixed(0)}M`;
    }
    // parseFloat to trim off trailing zeros
    return `${parseFloat((number / 1000000).toFixed(2)).toString()}M`;
  },

  formatPoints: (x: string | number): string => {
    if (!x && x !== 0) return null;
    const number = parseFloat(x.toString()).toFixed(2);
    return number
      .toString()
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },

  formatListToString: (list: string[]): string =>
    list
      .map((item, index) => {
        const firstItem = index === 0;
        const lastItem = index === list.length - 1;

        if (list.length > 2 && !lastItem && !firstItem) return `, ${item}`;
        if (list.length > 2 && lastItem) return `, and ${item}`; // if it's the last item
        if (list.length === 2 && lastItem) return ` and ${item}`; // if there are only two items
        return item;
      })
      .join(''),

  genericMatchText: ({ teams, match }: { teams: Teams; match: Match }): string => {
    const homeTeam = match?.homeTeamId && teams ? teams[match.homeTeamId] : null;
    const awayTeam = match?.awayTeamId && teams ? teams[match.awayTeamId] : null;

    if (homeTeam && awayTeam) {
      return `${awayTeam.abbr} @ ${homeTeam.abbr}`;
    }
    return '';
  },

  genericMatchTextPickem: ({
    constructedAppearance,
    teams,
    soloGame,
  }: {
    constructedAppearance:
      | ConstructedAppearance
      | ConstructedPickEmRivalLineAppearance
      | ConstructedPickEmOverUnderLineAppearance
      | ConstructedEntrySlipPickEmOverUnderAppearance
      | ConstructedEntrySlipPickEmRivalAppearance;
    teams: Teams;
    soloGame: SoloGame;
  }) => {
    if (soloGame) {
      return `${soloGame.title} - `;
    }

    const homeTeam = teams[constructedAppearance?.match?.homeTeamId];
    const awayTeam = teams[constructedAppearance?.match?.awayTeamId];

    if (homeTeam.abbr === constructedAppearance.team?.abbr) {
      return `vs. ${awayTeam.abbr} - `;
    }
    if (awayTeam.abbr === constructedAppearance.team?.abbr) {
      return `@ ${homeTeam.abbr} - `;
    }
    return null;
  },

  matchText: ({
    match,
    gameType,
    player,
    soloGame,
    team,
    teams,
    teamSport,
  }: {
    gameType: string;
    match?: Match;
    player?: Player;
    team: Team;
    teams?: Teams;
    soloGame?: SoloGame;
    teamSport?: boolean;
  }): JSX.Element => {
    // pick em solo games
    if (soloGame && gameType === 'SoloGame') {
      return <strong>{soloGame.title}</strong>;
    }

    // draft solo games
    if (!soloGame && !teamSport && gameType === 'SoloGame' && player?.country) {
      return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{player.country}</>
      );
    }

    // pickem and draft team sports:
    if (!team) return null;
    const homeTeam = match?.homeTeamId && teams ? teams[match.homeTeamId] : null;
    const awayTeam = match?.awayTeamId && teams ? teams[match.awayTeamId] : null;

    if (homeTeam && awayTeam && homeTeam.id === team.id) {
      return (
        <>
          <strong>{homeTeam.abbr}</strong>
          {' vs '}
          {awayTeam.abbr}
        </>
      );
    }
    if (homeTeam && awayTeam && awayTeam.id === team.id) {
      return (
        <>
          <strong>{awayTeam.abbr}</strong>
          {' @ '}
          {homeTeam.abbr}
        </>
      );
    }

    if (homeTeam && homeTeam.id === team.id) {
      return (
        <>
          @ <strong>{homeTeam.abbr}</strong>
        </>
      );
    }
    if (awayTeam && awayTeam.id === team.id) {
      return (
        <>
          <strong>{awayTeam.abbr}</strong> @
        </>
      );
    }
    if (team.abbr) {
      return <strong>{team.abbr}</strong>;
    }

    return null;
  },

  matchResultText: ({
    teams,
    match,
    team,
    soloGame,
  }: {
    teams: Teams;
    match: Match;
    soloGame?: SoloGame;
    team: Team;
  }): JSX.Element => {
    if (soloGame) {
      return <strong>{soloGame.title}</strong>;
    }
    if (!team) return null;
    const homeTeam = match?.homeTeamId && teams ? teams[match.homeTeamId] : null;
    const awayTeam = match?.awayTeamId && teams ? teams[match.awayTeamId] : null;

    if (homeTeam && awayTeam && homeTeam.id === team.id) {
      return (
        <>
          {/* eslint-disable-next-line max-len */}
          <strong>
            {homeTeam.abbr} {match.homeTeamScore}
          </strong>{' '}
          vs {awayTeam.abbr} {match.awayTeamScore}
        </>
      );
    }
    if (homeTeam && awayTeam && awayTeam.id === team.id) {
      return (
        <>
          {/* eslint-disable-next-line max-len */}
          <strong>
            {awayTeam.abbr} {match.awayTeamScore}
          </strong>{' '}
          @ {homeTeam.abbr} {match.homeTeamScore}
        </>
      );
    }

    // in theory, this should never happen
    if (homeTeam && homeTeam.id === team.id) {
      return (
        <>
          @{' '}
          <strong>
            {homeTeam.abbr} {match.homeTeamScore}
          </strong>
        </>
      );
    }
    if (awayTeam && awayTeam.id === team.id) {
      return (
        <>
          <strong>
            {awayTeam.abbr} {match.awayTeamScore}
          </strong>{' '}
          @
        </>
      );
    }
    if (team.abbr) {
      return <strong>{team.abbr}</strong>;
    }

    return null;
  },

  matchTextWithScore: ({
    match,
    soloGame,
    team,
    teams,
    sport,
    isRivalLobby,
  }: {
    match?: Match;
    team: Team;
    teams: Teams;
    soloGame?: SoloGame;
    sport: Sport;
    isRivalLobby?: boolean;
  }): JSX.Element => {
    const currentPeriod = (period: number) => {
      const periodLabel = isRivalLobby ? sport.periodLabel.slice(0, 1) : sport.periodLabel;
      const matchOrSoloGame = match || soloGame;

      if (period === 0) {
        return `1st ${periodLabel}`;
      }

      if (matchOrSoloGame?.period > sport.periods) {
        return 'OT';
      }

      return `${numberToOrdinal(period)} ${periodLabel}`;
    };

    if (soloGame) {
      return (
        <>
          <strong>{soloGame.title}</strong> - {currentPeriod(soloGame.period)}
        </>
      );
    }

    // pickem and draft team sports:
    if (!team) return null;
    const homeTeam = match?.homeTeamId && teams ? teams[match.homeTeamId] : null;
    const awayTeam = match?.awayTeamId && teams ? teams[match.awayTeamId] : null;

    if (homeTeam && awayTeam && homeTeam.id === team.id) {
      return (
        <>
          <strong>
            {homeTeam.abbr} {match.homeTeamScore}
          </strong>{' '}
          - {awayTeam.abbr}&nbsp;
          {match.awayTeamScore} - {currentPeriod(match.period)}
        </>
      );
    }
    if (homeTeam && awayTeam && awayTeam.id === team.id) {
      return (
        <>
          <strong>
            {awayTeam.abbr} {match.awayTeamScore}
          </strong>{' '}
          - {homeTeam.abbr}&nbsp;
          {match.homeTeamScore} - {currentPeriod(match.period)}
        </>
      );
    }

    return null;
  },

  slateTitle: (slate: LobbySlate | Slate | CompletedSlate | SlateInfo | LiveSlate): string => {
    if (!slate) return null;

    if (slate.title) {
      return `${slate.sportId.toUpperCase()} ${slate.title}`;
    }
    // if it's same day don't show day
    if (dayjs().isSame(dayjs(slate.startAt), 'day')) {
      return `${slate.sportId.toUpperCase()} ${dayjs(slate.startAt).format('h:mmA z')}`;
    }
    // if it's more than a week out, show the date
    if (dayjs().add(6, 'day').isBefore(dayjs(slate.startAt))) {
      return `${slate.sportId.toUpperCase()} ${dayjs(slate.startAt).format('M/D h:mmA z')}`;
    }
    return `${slate.sportId.toUpperCase()} ${dayjs(slate.startAt).format('ddd h:mmA z')}`;
  },

  slateDescription: (slate: LobbySlate | Slate | CompletedSlate): string => {
    if (slate.description) {
      return slate.description;
    }
    if (slate?.gameCount) {
      return `${slate.gameCount} - ${slate.gameCount === 1 ? 'game' : 'games'}`;
    }
    return '';
  },

  sumFloats: (num1: string | number, num2: string | number) =>
    parseFloat((parseFloat(num1.toString()) + parseFloat(num2.toString())).toFixed(2)),

  teamOrCountryName: ({
    constructedAppearance,
    sport,
  }: {
    constructedAppearance: ConstructedAppearance;
    sport: Sport;
  }) => {
    if (sport?.teamSport && constructedAppearance.team?.abbr) {
      return constructedAppearance.team.abbr;
    }
    if (!sport?.teamSport && constructedAppearance.player.country) {
      return constructedAppearance.player.country;
    }
    return null;
  },

  timeToDate: (time: string): string => {
    if (!time) {
      return null;
    }
    return dayjs(time).format('M/DD/YY h:mmA');
  },

  timeToDayOrDate: (time: string): string => {
    if (!time) {
      return null;
    }
    // if greater than 7 days, show date
    if (dayjs().add(6, 'day').isBefore(dayjs(time), 'day')) {
      return dayjs(time).format('M/D h:mmA z');
    }
    // if same day, show time
    if (dayjs().isSame(dayjs(time), 'day')) {
      return dayjs(time).format('h:mmA z');
    }
    // if in the past, but but not same day, show date
    if (dayjs(time).isBefore(dayjs(), 'day')) {
      return dayjs(time).format('M/DD/YY h:mmA z');
    }
    // if within the week, show day
    return dayjs(time).format('ddd h:mmA z');
  },
};

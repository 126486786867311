import { datadogRum } from '@datadog/browser-rum';

const errorLogger = (
  logError: boolean,
  error: string,
  context?: {
    [key: string]: string;
  }
): void => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  if (logError) {
    // handle errors that aren't tracked automatically by the RUM SDK
    // these will only record, if datadogRUM was initialized
    datadogRum.addError(new Error(error), context);
  }
};

export default errorLogger;

import { AxiosResponse } from 'axios';

import request from '@request';
import { ActiveDraftsResponse } from '@/interfaces/active-drafts';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const getActiveDrafts = async (): Promise<AxiosResponse<ActiveDraftsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: '/user/active_drafts',
      underdogAPIVersion: 'v3',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';

import request from '@request';
import {
  CorrelationsResponse,
  EntrySlipRequest,
  EntrySlipsResponse,
  FavoritePlayersResponse,
  FeaturedLobbyResponse,
  FeaturedOverUnderLinesResponse,
  FlexPoolStylesResponse,
  OverUnderLinesResponse,
  PayoutOutcomeOption,
  PayoutOutcomeResponse,
  PickEmEntrySlipLimitsResponse,
  PickEmPoolStylesResponse,
  RivalLinesResponse,
  SharedEntrySlipResponse,
  ShareLinkResponse,
  UserPickEmPoolsLeaderboardEntrySlipResponse,
} from '@/interfaces/pick-em';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const submitEntrySlip = async ({
  entrySlip,
  confirmed,
}: {
  entrySlip: EntrySlipRequest;
  confirmed?: boolean;
}): Promise<any> => {
  const isFlexPool = entrySlip.pickEmPoolStyleId !== null;

  const flexPoolEntrySlip = {
    fee: entrySlip.fee,
    options: entrySlip.options,
    payout_option_id: entrySlip.payoutOptionId,
    pickem_pool_style_id: entrySlip.pickEmPoolStyleId,
  };

  // for classic pickem
  const defaultEntrySlip = {
    correlation_ids: entrySlip.correlationIds,
    fee: entrySlip.fee,
    options: entrySlip.options,
    payout_option_id: entrySlip.payoutOptionId,
    total_multiplier: entrySlip.multiplier,
  };

  const entry_slip = isFlexPool ? flexPoolEntrySlip : defaultEntrySlip;
  const response = await request({
    method: 'POST',
    url: '/entry_slips',
    underdogAPIVersion: 'v4',
    requiresGeoComply: true,
    data: {
      entry_slip,
    },
    params: {
      ...(confirmed ? { confirmed: true } : {}),
    },
  });

  return response;
};

export const getOverUnderLines = async (): Promise<AxiosResponse<OverUnderLinesResponse>> => {
  // eslint-disable-next-line max-len
  const response = await request({
    method: 'GET',
    underdogAPIVersion: 'beta/v5',
    url: '/over_under_lines',
  });

  return response;
};

export const getLiveOverUnderLines = async (): Promise<AxiosResponse<OverUnderLinesResponse>> => {
  // eslint-disable-next-line max-len
  const response = await request({
    method: 'GET',
    underdogAPIVersion: 'beta/v2',
    url: '/live_over_under_lines',
  });

  return response;
};

/*
 * Get featured lobby
 *
 * Returns the all content displayed under the feature tab
 */
export const getFeaturedLobby = async (): Promise<AxiosResponse<FeaturedLobbyResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: '/pickem_lobby',
    });
    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getActiveEntrySlips = async ({
  page,
}: {
  page?: number;
}): Promise<AxiosResponse<EntrySlipsResponse>> => {
  const response = await request({
    method: 'GET',
    underdogAPIVersion: 'v5',
    url: '/user/active_entry_slips',
    params: {
      page,
    },
  });

  return response;
};

export const getSettledEntrySlips = async ({
  page,
}: {
  page?: number;
}): Promise<AxiosResponse<EntrySlipsResponse>> => {
  const response = await request({
    method: 'GET',
    underdogAPIVersion: 'v5',
    url: '/user/settled_entry_slips',
    params: {
      page,
    },
  });

  return response;
};

export const getEntrySlipLimits = async (): Promise<
  AxiosResponse<PickEmEntrySlipLimitsResponse>
> => {
  const response = await request({
    method: 'GET',
    url: '/user/entry_slip_limits',
    requiresLatLong: true,
  });

  return response;
};

export const getFavoritePickEmPlayers = async (): Promise<
  AxiosResponse<FavoritePlayersResponse>
> => {
  const response = await request({
    method: 'GET',
    url: '/user/favorite_players',
  });

  return response;
};

export const addFavoritePickEmPlayer = async (playerId: string): Promise<AxiosResponse> => {
  const data = {
    favorite_player: {
      player_id: playerId,
    },
  };
  const response = await request({
    method: 'POST',
    url: '/user/favorite_players',
    data,
  });

  return response;
};

export const removeFavoritePickEmPlayer = async (playerId: string): Promise<AxiosResponse> => {
  const response = await request({
    method: 'DELETE',
    url: `/user/favorite_players/${playerId}`,
  });

  return response;
};

export const getRivalLines = async (): Promise<AxiosResponse<RivalLinesResponse>> => {
  const response = await request({
    method: 'GET',
    underdogAPIVersion: 'beta/v3',
    url: '/rival_lines',
  });

  return response;
};

export const createSlipShareLink = async ({
  entrySlipId,
}: {
  entrySlipId: string;
}): Promise<AxiosResponse<ShareLinkResponse>> => {
  try {
    const response = await request({
      method: 'POST',
      url: `/entry_slips/${entrySlipId}/share_link`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getSharedSlip = async ({
  entrySlipShareId,
}: {
  entrySlipShareId: string;
}): Promise<AxiosResponse<SharedEntrySlipResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIVersion: 'v4',
      url: `/entry_slips/${entrySlipShareId}/share`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getEntrySlipForImage = async ({
  entrySlipShareId,
}: {
  entrySlipShareId: string;
}): Promise<AxiosResponse<SharedEntrySlipResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIVersion: 'v3',
      url: `/entry_slips/${entrySlipShareId}/image`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getFeaturedOverUnderLines = async (): Promise<
  AxiosResponse<FeaturedOverUnderLinesResponse>
> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIVersion: 'v3',
      url: '/user/featured_over_under_lines',
      requiresLatLong: true,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get correlation info
 *
 * Returns the correlation relationships for pick'em
 */
export const getCorrelations = async (): Promise<AxiosResponse<CorrelationsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      underdogAPIVersion: 'v2',
      url: '/correlations',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get payout outcome
 *
 * Returns the total payout multiplier values, fee limits, and validity of a given list of picks
 */
export const getSlipPayoutOutcome = async ({
  options,
}: {
  options: PayoutOutcomeOption[];
}): Promise<AxiosResponse<PayoutOutcomeResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: '/entry_slips/payout_outcome',
      params: {
        options,
      },
      paramsSerializer: {
        encode: (params) => params.replace(/(\[[0-9]])/g, '[]'),
      },
    });
    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

/*
 * Get payout styles
 *
 * Payout styles are for pick 'ems, to see what the max entry fee and multiplier is
 */
export const getPayoutStyles = async (): Promise<any> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: '/payout_styles',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getPickEmPoolStyles = async (): Promise<AxiosResponse<PickEmPoolStylesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIVersion: 'v2',
      underdogAPIEndpoint: 'stats',
      url: '/pickem_pool_styles',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getFlexPoolStyles = async (): Promise<AxiosResponse<FlexPoolStylesResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIVersion: 'v3',
      underdogAPIEndpoint: 'stats',
      url: '/pickem_pool_styles',
    });
    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getPickEmPoolLeaderboard = async ({
  page,
  pickEmPoolId,
}: {
  page?: number;
  pickEmPoolId: string;
}): Promise<AxiosResponse<EntrySlipsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/pickem_pools/${pickEmPoolId}/leaderboard`,
      params: {
        page,
      },
    });

    return {
      ...response,
      data: {
        data: response.data.pickem_pool_leaderboard,
        meta: response.data.meta,
      },
    };
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getUserPickEmPoolsLeaderboardEntrySlip = async (
  entrySlipId: string
): Promise<AxiosResponse<UserPickEmPoolsLeaderboardEntrySlipResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/user/pickem_pools/entry_slips/${entrySlipId}`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

import {
  draftCanadaNote as draftCanadaNoteFallback,
  draftStates as draftStatesFallback,
  pickEmCanadaNote as pickEmCanadaNoteFallback,
  pickEmStates as pickEmStatesFallback,
} from '@/components/pages/landing/landing-page-data';
import { LandingPageConfigs, LandingPageConfigsResponse } from '@/interfaces/landing-page-configs';

const landingPageConfigsAdapter = (data: LandingPageConfigsResponse): LandingPageConfigs => {
  if (data?.landingPageStateConfigs?.items?.length > 0) {
    const stateConfig = data?.landingPageStateConfigs?.items[0];
    const { draftStates, draftCanadaNote, pickEmStates, pickEmCanadaNote } =
      stateConfig?.fields?.stateConfigs ?? {};
    return {
      id: stateConfig?.sys?.id,
      landingPageStateConfigs: {
        draftStates,
        draftCanadaNote,
        pickEmStates,
        pickEmCanadaNote,
      },
    };
  }

  // Return fallback values in the case that the Contentful API call errors out
  return {
    id: 'landing_page_fallback',
    landingPageStateConfigs: {
      draftStates: draftStatesFallback,
      draftCanadaNote: draftCanadaNoteFallback,
      pickEmStates: pickEmStatesFallback,
      pickEmCanadaNote: pickEmCanadaNoteFallback,
    },
  };
};

export default landingPageConfigsAdapter;

/* eslint-disable max-classes-per-file */
import { ApiErrorDataResponse, AppErrorResponse } from '@/interfaces/error';

// TODO [FAN-2198]: work out the type for this
export const apiErrorTracking = (error: any): { message: string } => {
  // add error tracking in here
  const message: string = error.message || error.detail;
  // eslint-disable-next-line no-console
  console.warn('Error:', message);
  return {
    message,
  };
};

export class APIError extends Error {
  title?: string;

  message: string;

  status?: number;

  apiCode?: string | number;

  ctaText?: string;

  ctaUrl?: string;

  constructor(apiErrorData: ApiErrorDataResponse) {
    super();
    this.title = apiErrorData.title;
    this.message = apiErrorData.detail;
    this.apiCode = apiErrorData.api_code;
    this.ctaText = apiErrorData.cta_text;
    this.ctaUrl = apiErrorData.cta_url;
    this.status = apiErrorData.http_status_code || apiErrorData.status; // fix this somehow
  }
}

// TODO [FAN-2616]: this error handling might be a little convoluted and
// I might lose error data by doing too much massaging
export class AppError extends Error {
  title?: string;

  message: string;

  status?: number;

  apiCode?: string | number;

  ctaText?: string;

  ctaUrl?: string;

  constructor(apiErrorData: AppErrorResponse) {
    super();
    this.title = apiErrorData.title;
    this.message = apiErrorData.message;
    this.apiCode = apiErrorData.apiCode;
    this.ctaText = apiErrorData.ctaText;
    this.ctaUrl = apiErrorData.ctaUrl;
    this.status = apiErrorData.status;
  }
}

/* eslint-disable camelcase */
import appearanceAdapter from '@/store/modules/constructed-slates/adapters/appearance';
import playerAdapter from '@/store/modules/constructed-slates/adapters/player';
import matchAdapter from '@/store/modules/matches/adapters/match';

import { FeaturedLobby, FeaturedLobbyResponse, PlayerGroupValue } from '@/interfaces/pick-em';

import overUnderLineAdapter from './over-under-lines';
import pickEmPackAdapter from './pack';
import soloGameAdapter from './solo-game';

const featuredLobbyAdapter = (featuredLobby: FeaturedLobbyResponse): FeaturedLobby => {
  const {
    appearances,
    games,
    over_under_lines,
    pickem_packs,
    player_groups,
    players,
    sections,
    solo_games,
  } = featuredLobby;

  const adaptedSections = sections.map((section) => ({
    title: section.title,
    contentType: section.content_type,
    key: section.key,
  }));

  const playerGroupsKeys = Object.keys(player_groups);
  const adaptedPlayerGroups: { [group: string]: PlayerGroupValue[] } = {};
  playerGroupsKeys.forEach((group) => {
    adaptedPlayerGroups[group] = player_groups[group].map((prop) => ({
      id: prop.id,
      appearanceIds: prop.appearance_ids,
    }));
  });

  const adaptedAppearances = appearances.map(appearanceAdapter);
  const adaptedGames = games.map(matchAdapter);
  const adaptedOverUnderLines = over_under_lines.map(overUnderLineAdapter);
  const adaptedPickemPacks = pickem_packs.map(pickEmPackAdapter);
  const adaptedPlayers = players.map(playerAdapter);
  const adaptedSoloGames = solo_games.map(soloGameAdapter);

  return {
    appearances: adaptedAppearances,
    games: adaptedGames,
    overUnderLines: adaptedOverUnderLines,
    pickemPacks: adaptedPickemPacks,
    playerGroups: adaptedPlayerGroups,
    players: adaptedPlayers,
    sections: adaptedSections,
    soloGames: adaptedSoloGames,
  };
};

export default featuredLobbyAdapter;

import { v4 as uuidv4 } from 'uuid';

import { ampli } from '@/ampli';
import { RegistrationSource } from '@/interfaces/user';

import { AMPLITUDE_DEVICE_ID } from '../constants';

import { formatRegistrationSource } from './helpers';

const generateDeviceId = () => {
  const amplitudeDeviceId = uuidv4();
  sessionStorage.setItem(AMPLITUDE_DEVICE_ID, amplitudeDeviceId);

  return amplitudeDeviceId;
};

const getAmplitudeDeviceId = () => {
  const amplitudeDeviceId = sessionStorage.getItem(AMPLITUDE_DEVICE_ID);

  return amplitudeDeviceId || generateDeviceId();
};

const environment = process.env.APP_ENV === 'production' ? 'production' : 'development';

export const loadAmplitude = () => {
  ampli.load({
    client: {
      configuration: {
        defaultTracking: {
          sessions: true,
        },
      },
    },
    environment,
  });
};

export const identifyAmplitudeUser = ({
  userId,
  registrationSource,
}: {
  userId: string;
  registrationSource: RegistrationSource;
}) => {
  const formattedRegistrationSource = formatRegistrationSource(registrationSource);

  ampli.identify(userId, formattedRegistrationSource, {
    device_id: getAmplitudeDeviceId(),
    user_id: userId,
  });
};

export const batchAmplitudeEvents = () => {
  ampli.client.flush();
};

export default ampli;

import * as contentful from 'contentful';

const accessToken =
  process.env.NODE_ENV === 'production'
    ? process.env.CONTENTFUL_ACCESS_TOKEN
    : process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN;
// why not just use the correct access token value in .env files?
// so that it's really clear that one is fetching the preview values

const host =
  process.env.NODE_ENV === 'production' ? 'cdn.contentful.com' : 'preview.contentful.com';

const client = contentful.createClient({
  space: process.env.CONTENTFUL_SPACE_ID,
  accessToken,
  host,
});

export default client;

/* eslint-disable camelcase */

import { Sport, SportResponse, SportStatus } from '@/interfaces/drafting-config';
import { toCamel } from '@/utilities/helpers';

const sportAdapter = (sport: SportResponse): Sport => {
  const {
    id,
    color,
    game_type,
    manual,
    name,
    over_unders_enabled,
    periods,
    period_label,
    periods_label,
    rank,
    rivals_enabled,
    status: statusResponse, // to be deprecated
    draft_status,
    pickem_status,
    news_status,
    team_sport,
  } = sport;

  const status = toCamel(statusResponse) as
    | SportStatus.ACTIVE
    | SportStatus.DISABLED
    | SportStatus.PICK_EM;

  return {
    id,
    color,
    gameType: game_type,
    manual,
    name,
    overUndersEnabled: over_unders_enabled,
    periods,
    periodLabel: period_label,
    periodsLabel: periods_label,
    rank,
    rivalsEnabled: rivals_enabled,
    status,
    draftStatus: draft_status,
    pickemStatus: pickem_status,
    newsStatus: news_status,
    teamSport: team_sport,
  };
};

export default sportAdapter;

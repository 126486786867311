import React from 'react';
import clsx from 'clsx';

import Icon from '@/components/atoms/icon';

import styles from './styles.scss';

export interface SupportLinkProps {
  className?: string;
  label?: string | JSX.Element;
  queryParams?: string;
  showArrow?: boolean;
}

const SupportLink = (props: SupportLinkProps) => {
  const { className, queryParams, label = 'support@underdogfantasy.com', showArrow } = props;

  const href =
    process.env.NODE_ENV === 'production'
      ? `https://underdogfantasy.zendesk.com/hc/en-us/requests/new${queryParams || ''}`
      : `https://testud.zendesk.com/hc/en-us/requests/new${queryParams || ''}`;

  return (
    <>
      <a
        className={clsx(styles.link, className)}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
      >
        {label}
      </a>
      {showArrow && <Icon name="arrowOutward" className={styles.arrowIcon} />}
    </>
  );
};

export default SupportLink;

import {
  PickEmConfigurationResponse,
  PickEmConfigurationsResponse,
} from '@/interfaces/configurations';
import {
  SelectedOverUnder,
  SelectedSharedOverUnder,
} from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { SelectedRival } from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { DraftingConfig, StateConfig } from '@/interfaces/drafting-config';
import { FeatureFlags } from '@/interfaces/feature-flags';
import {
  MatchUpdateResponse,
  SoloGameUpdateResponse,
  StatLineUpdateResponse,
} from '@/interfaces/live';
import {
  CorrelationsResponse,
  EntrySlipsResponse,
  FavoritePlayer,
  FavoritePlayersResponse,
  FeaturedLobbyResponse,
  FeaturedOverUnderLinesResponse,
  FlexPoolStylesResponse,
  OverUnderLines,
  OverUnderLinesResponse,
  OverUnderLineSwapResponse,
  PayoutOutcomeOption,
  PayoutOutcomeResponse,
  PayoutStylesResponse,
  PickEmEntrySlipLimitResponse,
  PickEmPoolStylesResponse,
  PickEmSlipType,
  PickLocation,
  RivalLine,
  RivalLinesResponse,
} from '@/interfaces/pick-em';
import { SportId } from '@/interfaces/types';
import { Role } from '@/interfaces/user';

export const GET_PICK_EM = 'pickem/GET_PICK_EM' as const;

export const GET_PAYOUT_OUTCOME = 'pickem/GET_PAYOUT_OUTCOME' as const;
export const SET_PAYOUT_OUTCOME = 'pickem/SET_PAYOUT_OUTCOME' as const;

export const SET_ENTRY_SLIP_LIMIT = 'pickem/SET_ENTRY_SLIP_LIMIT' as const;

export const SET_PICK_EM_OVER_UNDER_LINES = 'pickem/SET_PICK_EM_OVER_UNDER_LINES' as const;

export const SET_PICK_EM_RIVAL_LINES = 'pickem/SET_PICK_EM_RIVAL_LINES' as const;

export const REMOVE_PICK_EM_OVER_UNDER_LINE = 'pickem/REMOVE_PICK_EM_OVER_UNDER_LINE' as const;

export const REMOVE_PICK_EM_RIVAL_LINE = 'pickem/REMOVE_PICK_EM_RIVAL_LINE' as const;

export const GET_LIVE_PICK_EM_ENTRIES = 'pickem/GET_LIVE_PICK_EM_ENTRIES' as const;
export const SET_LIVE_PICK_EM_ENTRIES = 'pickem/SET_LIVE_PICK_EM_ENTRIES' as const;

export const GET_SETTLED_PICK_EM_ENTRIES = 'pickem/GET_SETTLED_PICK_EM_ENTRIES' as const;
export const SET_SETTLED_PICK_EM_ENTRIES = 'pickem/SET_SETTLED_PICK_EM_ENTRIES' as const;
export const RESET_SETTLED_PICK_EM_ENTRIES = 'pickem/RESET_SETTLED_PICK_EM_ENTRIES' as const;

export const GET_PICK_EM_CONFIG = 'pickem/GET_PICK_EM_CONFIG' as const;
export const SET_PICK_EM_CONFIG = 'pickem/SET_PICK_EM_CONFIG' as const;

export const GET_PICK_EM_POOL_STYLES = 'pickem/GET_PICK_EM_POOL_STYLES' as const;
export const SET_PICK_EM_POOL_STYLES = 'pickem/SET_PICK_EM_POOL_STYLES' as const;

export const GET_FLEX_POOL_STYLES = 'pickem/GET_FLEX_POOL_STYLES' as const;
export const SET_FLEX_POOL_STYLES = 'pickem/SET_FLEX_POOL_STYLES' as const;

export const GET_PICK_EM_POOL_LEADERBOARD = 'pickem/GET_PICK_EM_POOL_LEADERBOARD' as const;
export const SET_PICK_EM_POOL_LEADERBOARD = 'pickem/SET_PICK_EM_POOL_LEADERBOARD' as const;
export const RESET_PICK_EM_POOL_LEADERBOARD = 'pickem/RESET_PICK_EM_POOL_LEADERBOARD' as const;

export const GET_PICK_EM_ENTRIES = 'pickem/GET_PICK_EM_ENTRIES' as const;
export const SET_PICK_EM_ENTRIES = 'pickem/SET_PICK_EM_ENTRIES' as const;

// Favorites
export const GET_FAVORITE_PICK_EM_PLAYERS = 'pickem/GET_FAVORITE_PICK_EM_PLAYERS' as const;
export const SET_FAVORITE_PICK_EM_PLAYERS = 'pickem/SET_FAVORITE_PICK_EM_PLAYERS' as const;

export const ADD_FAVORITE_PICK_EM_PLAYER = 'pickem/ADD_FAVORITE_PICK_EM_PLAYER' as const;
export const REMOVE_FAVORITE_PICK_EM_PLAYER = 'pickem/REMOVE_FAVORITE_PICK_EM_PLAYER' as const;

// Live updates
export const UPDATE_LIVE_PICK_EM_STAT_LINE = 'pickem/UPDATE_LIVE_PICK_EM_STAT_LINE' as const;
export const UPDATE_LIVE_PICK_EM_GAME = 'pickem/UPDATE_LIVE_PICK_EM_GAME' as const;
export const UPDATE_LIVE_PICK_EM_SOLO_GAME = 'pickem/UPDATE_LIVE_PICK_EM_SOLO_GAME' as const;

// Updates
export const ACCEPT_OVER_UNDER_LINE_SWAP = 'pickem/ACCEPT_OVER_UNDER_LINE_SWAP' as const;
// these are used twice, for the lobby and for selected picks
export const UPDATE_PICK_EM_CONFIGURATION = 'pickem/UPDATE_PICK_EM_CONFIGURATION' as const;
export const UPDATE_PICK_EM_OVER_UNDER_LINE_STATUS =
  'pickem/UPDATE_PICK_EM_OVER_UNDER_LINE_STATUS' as const;
export const SWAP_PICK_EM_OVER_UNDER_LINE = 'pickem/SWAP_PICK_EM_OVER_UNDER_LINE' as const;
export const SWAP_FINISHED_PICK_EM_OVER_UNDER_LINE =
  'pickem/SWAP_FINISHED_PICK_EM_OVER_UNDER_LINE' as const;
export const UPDATE_PICK_EM_LIVE_EVENT_STAT = 'pickem/UPDATE_PICK_EM_LIVE_EVENT_STAT' as const;

// Making selections
export const SET_INITIAL_PICK_EM_SELECTIONS = 'pickem/SET_INITIAL_SELECTIONS' as const;
export const UPDATE_SELECTED_OVER_UNDERS = 'pickem/UPDATE_SELECTED_OVER_UNDERS' as const;
export const UPDATE_SELECTED_RIVALS = 'pickem/UPDATE_SELECTED_RIVALS' as const;
export const ACCEPT_SELECTED_OVER_UNDER_LINE_SWAP =
  'pickem/ACCEPT_SELECTED_OVER_UNDER_LINE_SWAP' as const;
export const REMOVE_SELECTED_ERROR = 'pickem/REMOVE_ERROR' as const;
export const CLEAR_PICK_EM_ENTRY_SLIP = 'pickem/CLEAR_PICK_EM_ENTRY_SLIP' as const;
export const UPDATE_PICKS_FROM_SHARE_SLIP = 'pickem/UPDATE_PICKS_FROM_SHARE_SLIP' as const;

export const TOGGLE_PICK_EM_INSURANCE = 'pickem/TOGGLE_PICK_EM_INSURANCE' as const;

// Actions
export const getPickEm = (payload: {
  draftingConfig: DraftingConfig;
  pickEmStateConfig: StateConfig['pickEm'];
  roles: Role[];
}) => ({ type: GET_PICK_EM, payload });

export const getPickEmConfig = () => ({ type: GET_PICK_EM_CONFIG });

export const setPickEmConfig = (payload: {
  payoutStylesResponse: PayoutStylesResponse;
  correlationsResponse: CorrelationsResponse;
  pickEmConfigurationsResponse: PickEmConfigurationsResponse;
}) => ({ type: SET_PICK_EM_CONFIG, payload });

export const getSlipPayoutOutcome = (payload: {
  options: PayoutOutcomeOption[];
  entryRoles: string[];
}) => ({
  type: GET_PAYOUT_OUTCOME,
  payload,
});

export const setSlipPayoutOutcome = (payload: {
  data: PayoutOutcomeResponse;
  entryRoles: string[];
}) => ({
  type: SET_PAYOUT_OUTCOME,
  payload,
});

export const setEntrySlipLimits = (payload: { limits: PickEmEntrySlipLimitResponse[] }) => ({
  type: SET_ENTRY_SLIP_LIMIT,
  payload,
});

export const setPickEmOverUnderLines = (payload: {
  preGameOverUnderLines: OverUnderLinesResponse;
  liveOverUnderLines: OverUnderLinesResponse;
  favoritePlayers: FavoritePlayersResponse['favorite_players'];
  draftingConfig: DraftingConfig;
  featuredLobby: FeaturedLobbyResponse;
  featuredOverUnders: FeaturedOverUnderLinesResponse;
  pickEmStateConfig: StateConfig['pickEm'];
  roles: Role[];
}) => ({ type: SET_PICK_EM_OVER_UNDER_LINES, payload });

export const setPickEmRivalLines = (payload: {
  data: RivalLinesResponse;
  favoritePlayers: FavoritePlayersResponse['favorite_players'];
  draftingConfig: DraftingConfig;
  pickEmStateConfig: StateConfig['pickEm'];
  roles: Role[];
}) => ({ type: SET_PICK_EM_RIVAL_LINES, payload });

export const removePickEmOverUnderLine = (payload: { data: string[] }) => ({
  type: REMOVE_PICK_EM_OVER_UNDER_LINE,
  payload,
});

export const removePickEmRivalLine = (payload: { data: string[] }) => ({
  type: REMOVE_PICK_EM_RIVAL_LINE,
  payload,
});

export const getLivePickEmEntries = (payload: { page?: number }) => ({
  type: GET_LIVE_PICK_EM_ENTRIES,
  payload,
});

export const setLivePickEmEntries = (payload: {
  data: EntrySlipsResponse;
  pageNumber?: number;
}) => ({
  type: SET_LIVE_PICK_EM_ENTRIES,
  payload,
});

export const getPickEmPoolLeaderboard = (payload: { page?: number; pickEmPoolId: string }) => ({
  type: GET_PICK_EM_POOL_LEADERBOARD,
  payload,
});

export const resetPickEmPoolLeaderboard = () => ({ type: RESET_PICK_EM_POOL_LEADERBOARD });

export const setPickEmPoolLeaderboard = (payload: { data: EntrySlipsResponse }) => ({
  type: SET_PICK_EM_POOL_LEADERBOARD,
  payload,
});

export const getSettledPickEmEntries = (payload: { page?: number }) => ({
  type: GET_SETTLED_PICK_EM_ENTRIES,
  payload,
});

export const setSettledPickEmEntries = (payload: {
  data: EntrySlipsResponse;
  pageNumber?: number;
}) => ({
  type: SET_SETTLED_PICK_EM_ENTRIES,
  payload,
});

export const resetSettledPickEmEntries = () => ({ type: RESET_SETTLED_PICK_EM_ENTRIES });

export const updatePickEmConfiguration = (payload: {
  newConfiguration: PickEmConfigurationResponse;
}) => ({ type: UPDATE_PICK_EM_CONFIGURATION, payload });

export const updatePickEmLiveEventStat = (payload: {
  data: StatLineUpdateResponse;
  oULAppearanceStatId: string;
}) => ({ type: UPDATE_PICK_EM_LIVE_EVENT_STAT, payload });

export const acceptOverUnderLineSwap = (payload: { overUnderLineIds: string[] }) => ({
  type: ACCEPT_OVER_UNDER_LINE_SWAP,
  payload,
});

// Favorites

export const getFavoritePickEmPlayers = () => ({ type: GET_FAVORITE_PICK_EM_PLAYERS });

export const setFavoritePickEmPlayers = (payload: { data: FavoritePlayersResponse }) => ({
  type: SET_FAVORITE_PICK_EM_PLAYERS,
  payload,
});

export const addFavoritePickEmPlayer = (payload: { player: FavoritePlayer }) => ({
  type: ADD_FAVORITE_PICK_EM_PLAYER,
  payload,
});

export const removeFavoritePickEmPlayer = (payload: { playerId: string }) => ({
  type: REMOVE_FAVORITE_PICK_EM_PLAYER,
  payload,
});

// Live updates

export const swapPickEmOverUnderLine = (payload: { data: OverUnderLineSwapResponse[] }) => ({
  type: SWAP_PICK_EM_OVER_UNDER_LINE,
  payload,
});

export const swapFinishedPickEmOverUnderLine = (payload: { overUnderLineIds: string[] }) => ({
  type: SWAP_FINISHED_PICK_EM_OVER_UNDER_LINE,
  payload,
});

export const updateLivePickEmStatLine = (payload: { data: StatLineUpdateResponse }) => ({
  type: UPDATE_LIVE_PICK_EM_STAT_LINE,
  payload,
});

export const updateLivePickEmGame = (payload: { data: MatchUpdateResponse }) => ({
  type: UPDATE_LIVE_PICK_EM_GAME,
  payload,
});

export const updateLivePickEmSoloGame = (payload: { data: SoloGameUpdateResponse }) => ({
  type: UPDATE_LIVE_PICK_EM_SOLO_GAME,
  payload,
});

export const updatePickEmOverUnderLineStatus = (payload: {
  statusType: 'suspended' | 'unsuspended';
  overUnderLineIds: string[];
}) => ({ type: UPDATE_PICK_EM_OVER_UNDER_LINE_STATUS, payload });

// Making selections
export const setInitialPickEmSelections = (payload: {
  rivalLines: RivalLine[];
  overUnderLines: OverUnderLines;
}) => ({ type: SET_INITIAL_PICK_EM_SELECTIONS, payload });

export interface UpdateSelectedArgs<T extends SelectedOverUnder | SelectedRival> {
  action: 'remove' | 'add';
  selection: T;
  isPickEmFeatureEnabled: FeatureFlags['pickEm'];
  lobbyName?: SportId;
  pickLocation: PickLocation;
  minInsuredPicks: number;
  maxUserPicks: number;
}

export const updateSelectedOverUnders = (payload: UpdateSelectedArgs<SelectedOverUnder>) => ({
  type: UPDATE_SELECTED_OVER_UNDERS,
  payload,
});

export const updateSelectedRivals = (payload: UpdateSelectedArgs<SelectedRival>) => ({
  type: UPDATE_SELECTED_RIVALS,
  payload,
});

export const acceptSelectedOverUnderLineSwap = (payload: { optionIds: string[] }) => ({
  type: ACCEPT_SELECTED_OVER_UNDER_LINE_SWAP,
  payload,
});

export const removeSelectedPickEmError = () => ({ type: REMOVE_SELECTED_ERROR });

export const updateSelectedPicksFromSlip = (payload: {
  selectedOverUnders: SelectedSharedOverUnder[];
  selectedRivals: SelectedRival[];
}) => ({ type: UPDATE_PICKS_FROM_SHARE_SLIP, payload });

export const clearPickEmEntrySlip = () => ({ type: CLEAR_PICK_EM_ENTRY_SLIP });

export const togglePickEmInsurance = (payload: {
  slipType: PickEmSlipType;
  minInsuredPicks: number;
}) => ({ type: TOGGLE_PICK_EM_INSURANCE, payload });

export const getPickEmPoolStyles = () => ({ type: GET_PICK_EM_POOL_STYLES });

export const setPickEmPoolStyles = (payload: { data: PickEmPoolStylesResponse }) => ({
  type: SET_PICK_EM_POOL_STYLES,
  payload,
});

export const getFlexPoolStyles = () => ({ type: GET_FLEX_POOL_STYLES });

export const setFlexPoolStyles = (payload: { data: FlexPoolStylesResponse }) => ({
  type: SET_FLEX_POOL_STYLES,
  payload,
});

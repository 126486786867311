import React, { useCallback, useEffect, useMemo } from 'react';
import Cookies from 'js-cookie';
import Pusher, { Channel } from 'pusher-js';

import errorLogger from '@/utilities/errors/logger';

import { auth0Service } from '../auth/auth0-service';

const PusherEventContext = React.createContext(null);

interface PusherEventProviderProps {
  children: JSX.Element;
}

const API_ENDPOINT = `${process.env.API_ENDPOINT}/v1` || 'http://localhost:3000/api/v1';

let pusher: Pusher;

// initPusher is a func rather than straight calling `new`, because we want
// to wait and make sure we have the auth session (if you login or register
// we don't have this)
export const initPusher = () => {
  if (pusher) {
    // re-authorize pusher instance (pusher instance exists until tab refresh or tab close)
    pusher.disconnect();
    pusher = null;
  }
  if (!pusher) {
    pusher = new Pusher(process.env.PUSHER_APP_KEY, {
      cluster: process.env.PUSHER_APP_CLUSTER,
      channelAuthorization: {
        endpoint: `${API_ENDPOINT}/authorizations/pusher_channels`,
        transport: 'ajax',
        headers: {
          Authorization: Cookies.get('session') || auth0Service.getAccessToken(),
        },
      },
    });
  }

  return pusher;
};

export type PusherService = {
  subscribeToPrivateChannels: typeof subscribeToPrivateChannels;
  unsubscribeToPrivateChannels: typeof unsubscribeToPrivateChannels;
};

export const subscribeToPrivateChannels = (userId?: string) => {
  if (!pusher.channel(`private-user-${userId}`) && userId) {
    errorLogger(false, `subscribing -- private-user-${userId}`);
    return pusher.subscribe(`private-user-${userId}`);
  }
  return pusher.channel(`private-user-${userId}`);
};

export const unsubscribeToPrivateChannels = (userId: string) => {
  pusher.unsubscribe(`private-user-${userId}`);
};

export const useChannel = (channelName: string, userId?: string) => {
  if (!pusher) {
    initPusher();
  }
  // this is annoying but sometimes, useChannel is called before private channels are subscribed
  subscribeToPrivateChannels(userId);
  const channel = useMemo(() => {
    if (!channelName) return null;
    if (!pusher.channel(channelName)) {
      errorLogger(false, `subscribing -- ${channelName}`);
      return pusher.subscribe(channelName);
    }
    // if we already have the channel we still want to return it
    // might just be able to subscribe to it again
    return pusher.channel(channelName);
  }, [channelName]);

  // useEffect(() => (
  //   () => {
  // errorLogger(false, 'unsubscribing --', channelName);
  //     pusher.unsubscribe(channelName);
  //   }
  // ), [channelName]);

  return channel;
};

export const unsubscribeChannel = (channelName: string) => {
  if (pusher.channel(channelName)) {
    errorLogger(false, `unsubscribing -- ${channelName}`);
    pusher.unsubscribe(channelName);
  }
};

// TODO [FAN-2199]: this service needs so much cleaning up, see ActiveDraftCell
export const useEvent = (channel: Channel, event: string, callback: (data: any) => void) => {
  const memoCallback = useCallback(callback, [callback]);
  useEffect(() => {
    if (channel) {
      channel.unbind(event, memoCallback); // only bind the event once
      channel.bind(event, memoCallback);
    }
    return () => {
      if (channel) {
        channel.unbind(event, memoCallback);
      }
    };
  }, [memoCallback, channel, event]);
};

export interface EventItem {
  channelName: string;
  eventName: string;
  callback: (...args: any) => void;
}

// export const useChannelEventLoop = ({ eventArray, userId }:
// { eventArray: EventItem[]; userId: string }) => {
//   if (!pusher) {
//     initPusher();
//   }
//   subscribeToPrivateChannels(userId);
//   useEffect(() => {
//     if (!eventArray || eventArray?.length < 1) return null;
//     eventArray.forEach((item) => {
//       if (!pusher.channel(item.channelName)) {
//         const channel = pusher.subscribe(item.channelName);
//         channel.unbind(item.eventName, item.callback);
//         channel.bind(item.eventName, item.callback);
//         errorLogger(false, `subscribing -- ${item.channelName}`);
//       }
//     });

//     return () => {
//       eventArray.forEach((channelEvent) => {
//         errorLogger(false, `unsubscribing -- ${channelEvent.channelName}`);
//         pusher.unsubscribe(channelEvent.channelName);
//       });F
//     };
//   }, [eventArray]);
// };

export const PusherEventProvider = (props: PusherEventProviderProps) => {
  const providerPusher = useMemo(
    () =>
      new Pusher(process.env.PUSHER_APP_KEY, {
        cluster: process.env.PUSHER_APP_CLUSTER,
        authEndpoint: `${API_ENDPOINT}/authorizations/pusher_channels`,
        auth: {
          params: null,
          headers: {
            // this will be either Devise or Auth0 - only one token is present at a time
            // pusher doesn't accept an async function, so we can't call auth0Service.getAuth,
            // but pusher will only initalize after a token exists
            Authorization: Cookies.get('session') || auth0Service.getAccessToken(),
          },
        },
      }),
    []
  );

  return (
    <PusherEventContext.Provider value={providerPusher}>
      {props.children}
    </PusherEventContext.Provider>
  );
};

export default PusherEventContext;

import React, { PropsWithChildren, Suspense } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import Loader from '@/components/atoms/loader';
import { withAuth } from '@/components/contexts/auth';
import Nav from '@/components/molecules/nav';

import { ALTERNATIVE_HOME_ROUTE, DEFAULT_ROUTE } from './constants';

/**
 * The Auth route checks if a user is logged in and trying to hit one of these
 * routes and redirects them to the lobby page if they are already logged in.
 * It knows that a user is logged in because the component is connected to the
 * store, and it gets the state.session.auth boolean from there.
 * The session reducer gets the auth from the cookie in the user's browser while
 * the redux store is being hydrated.
 *
 * Page loads > createStore runs all the reducers > the session reducer gets the
 * auth from the browser cookie > these routes then have access to that and can
 * make decisions
 */

const Auth = ({
  children,
  isAuthenticated,
  isAlternateHomeRoute,
}: PropsWithChildren<{ isAuthenticated: boolean; isAlternateHomeRoute: boolean }>) => {
  const location = useLocation();

  if (isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: isAlternateHomeRoute ? ALTERNATIVE_HOME_ROUTE : DEFAULT_ROUTE,
          search: location?.search ? location.search : null,
        }}
        replace
      />
    );
  }

  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  return <Suspense fallback={<Loader />}>{children}</Suspense>;
};

/**
 * See note for the Auth route applies for the redirected route, except that it
 * will only let you access pages if you're authenticated
 */

const Protected = ({
  children,
  isAuthenticated,
  hideNav = false,
}: PropsWithChildren<{
  isAuthenticated: boolean;
  hideNav?: boolean;
}>) => {
  const location = useLocation();

  if (isAuthenticated) {
    return (
      <>
        {!hideNav && <Nav />}
        <Suspense fallback={<Loader />}>{children}</Suspense>
      </>
    );
  }

  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  return (
    <Navigate
      to={{
        pathname: '/login',
        search: `${
          location?.pathname
            ? `next=${encodeURIComponent(location?.pathname)}${location?.search || ''}`
            : null
        }`,
      }}
      replace
    />
  );
};

/**
 * See note for the Auth route applies for the redirected route, except that it
 * will only let you access pages if you're authenticated
 */

const ProtectedMobile = ({
  isAuthenticated,
  children,
}: PropsWithChildren<{ isAuthenticated: boolean }>) => {
  if (isAuthenticated) {
    return <Suspense fallback={<Loader />}>{children}</Suspense>;
  }

  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  return <Navigate to={{ pathname: '/login' }} replace />;
};

export const AuthRoute = withAuth(Auth);

export const ProtectedRoute = withAuth(Protected);

export const ProtectedMobileRoute = withAuth(ProtectedMobile);

/* eslint-disable camelcase */
import { PayoutOption, PayoutStyle, PayoutStyleResponse } from '@/interfaces/pick-em';
import {
  DEFAULT_MAX_INSURED_PAYOUT_MODIFIER,
  DEFAULT_MAX_PAYOUT_MODIFIER,
} from '@/utilities/constants';

const payoutStyleAdapter = (payoutStyle: PayoutStyleResponse): PayoutStyle => {
  const {
    id,
    name,
    rules_url,
    status,
    min_fee,
    payout_options: payoutOptionsResponse,
    insurance,
    insured_selections,
    max_insured_multiplier,
    max_standard_multiplier,
  } = payoutStyle;

  const payoutOptions: PayoutOption[] = payoutOptionsResponse.map((pO) => {
    const { id: optionId, selection_count, multiplier, max_fee, min_multiplier } = pO;

    return {
      id: optionId,
      selectionCount: selection_count,
      multiplier,
      maxFee: max_fee,
      minMultiplier: min_multiplier,
    };
  });

  return {
    id,
    name,
    rulesUrl: rules_url,
    status,
    minFee: min_fee,
    payoutOptions,
    default: payoutStyle.default,
    insurance,
    insuredSelections: insured_selections,
    maxInsuredMultiplier: parseFloat(max_insured_multiplier) || DEFAULT_MAX_PAYOUT_MODIFIER,
    maxStandardMultiplier:
      parseFloat(max_standard_multiplier) || DEFAULT_MAX_INSURED_PAYOUT_MODIFIER,
  };
};

export default payoutStyleAdapter;

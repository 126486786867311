/* eslint-disable camelcase */

import appearanceAdapter from '@/store/modules/constructed-slates/adapters/appearance';
import playerAdapter from '@/store/modules/constructed-slates/adapters/player';
import matchAdapter from '@/store/modules/matches/adapters/match';
import payoutOutcomeAdapter from '@/store/modules/pick-em/adapters/payout-outcome';

import { PickEmConfigurationsResponse } from '@/interfaces/configurations';
import { SelectedOverUnder } from '@/interfaces/constructed-interfaces/constructed-pick-em-over-under-appearance';
import { SelectedRival } from '@/interfaces/constructed-interfaces/constructed-pick-em-rival-appearance';
import { LiveStatLines } from '@/interfaces/constructed-interfaces/constructed-slates';
import {
  CorrelationsResponse,
  EntrySlips,
  EntrySlipsResponse,
  EntrySlipState,
  FlexPoolStylesResponse,
  OverUnderLines,
  PayoutOutcomeResponse,
  PayoutStylesResponse,
  PickEmEntries,
  PickEmEntrySlipLimitResponse,
  PickEmEntrySlipLimits,
  PickEmPoolStylesResponse,
  RivalLine,
} from '@/interfaces/pick-em';
import { UD_PICK_EM } from '@/utilities/constants';
import { arrayToObjectIdKeys } from '@/utilities/helpers';

import configurationAdapter from '../../adapters/configuration';
import correlationAdapter from '../../adapters/correlation';
import entrySlipAdapter from '../../adapters/entry-slip';
import pickEmEntrySlipLimitsAdapter from '../../adapters/entry-slip-limit';
import entrySlipRivalLineAdapter from '../../adapters/entry-slip-rival-line';
import flexPoolStyleAdapter from '../../adapters/flex-pool-style';
import overUnderAdapter from '../../adapters/over-under';
import overUnderLineAdapter from '../../adapters/over-under-lines';
import overUnderOptionAdapter from '../../adapters/over-under-option';
import payoutStyleAdapter from '../../adapters/payout-style';
import pickEmPoolStyleAdapter from '../../adapters/pick-em-pool-style';
import rivalAdapter from '../../adapters/rival';
import rivalOptionAdapter from '../../adapters/rival-option';
import soloGameAdapter from '../../adapters/solo-game';

import { adjustSlipType, getBoostType, getPayoutMultiplier, getPayoutStyle } from '.';

export const setPickEmConfigToState = (
  state: PickEmEntries,
  {
    payoutStylesResponse,
    correlationsResponse,
    pickEmConfigurationsResponse,
  }: {
    payoutStylesResponse: PayoutStylesResponse;
    correlationsResponse: CorrelationsResponse;
    pickEmConfigurationsResponse: PickEmConfigurationsResponse;
  }
): PickEmEntries => {
  const payoutStylesById = arrayToObjectIdKeys(
    // convert to object with ids as keys
    payoutStylesResponse.payout_styles.map(
      (payoutStyle) => payoutStyleAdapter(payoutStyle) // convert response shape to redux shape
    )
  );
  const correlations = correlationsResponse.correlations.map(correlationAdapter);

  const pickEmConfigurations =
    pickEmConfigurationsResponse.configurations.map(configurationAdapter);

  return {
    ...state,
    payoutStyles: payoutStylesById,
    correlations,
    pickEmConfigurations,
  };
};

export const setPayoutOutcomeToState = (
  state: PickEmEntries,
  { data, entryRoles }: { data: PayoutOutcomeResponse; entryRoles: string[] }
): PickEmEntries => {
  const adaptedPayoutOutcome = payoutOutcomeAdapter({ data, entryRoles });
  return {
    ...state,
    payoutOutcome: adaptedPayoutOutcome,
  };
};

export const setPoolStyles = (
  state: PickEmEntries,
  { data }: { data: PickEmPoolStylesResponse }
) => {
  const pickEmPoolStylesById = arrayToObjectIdKeys(
    data.pickem_pool_styles.map(pickEmPoolStyleAdapter)
  );

  return {
    ...state,
    pickEmPoolStyles: pickEmPoolStylesById,
  };
};

export const setFlexPoolStyles = (
  state: PickEmEntries,
  { data }: { data: FlexPoolStylesResponse }
) => {
  const flexPoolStylesById = arrayToObjectIdKeys(data.pickem_pool_styles.map(flexPoolStyleAdapter));

  return {
    ...state,
    flexPoolStyles: flexPoolStylesById,
  };
};

export const setEntries = (
  state: EntrySlips,
  { data: dataResponse, pageNumber }: { data: EntrySlipsResponse; pageNumber?: number }
): EntrySlips => {
  const {
    data: {
      appearances: appearancesResponse,
      entry_slips,
      over_under_options,
      over_under_lines,
      over_unders,
      games: gamesResponse,
      players: playersResponse,
      rival_lines,
      rival_options,
      rivals: rivalsResponse,
      solo_games,
    },
    meta,
  } = dataResponse;

  const newAppearances = appearancesResponse?.map(appearanceAdapter);
  const newEntrySlips = entry_slips?.map(entrySlipAdapter);
  const newOverUnderOptions = arrayToObjectIdKeys(over_under_options?.map(overUnderOptionAdapter));
  const newOverUnderLines = arrayToObjectIdKeys(over_under_lines?.map(overUnderLineAdapter));
  const newOverUnders = arrayToObjectIdKeys(over_unders?.map(overUnderAdapter));
  const newGames = arrayToObjectIdKeys(gamesResponse?.map(matchAdapter));
  const newPlayers = arrayToObjectIdKeys(playersResponse?.map(playerAdapter));
  const newRivalLines = arrayToObjectIdKeys(rival_lines?.map(entrySlipRivalLineAdapter));
  const newRivalOptions = arrayToObjectIdKeys(rival_options?.map(rivalOptionAdapter));
  const newRivals = arrayToObjectIdKeys(rivalsResponse?.map(rivalAdapter));
  const newSoloGames = arrayToObjectIdKeys(solo_games?.map(soloGameAdapter));

  // combine with existing and remove duplicates
  const appearances =
    state.appearances && pageNumber
      ? [...state.appearances, ...newAppearances].filter(
          (appearance, ind, arr) => arr.findIndex((e) => e.id === appearance.id) === ind
        )
      : newAppearances;

  const entrySlips =
    state.entrySlips && pageNumber
      ? [...state.entrySlips, ...newEntrySlips].filter(
          (entrySlip, ind, arr) => arr.findIndex((e) => e.id === entrySlip.id) === ind
        )
      : newEntrySlips;

  const overUnderOptions =
    state.overUnderOptions && pageNumber
      ? {
          ...state.overUnderOptions,
          ...newOverUnderOptions,
        }
      : newOverUnderOptions;

  const overUnderLines =
    state.overUnderLines && pageNumber
      ? {
          ...state.overUnderLines,
          ...newOverUnderLines,
        }
      : newOverUnderLines;

  const overUnders =
    state.overUnders && pageNumber
      ? {
          ...state.overUnders,
          ...newOverUnders,
        }
      : newOverUnders;

  const games =
    state.games && pageNumber
      ? {
          ...state.games,
          ...newGames,
        }
      : newGames;

  const players =
    state.players && pageNumber
      ? {
          ...state.players,
          ...newPlayers,
        }
      : newPlayers;

  const rivalLines =
    state.rivalLines && pageNumber
      ? {
          ...state.rivalLines,
          ...newRivalLines,
        }
      : newRivalLines;

  const rivalOptions =
    state.rivalOptions && pageNumber
      ? {
          ...state.rivalOptions,
          ...newRivalOptions,
        }
      : newRivalOptions;

  const rivals =
    state.rivals && pageNumber
      ? {
          ...state.rivals,
          ...newRivals,
        }
      : newRivals;

  const soloGames =
    state.soloGames && pageNumber
      ? {
          ...state.soloGames,
          ...newSoloGames,
        }
      : newSoloGames;

  const appearanceIds = appearances.map((a) => a.id); // shouldn't change, used for pusher subscribing
  const gameIds = Object.keys(games); // shouldn't change, used for pusher subscribing
  const soloGameIds = Object.keys(soloGames);

  const liveStatLines: LiveStatLines = appearances.reduce((acc: LiveStatLines, curr) => {
    acc[curr.id] = curr.statLine;
    return acc;
  }, {});

  return {
    appearanceIds,
    gameIds,
    soloGameIds,
    entrySlips,
    overUnderOptions,
    overUnderLines,
    overUnders,
    appearances,
    games,
    players,
    rivalLines,
    rivalOptions,
    rivals,
    soloGames,
    liveStatLines,
    liveGames: games,
    meta,
    lastUpdated: new Date(),
  };
};

export const setPickEmEntries = (
  state: PickEmEntries,
  { data: dataResponse, pageNumber }: { data: EntrySlipsResponse; pageNumber?: number },
  entrySlipState: EntrySlipState
): PickEmEntries => {
  const newPickEmEntries = setEntries(state[entrySlipState], { data: dataResponse, pageNumber });

  return {
    ...state,
    [entrySlipState]: newPickEmEntries,
  };
};

export const setSelectedPickEmEntries = (
  state: PickEmEntries,
  {
    rivalLines,
    overUnderLines,
  }: {
    rivalLines: RivalLine[];
    overUnderLines: OverUnderLines;
  }
) => {
  const pickEmFromLocalStorage = JSON.parse(localStorage.getItem(UD_PICK_EM));
  const { payoutStyles, flexPoolStyles } = state;

  // filter out inactive over unders
  // eslint-disable-next-line max-len
  const filteredSelectedOverUnders: SelectedOverUnder[] = (
    pickEmFromLocalStorage?.selectedOverUnders?.filter(
      (sOUL: SelectedOverUnder) => overUnderLines[sOUL.overUnderLineId]
    ) || []
  ).map((sOUL: SelectedOverUnder) => ({
    // grab the latest overUnderLine from redux, rather than what's stored in localStorage
    ...sOUL,
    constructedOverUnderAppearance: {
      ...sOUL.constructedOverUnderAppearance,
      overUnderLines: [overUnderLines[sOUL.overUnderLineId]],
    },
  }));

  // filter out inactive rivals
  // eslint-disable-next-line max-len
  const filteredRivalLines: SelectedRival[] =
    pickEmFromLocalStorage?.selectedRivals?.filter((sRL: SelectedRival) =>
      rivalLines.find((line: RivalLine) => line.id === sRL.id)
    ) || [];

  const boostType = getBoostType({
    selectedOverUnders: filteredSelectedOverUnders,
  });

  const slipType = adjustSlipType({ boostType });

  const payoutModifier = getPayoutMultiplier({ selectedOverUnders: filteredSelectedOverUnders });

  // get fresh payout style, we don't store this bc lines may be removed
  const newPayoutStyle = getPayoutStyle({
    payoutStyles,
    slipType,
    selectedRivals: filteredRivalLines,
    selectedOverUnders: filteredSelectedOverUnders,
  });

  const newFlexPoolStyle =
    flexPoolStyles && Object.values(flexPoolStyles || {}).find((fPS) => fPS.default);

  localStorage.setItem(
    UD_PICK_EM,
    JSON.stringify({
      ...pickEmFromLocalStorage,
      selectedOverUnders: filteredSelectedOverUnders,
      selectedRivals: filteredRivalLines,
      slipType,
      payoutModifier,
      payoutStyle: null,
      pickEmPoolStyle: null,
      flexPoolStyle: null,
      error: null,
      loaded: false,
    })
  );

  return {
    ...state,
    selected: {
      ...state.selected,
      selectedOverUnders: filteredSelectedOverUnders,
      selectedRivals: filteredRivalLines,
      payoutModifier,
      loaded: true,
      payoutStyle: newPayoutStyle,
      flexPoolStyle: newFlexPoolStyle,
      slipType,
    },
  };
};

export const setLimits = (
  state: PickEmEntries,
  { limits }: { limits: PickEmEntrySlipLimitResponse[] }
): PickEmEntries => {
  const adaptedPickEmEntrySlipLimits = limits.map(pickEmEntrySlipLimitsAdapter);

  // eslint-disable-next-line max-len
  const limitsBySportId: PickEmEntrySlipLimits = arrayToObjectIdKeys(
    adaptedPickEmEntrySlipLimits,
    'sportId'
  );

  return {
    ...state,
    selected: {
      ...state.selected,
      entrySlipLimits: limitsBySportId,
    },
  };
};

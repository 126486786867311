import { AxiosResponse } from 'axios';

import request from '@request';
import { PickEmConfigurationsResponse } from '@/interfaces/configurations';
import { apiErrorTracking, AppError } from '@/utilities/errors';

/*
 * Get Configurations
 *
 * Returns an array of configurations for different categories
 */

export const getConfigurations = async (
  category: 'Pickem'
): Promise<AxiosResponse<PickEmConfigurationsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      underdogAPIEndpoint: 'stats',
      url: `/configuration_categories/${category}/configurations`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { RootState } from '@/store';
import { getContentfulPromoInfo as getContentfulPromoInfoAction } from '@/store/modules/contentful-promo-info/actions';
import { closeToast as closeToastAction } from '@/store/modules/toast/actions';

import Avatar from '@/components/atoms/avatar';
import Button from '@/components/atoms/button';
import Icon from '@/components/atoms/icon';
import Loader from '@/components/atoms/loader';
import NavLinkWrapper from '@/components/atoms/nav-link-wrapper';
import { useToast } from '@/components/atoms/toast';
import { ContentfulPromoInfos, ContentfulPromoInfoUser } from '@/interfaces/contentful-promo-info';

import { getOptions } from './options';

import commonStyles from './common-styles.scss';

export interface ContentfulPromoInfoProps {
  contentfulInfoId: string;
  contentfulPromoInfos: ContentfulPromoInfos;
  getContentfulPromoInfoById: ({ contentfulInfoId }: { contentfulInfoId: string }) => void;
  handleCloseModal: () => void;
  closeToast: () => void;
}

const ContentfulPromoInfo = (props: ContentfulPromoInfoProps) => {
  const {
    contentfulInfoId,
    contentfulPromoInfos,
    getContentfulPromoInfoById,
    handleCloseModal,
    closeToast,
  } = props;

  const params = useParams();
  const navigate = useNavigate();
  const openToast = useToast();
  const location = useLocation();

  const isMobilePage = /^\/m\//.test(location.pathname);

  const [isLoading, setIsLoading] = useState(false);

  const promoInfoContent = contentfulPromoInfos?.[contentfulInfoId || params.contentfulInfoId];

  useEffect(() => {
    if (!promoInfoContent) {
      setIsLoading(true);
      getContentfulPromoInfoById({
        contentfulInfoId: contentfulInfoId || params.contentfulInfoId,
      });
    } else {
      setIsLoading(false);
    }
  }, [promoInfoContent, getContentfulPromoInfoById, contentfulInfoId, params.contentfulInfoId]);

  if (isLoading || !promoInfoContent) {
    return (
      <div className={commonStyles.contentfulModal}>
        <Loader />
      </div>
    );
  }

  const toastMessage = (
    <div className={commonStyles.toastMessageContainer}>
      <p>
        Sorry about that - we&apos;re unable to load the entry details. For more info, check out
        the&nbsp;
        <NavLinkWrapper
          to="/rules/promotions"
          classNames={{
            activeNavLink: commonStyles.toastLink,
            navLink: commonStyles.toastLink,
          }}
          element="Promotion rules"
          onClick={closeToast}
        />
        <Icon className={commonStyles.arrowIcon} name="arrowOutward" />
      </p>
      <button onClick={closeToast} aria-label="Close">
        <Icon className={commonStyles.toastClearIcon} name="clear" />
      </button>
    </div>
  );

  if (!isLoading && !promoInfoContent.contentfulInfoId) {
    if (handleCloseModal) {
      handleCloseModal();
    }
    openToast({
      message: toastMessage,
      open: true,
    });
    return null;
  }

  return (
    <>
      <div className={commonStyles.topHalf}>
        {promoInfoContent.title && <h2 className={commonStyles.title}>{promoInfoContent.title}</h2>}
        <img
          className={commonStyles.image}
          src={promoInfoContent.headerImage.url}
          alt={promoInfoContent.headerImage.title}
        />
      </div>
      <div className={commonStyles.bottomHalf}>
        <div className={commonStyles.section}>
          {documentToReactComponents(promoInfoContent.content, getOptions())}
        </div>
        {promoInfoContent?.usersList?.users?.length > 1 && (
          <div className={commonStyles.section}>
            <h2 className={commonStyles.sectionHeader}>{promoInfoContent.usersList.title}</h2>
            <ul className={commonStyles.usersList}>
              {promoInfoContent.usersList.users.map((user: ContentfulPromoInfoUser) => (
                <li className={commonStyles.userRow} key={user.username}>
                  <Avatar
                    user={user}
                    classNames={{
                      wrapper: commonStyles.avatarWrapper,
                      avatar: commonStyles.avatar,
                    }}
                  />
                  <span>{user.username?.toUpperCase()}</span>
                  {promoInfoContent.usersList.winningText && (
                    <span>{promoInfoContent.usersList.winningText}</span>
                  )}
                  {promoInfoContent.usersList.winningAmount && (
                    <span className={commonStyles.winningAmount}>
                      {promoInfoContent.usersList.winningAmount}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {!isMobilePage && (
        <div className={commonStyles.buttonWrapper}>
          <Button
            type="solid"
            text={promoInfoContent.ctaText}
            onClick={() => {
              handleCloseModal();
              navigate(promoInfoContent.ctaPath);
            }}
            size="regular"
            width="full"
          />
        </div>
      )}
    </>
  );
};

export default connect(
  (state: RootState) => ({
    contentfulPromoInfos: state.contentfulPromoInfos,
  }),
  (dispatch) => ({
    getContentfulPromoInfoById: ({ contentfulInfoId }: { contentfulInfoId: string }) =>
      dispatch(getContentfulPromoInfoAction({ contentfulInfoId })),
    closeToast: () => dispatch(closeToastAction()),
  })
)(ContentfulPromoInfo);

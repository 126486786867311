/* eslint-disable camelcase */
import { Correlation, CorrelationResponse } from '@/interfaces/pick-em';

const correlationAdapter = (correlationResponse: CorrelationResponse): Correlation => {
  const {
    id,
    blocked,
    coefficient,
    choice,
    position_a_id,
    position_b_id,
    pickem_stat_a_id,
    pickem_stat_b_id,
    sport_id,
    type,
  } = correlationResponse;

  return {
    id,
    blocked,
    coefficient,
    choice,
    positionAId: position_a_id,
    positionBId: position_b_id,
    pickemStatAId: pickem_stat_a_id,
    pickemStatBId: pickem_stat_b_id,
    sportId: sport_id,
    type,
  };
};

export default correlationAdapter;

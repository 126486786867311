/* eslint-disable camelcase */
import { LiveSlateOverview, LiveSlateOverviewReponse } from '@/interfaces/live';

const liveSlateOverviewsAdapter = (
  slateOverviews: LiveSlateOverviewReponse[]
): LiveSlateOverview[] =>
  slateOverviews.map((slateOverview) => {
    const { id, slate_id, draft_count, payouts, fees } = slateOverview;

    return {
      id,
      slateId: slate_id,
      draftCount: draft_count,
      payouts,
      fees,
    };
  });

export default liveSlateOverviewsAdapter;

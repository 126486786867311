/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';

import request from '@request';
import { ContestsResponse, MainLobbyResponse, SitAndGosResponse } from '@/interfaces/lobbies';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const getLobby = async (): Promise<AxiosResponse<MainLobbyResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: '/lobby',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getAllSitAndGos = async (): Promise<AxiosResponse<SitAndGosResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: '/sit_and_gos',
      underdogAPIEndpoint: 'stats',
      underdogAPIVersion: 'v2',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

export const getContestsForSlate = async ({
  slateId,
}: {
  slateId: string;
}): Promise<AxiosResponse<ContestsResponse>> => {
  try {
    const response = await request({
      method: 'GET',
      url: `/slates/${slateId}/contests`,
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

import React, { useContext } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import { NavigationContext } from '@/components/contexts/navigation';

export interface LinkWrapperProps extends LinkProps {
  className: string;
  element: React.ReactNode;
  testId?: string;
}

const LinkWrapper = (props: LinkWrapperProps) => {
  const { className, onClick, element, target, to, testId } = props;
  const { getNextRoute } = useContext(NavigationContext);

  return (
    <Link
      className={className}
      onClick={onClick}
      target={target}
      to={getNextRoute({ path: to })}
      data-testid={testId}
    >
      {element}
    </Link>
  );
};

export default LinkWrapper;

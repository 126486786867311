/* eslint-disable camelcase */
import playerAdapter from '@/store/modules/constructed-slates/adapters/player';

import { ampli } from '@/ampli';
import { FavoritePlayer, FavoritePlayersResponse } from '@/interfaces/pick-em';
import langHelper from '@/utilities/lang-helper';

import {
  ADD_FAVORITE_PICK_EM_PLAYER,
  addFavoritePickEmPlayer,
  REMOVE_FAVORITE_PICK_EM_PLAYER,
  removeFavoritePickEmPlayer,
  SET_FAVORITE_PICK_EM_PLAYERS,
  setFavoritePickEmPlayers,
} from '../actions';

type State = FavoritePlayer[];

type FavoritePlayersActions = ReturnType<
  | typeof setFavoritePickEmPlayers
  | typeof addFavoritePickEmPlayer
  | typeof removeFavoritePickEmPlayer
>;

const initialState: FavoritePlayer[] = [];

const set = ({ data }: { data: FavoritePlayersResponse }): FavoritePlayer[] => {
  const { favorite_players } = data;

  const favoritePlayers: FavoritePlayer[] = favorite_players.map((player) => {
    const adaptedPlayer = playerAdapter(player);
    return {
      ...adaptedPlayer,
      favorite: true,
    };
  });

  return favoritePlayers;
};

const add = (state: FavoritePlayer[], { player }: { player: FavoritePlayer }): FavoritePlayer[] => {
  // if the player exists, don't add them a second time
  if (state.findIndex((p) => p.id === player.id) >= 0) {
    return state;
  }
  const newPlayer = {
    ...player,
    favorite: true,
  };

  ampli.pickemFavoriteAdded({
    player_id: player.id,
    player_name: langHelper.getPlayerFullName(player),
    sport_name: player.sportId,
  });

  return [...state, newPlayer];
};

const remove = (state: FavoritePlayer[], { playerId }: { playerId: string }): FavoritePlayer[] => {
  const removedPlayer = state.find((player) => player.id === playerId);
  const newState = state.filter((player) => player.id !== playerId);

  ampli.pickemFavoriteRemoved({
    player_id: removedPlayer.id,
    player_name: langHelper.getPlayerFullName(removedPlayer),
    sport_name: removedPlayer.sportId,
  });

  return newState;
};

export const favoritePlayersReducer = (
  state: State = initialState,
  action: FavoritePlayersActions = {} as FavoritePlayersActions
): State => {
  switch (action.type) {
    case SET_FAVORITE_PICK_EM_PLAYERS:
      return set(action.payload);
    case ADD_FAVORITE_PICK_EM_PLAYER:
      return add(state, action.payload);
    case REMOVE_FAVORITE_PICK_EM_PLAYER:
      return remove(state, action.payload);
    default:
      return state;
  }
};

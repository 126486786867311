export const ERROR_CODES = {
  BLOCKED_EMAIL: 'blocked_email',
  CONFIRMATION_NEEDED: 'confirmation_needed',
  CS_VERIFICATION_NEEDED: 'cs_verification_needed',
  DEPOSIT_NEEDED: 'deposit_needed',
  FORM_VERIFICATION_NEEDED: 'form_verification_needed',
  ID_VERIFICATION_NEEDED: 'id_verification_needed',
  INVALID_ENTRY: 'invalid_entry',
  LOCATION_NEEDED: 'location_needed',
  RANKINGS_NEEDED: 'rankings_needed',
  TAX_INFO_NEEDED: 'tax_info_needed',
  UNAVAILABLE_COUNTRY_FAILED: 'unavailable_country_failed',
  UPGRADE_REQUIRED: 'upgrade_required',
  USER_DISMISS: 'user_dismiss',
};

export const ERROR_MESSAGES = {
  UNAUTHORIZED_ERROR: 'You were logged out from your account, please log in again.',
  UNAUTHORIZED_ERROR_MOBILE: 'Please return to the app and try again.',
  WEAK_PASSWORD: `Your password must contain at least one uppercase letter, one lowercase letter,
  one special character and one number and must be 8+ characters long`,
};

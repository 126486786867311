/* eslint-disable camelcase */
import { PickEmConfiguration, PickEmConfigurationResponse } from '@/interfaces/configurations';

const pickEmConfigurationAdapter = (
  pickEmConfigurationResponse: PickEmConfigurationResponse
): PickEmConfiguration => {
  const { id, category, type, owner_type, owner_id, value, value_type } =
    pickEmConfigurationResponse;

  return {
    id,
    category,
    type,
    ownerType: owner_type,
    ownerId: owner_id,
    value,
    valueType: value_type,
  };
};

export default pickEmConfigurationAdapter;

import { PickEmConfigurationResponse } from '@/interfaces/configurations';
import { EntrySlipState, PickEmEntries } from '@/interfaces/pick-em';

import {
  ACCEPT_SELECTED_OVER_UNDER_LINE_SWAP,
  acceptSelectedOverUnderLineSwap,
  ADD_FAVORITE_PICK_EM_PLAYER,
  addFavoritePickEmPlayer,
  CLEAR_PICK_EM_ENTRY_SLIP,
  clearPickEmEntrySlip,
  REMOVE_FAVORITE_PICK_EM_PLAYER,
  REMOVE_PICK_EM_OVER_UNDER_LINE,
  REMOVE_SELECTED_ERROR,
  removeFavoritePickEmPlayer,
  removePickEmOverUnderLine,
  removeSelectedPickEmError,
  RESET_PICK_EM_POOL_LEADERBOARD,
  RESET_SETTLED_PICK_EM_ENTRIES,
  resetPickEmPoolLeaderboard,
  resetSettledPickEmEntries,
  SET_ENTRY_SLIP_LIMIT,
  SET_FLEX_POOL_STYLES,
  SET_INITIAL_PICK_EM_SELECTIONS,
  SET_LIVE_PICK_EM_ENTRIES,
  SET_PAYOUT_OUTCOME,
  SET_PICK_EM_CONFIG,
  SET_PICK_EM_POOL_LEADERBOARD,
  SET_PICK_EM_POOL_STYLES,
  SET_SETTLED_PICK_EM_ENTRIES,
  setEntrySlipLimits,
  setFlexPoolStyles as setFlexPoolStylesAction,
  setInitialPickEmSelections,
  setLivePickEmEntries,
  setPickEmConfig,
  setPickEmPoolLeaderboard,
  setPickEmPoolStyles,
  setSettledPickEmEntries,
  setSlipPayoutOutcome,
  SWAP_FINISHED_PICK_EM_OVER_UNDER_LINE,
  SWAP_PICK_EM_OVER_UNDER_LINE,
  swapFinishedPickEmOverUnderLine,
  swapPickEmOverUnderLine,
  TOGGLE_PICK_EM_INSURANCE,
  togglePickEmInsurance,
  UPDATE_LIVE_PICK_EM_GAME,
  UPDATE_LIVE_PICK_EM_SOLO_GAME,
  UPDATE_LIVE_PICK_EM_STAT_LINE,
  UPDATE_PICK_EM_CONFIGURATION,
  UPDATE_PICK_EM_LIVE_EVENT_STAT,
  UPDATE_PICK_EM_OVER_UNDER_LINE_STATUS,
  UPDATE_PICKS_FROM_SHARE_SLIP,
  UPDATE_SELECTED_OVER_UNDERS,
  UPDATE_SELECTED_RIVALS,
  updateLivePickEmGame,
  updateLivePickEmSoloGame,
  updateLivePickEmStatLine,
  updatePickEmConfiguration,
  updatePickEmLiveEventStat,
  updatePickEmOverUnderLineStatus,
  updateSelectedOverUnders,
  updateSelectedPicksFromSlip,
  updateSelectedRivals,
} from '../actions';
import configurationsAdapter from '../adapters/configuration';

import {
  setFlexPoolStyles,
  setLimits,
  setPayoutOutcomeToState,
  setPickEmConfigToState,
  setPickEmEntries,
  setPoolStyles,
  setSelectedPickEmEntries,
} from './helpers/set-pick-em-entries';
import {
  acceptSelectedSwap,
  addSelectedFavoritePlayer,
  clearEntrySlip,
  remove,
  removeErr,
  removeSelectedFavoritePlayer,
  swapFinishedSelectedOverUnderLine,
  swapSelectedOverUnderLine,
  toggleInsurance,
  updateLiveGame,
  updateLiveSoloGame,
  updateLiveStatLine,
  updateOverUnders,
  updatePicksFromSlip,
  updateRivals,
  updateSelectedOverUnderLineLiveEventStat,
  updateSelectedOverUnderLineStatus,
} from './helpers/update-pick-em-entries';

type State = PickEmEntries;

type PickEmEntriesActions = ReturnType<
  | typeof acceptSelectedOverUnderLineSwap
  | typeof addFavoritePickEmPlayer
  | typeof clearPickEmEntrySlip
  | typeof removeFavoritePickEmPlayer
  | typeof removePickEmOverUnderLine
  | typeof removeSelectedPickEmError
  | typeof resetPickEmPoolLeaderboard
  | typeof resetSettledPickEmEntries
  | typeof setEntrySlipLimits
  | typeof setFlexPoolStylesAction
  | typeof setInitialPickEmSelections
  | typeof setLivePickEmEntries
  | typeof setPickEmConfig
  | typeof setPickEmPoolLeaderboard
  | typeof setSettledPickEmEntries
  | typeof setSlipPayoutOutcome
  | typeof setPickEmPoolStyles
  | typeof swapFinishedPickEmOverUnderLine
  | typeof swapPickEmOverUnderLine
  | typeof togglePickEmInsurance
  | typeof updateLivePickEmGame
  | typeof updateLivePickEmSoloGame
  | typeof updateLivePickEmStatLine
  | typeof updateSelectedOverUnders
  | typeof updateSelectedPicksFromSlip
  | typeof updateSelectedRivals
  | typeof updatePickEmConfiguration
  | typeof updatePickEmOverUnderLineStatus
  | typeof updatePickEmLiveEventStat
>;

export const initialPickEmEntriesState: PickEmEntries = {
  leaderboard: {
    appearanceIds: null,
    gameIds: null,
    soloGameIds: null,
    appearances: null,
    entrySlips: null,
    overUnderOptions: null,
    overUnderLines: null,
    overUnders: null,
    games: null,
    players: null,
    rivalLines: null,
    rivalOptions: null,
    rivals: null,
    soloGames: null,
    liveStatLines: null,
    liveGames: null,
    meta: null,
    lastUpdated: null,
  },
  live: {
    appearanceIds: null,
    gameIds: null,
    soloGameIds: null,
    appearances: null,
    entrySlips: null,
    overUnderOptions: null,
    overUnderLines: null,
    overUnders: null,
    games: null,
    players: null,
    rivalLines: null,
    rivalOptions: null,
    rivals: null,
    soloGames: null,
    liveStatLines: null,
    liveGames: null,
    meta: null,
    lastUpdated: null,
  },
  selected: {
    selectedOverUnders: [],
    selectedRivals: [],
    entrySlipLimits: null,
    error: null,
    loaded: false,
    payoutStyle: null,
    pickEmPoolStyle: null,
    flexPoolStyle: null,
    slipType: null,
    payoutModifier: 1,
  },
  settled: {
    appearanceIds: null,
    gameIds: null,
    soloGameIds: null,
    appearances: null,
    entrySlips: null,
    overUnderOptions: null,
    overUnderLines: null,
    overUnders: null,
    games: null,
    players: null,
    rivalLines: null,
    rivalOptions: null,
    rivals: null,
    soloGames: null,
    liveStatLines: null,
    liveGames: null,
    meta: null,
    lastUpdated: null,
  },
  payoutStyles: null,
  correlations: null,
  payoutOutcome: null,
  pickEmConfigurations: null,
  pickEmPoolStyles: null,
  flexPoolStyles: null,
};

export const resetPickEmEntries = (
  state: PickEmEntries,
  entrySlipState: EntrySlipState
): PickEmEntries => ({
  ...state,
  [entrySlipState]: {
    ...initialPickEmEntriesState[entrySlipState],
    lastUpdated: new Date(),
  },
});

const updatePickEmConfigurationToState = (
  state: PickEmEntries,
  { newConfiguration }: { newConfiguration: PickEmConfigurationResponse }
) => {
  const adaptedNewConfiguration = configurationsAdapter(newConfiguration);
  const updatedPickEmConfigurations = state.pickEmConfigurations.map((configuration) => {
    return configuration.type === adaptedNewConfiguration.type
      ? adaptedNewConfiguration
      : configuration;
  });
  return { ...state, pickEmConfigurations: updatedPickEmConfigurations };
};

export const pickEmEntriesReducer = (
  state: State = initialPickEmEntriesState,
  action: PickEmEntriesActions = {} as PickEmEntriesActions
): State => {
  switch (action.type) {
    // set methods
    case SET_PICK_EM_CONFIG:
      return setPickEmConfigToState(state, action.payload);
    case SET_PAYOUT_OUTCOME:
      return setPayoutOutcomeToState(state, action.payload);
    case SET_PICK_EM_POOL_STYLES:
      return setPoolStyles(state, action.payload);
    case SET_FLEX_POOL_STYLES:
      return setFlexPoolStyles(state, action.payload);
    case SET_LIVE_PICK_EM_ENTRIES:
      return setPickEmEntries(state, action.payload, 'live');
    case SET_SETTLED_PICK_EM_ENTRIES:
      return setPickEmEntries(state, action.payload, 'settled');
    case RESET_SETTLED_PICK_EM_ENTRIES:
      return resetPickEmEntries(state, 'settled');
    case SET_INITIAL_PICK_EM_SELECTIONS:
      return setSelectedPickEmEntries(state, action.payload);
    case SET_ENTRY_SLIP_LIMIT:
      return setLimits(state, action.payload);
    case SET_PICK_EM_POOL_LEADERBOARD:
      return setPickEmEntries(state, action.payload, 'leaderboard');
    case RESET_PICK_EM_POOL_LEADERBOARD:
      return resetPickEmEntries(state, 'leaderboard');

    // live
    case UPDATE_LIVE_PICK_EM_STAT_LINE:
      return updateLiveStatLine(state, action.payload);
    case UPDATE_LIVE_PICK_EM_GAME:
      return updateLiveGame(state, action.payload);
    case UPDATE_LIVE_PICK_EM_SOLO_GAME:
      return updateLiveSoloGame(state, action.payload);

    // updates
    case UPDATE_PICK_EM_CONFIGURATION:
      return updatePickEmConfigurationToState(state, action.payload);
    case UPDATE_PICK_EM_OVER_UNDER_LINE_STATUS:
      return updateSelectedOverUnderLineStatus(state, action.payload);
    case SWAP_PICK_EM_OVER_UNDER_LINE:
      return swapSelectedOverUnderLine(state, action.payload);
    case SWAP_FINISHED_PICK_EM_OVER_UNDER_LINE:
      return swapFinishedSelectedOverUnderLine(state, action.payload);
    case UPDATE_PICK_EM_LIVE_EVENT_STAT:
      return updateSelectedOverUnderLineLiveEventStat(state, action.payload);
    case ACCEPT_SELECTED_OVER_UNDER_LINE_SWAP:
      return acceptSelectedSwap(state, action.payload);

    // selected
    case UPDATE_SELECTED_OVER_UNDERS:
      return updateOverUnders(state, action.payload);
    case ADD_FAVORITE_PICK_EM_PLAYER:
      return addSelectedFavoritePlayer(state, action.payload);
    case REMOVE_FAVORITE_PICK_EM_PLAYER:
      return removeSelectedFavoritePlayer(state, action.payload);
    case UPDATE_PICKS_FROM_SHARE_SLIP:
      return updatePicksFromSlip(state, action.payload);
    case UPDATE_SELECTED_RIVALS:
      return updateRivals(state, action.payload);
    case CLEAR_PICK_EM_ENTRY_SLIP:
      return clearEntrySlip(state);
    case REMOVE_PICK_EM_OVER_UNDER_LINE:
      return remove(state, action.payload);
    case REMOVE_SELECTED_ERROR:
      return removeErr(state);
    case TOGGLE_PICK_EM_INSURANCE:
      return toggleInsurance(state, action.payload);

    default:
      return state;
  }
};

import React, { useEffect } from 'react';

import Icon from '@/components/atoms/icon';

import styles from './styles.scss';

export interface ZendeskLauncherProps {
  isMobilePage: boolean;
  pathname: string;
}

const ZendeskLauncher = (props: ZendeskLauncherProps) => {
  const { isMobilePage, pathname } = props;
  const [showZendeskLauncher, setShowZendeskLauncher] = React.useState<boolean>(false);
  const [unreadMessagesCount, setUnreadMessagesCount] = React.useState<number>(0);

  useEffect(() => {
    if (
      isMobilePage ||
      pathname === '/' ||
      pathname === '/legality-underdog-contests' ||
      pathname === '/guarddog' ||
      pathname === '/splash/jack'
    ) {
      setShowZendeskLauncher(false);
    } else {
      setShowZendeskLauncher(true);
      if (window.zE) {
        // this could be using the messenger sdk or the webWidget sdk depending on setting that customer support choose in the zendesk admin
        window.zE('messenger:on', 'unreadMessages', (count: number) => {
          setUnreadMessagesCount(count);
        });
      }
    }
  }, [isMobilePage, pathname]);

  return showZendeskLauncher ? (
    <button
      className={styles.zendeskButton}
      onClick={() => {
        try {
          window.zE('webWidget', 'show');
        } catch (e) {
          // swallow error
        }
        try {
          window.zE('messenger', 'show');
        } catch (e) {
          // swallow error
        }
        try {
          window.zE('messenger', 'open');
        } catch (e) {
          // swallow error
        }
      }}
    >
      <Icon name="chatIcon" />
      {unreadMessagesCount > 0 ? (
        <div className={styles.unreadMessageCount}>{unreadMessagesCount}</div>
      ) : null}
    </button>
  ) : null;
};

export default ZendeskLauncher;

import {
  ContestsResponse,
  EntryCountUpdate,
  MainLobbyResponse,
  SlateSitAndGoResponse,
} from '@/interfaces/lobbies';
import { SlatesResponse } from '@/interfaces/slates';
import { SportId } from '@/interfaces/types';

export const GET_MAIN_LOBBY = 'lobbies/GET_MAIN_LOBBY' as const;
export const SET_MAIN_LOBBY = 'lobbies/SET_MAIN_LOBBY' as const;

export const GET_SLATES_FOR_SPORT = 'lobbies/GET_SLATES_FOR_SPORT' as const;
export const SET_SLATES_FOR_SPORT = 'lobbies/SET_SLATES_FOR_SPORT' as const;

export const GET_CONTESTS_FOR_SLATE = 'lobbies/GET_CONTESTS_FOR_SLATE' as const;
export const SET_CONTESTS_FOR_SLATE = 'lobbies/SET_CONTESTS_FOR_SLATE' as const;
export const ERROR_CONTESTS_FOR_SLATE = 'lobbies/ERROR_CONTESTS_FOR_SLATE' as const;

export const REMOVE_SLATE_SIT_AND_GOS = 'lobbies/REMOVE_SLATE_SIT_AND_GOS' as const;
export const REMOVE_LOBBY_TOURNAMENT = 'lobbies/REMOVE_LOBBY_TOURNAMENT' as const;
export const REMOVE_DRAFT_POOL = 'lobbies/REMOVE_DRAFT_POOL' as const;
export const REMOVE_LOBBY_WEEKLY_WINNER = 'lobbies/REMOVE_LOBBY_WEEKLY_WINNER' as const;
export const REMOVE_LOBBY_DRAFT_POOL = 'lobbies/REMOVE_LOBBY_DRAFT_POOL' as const;

export const UPDATE_SLATE_SIT_AND_GO_COUNT = 'lobbies/UPDATE_SLATE_SIT_AND_GO_COUNT' as const;
export const UPDATE_TOURNAMENT_COUNT = 'lobbies/UPDATE_TOURNAMENT_COUNT' as const;
export const UPDATE_DRAFT_POOL_COUNT = 'lobbies/UPDATE_DRAFT_POOL_COUNT' as const;
export const UPDATE_WEEKLY_WINNER_COUNT = 'lobbies/UPDATE_WEEKLY_WINNER_COUNT' as const;

export const getMainLobby = () => ({ type: GET_MAIN_LOBBY });

export const setMainLobby = (payload: { data: MainLobbyResponse }) => ({
  type: SET_MAIN_LOBBY,
  payload,
});

export const getSlatesForSport = (payload: { sportId: SportId }) => ({
  type: GET_SLATES_FOR_SPORT,
  payload,
});

export const setSlatesForSport = (payload: { slates: SlatesResponse; sportId: SportId }) => ({
  type: SET_SLATES_FOR_SPORT,
  payload,
});

export const getContestsForSlate = (payload: { slateId: string; sportId: SportId }) => ({
  type: GET_CONTESTS_FOR_SLATE,
  payload,
});

export const setContestsForSlate = (payload: { contests: ContestsResponse['contests'] }) => ({
  type: SET_CONTESTS_FOR_SLATE,
  payload,
});

export const errorContestsForSlate = () => ({ type: ERROR_CONTESTS_FOR_SLATE });

export const removeSlateSitAndGos = (payload: { slateSitAndGos: SlateSitAndGoResponse[] }) => ({
  type: REMOVE_SLATE_SIT_AND_GOS,
  payload,
});

export const removeLobbyTournament = (payload: { tournamentId: string }) => ({
  type: REMOVE_LOBBY_TOURNAMENT,
  payload,
});

export const removeLobbyDraftPool = (payload: { draftPoolId: string }) => ({
  type: REMOVE_LOBBY_DRAFT_POOL,
  payload,
});

export const removeLobbyWeeklyWinner = (payload: { weeklyWinnerId: string }) => ({
  type: REMOVE_LOBBY_WEEKLY_WINNER,
  payload,
});

export const updateSlateSitAndGoCount = (payload: { slateSitAndGos: SlateSitAndGoResponse[] }) => ({
  type: UPDATE_SLATE_SIT_AND_GO_COUNT,
  payload,
});

export const updateTournamentCount = (payload: EntryCountUpdate) => ({
  type: UPDATE_TOURNAMENT_COUNT,
  payload,
});

export const updateDraftPoolCount = (payload: EntryCountUpdate) => ({
  type: UPDATE_DRAFT_POOL_COUNT,
  payload,
});

export const updateWeeklyWinnerCount = (payload: EntryCountUpdate) => ({
  type: UPDATE_WEEKLY_WINNER_COUNT,
  payload,
});

import client from '@/api-contentful/contentful-request';

export const getContentfulActiveTournamentsRules = async (): Promise<any> => {
  const response = await client.getEntries({
    content_type: 'tournamentRules',
    'fields.activeTournament': true,
  });

  return response;
};

export const getContentfulTournamentRules = async ({
  tournamentSlug,
}: {
  tournamentSlug: string;
}): Promise<any> => {
  const response = await client.getEntries({
    content_type: 'tournamentRules',
    'fields.slug': tournamentSlug,
  });

  return response;
};

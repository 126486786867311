/* eslint-disable max-len */
import request from '@request';
import { apiErrorTracking, AppError } from '@/utilities/errors';

export const getCosmetics = async (): Promise<any> => {
  try {
    const response = await request({
      method: 'GET',
      url: '/cosmetics',
    });

    return response;
  } catch (e) {
    apiErrorTracking(e);
    throw new AppError(e);
  }
};

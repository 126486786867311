import React, {
  ComponentType,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';

import { RootState } from '@/store';
import { setError as setErrorAction } from '@/store/modules/error/actions';
import { setFeatureFlags as setFeatureFlagsAction } from '@/store/modules/feature-flags/actions';
import { setUser as setUserAction } from '@/store/modules/user/actions';

import { ampli, RegistrationReferralCodeEnteredSuccessfulProperties } from '@/ampli';
import * as featuresApi from '@/api/feature-flags';
import { clientType, clientVersion, getDeviceId, removeAuth, removeReferral } from '@/api/request';
import * as api from '@/api/users';
import { unsubscribeToPrivateChannels } from '@/components/contexts/pusher-events';
import { StateConfigResponse } from '@/interfaces/drafting-config';
import { AppErrorRedux } from '@/interfaces/error';
import { FeatureData, FeatureResponse } from '@/interfaces/feature-flags';
import {
  User,
  UserLoginRequest,
  UserProfileResponse,
  UserRegistrationRequest,
  UserResponse,
} from '@/interfaces/user';
import { batchAmplitudeEvents } from '@/utilities/amplitude';
import { AppError } from '@/utilities/errors';
import { ERROR_MESSAGES } from '@/utilities/errors/constants';
import errorLogger from '@/utilities/errors/logger';
import { isAuth0Enabled } from '@/utilities/helpers';
import { logoutLocation } from '@/utilities/location';

import { auth0Service } from './auth0-service';

export interface AuthContextProps {
  loginUser: (args: UserLoginRequest) => Promise<void>;
  registerUser: ({
    ampliPromotionType,
    promoCode,
    userRegistrationObject,
  }: {
    ampliPromotionType: RegistrationReferralCodeEnteredSuccessfulProperties['promotion_type'];
    promoCode: string;
    userRegistrationObject: UserRegistrationRequest;
  }) => Promise<void>;
  logoutUser: () => Promise<void>;
  isLoading: boolean;
  isAuthenticated: boolean;
  isAlternateHomeRoute: boolean;
  isAuth0FeatureEnabled: boolean;
  isAuth0RolloutSignInFeatureEnabled: boolean;
  isAuth0RolloutSignUpFeatureEnabled: boolean;
}

export const AuthContext = React.createContext<AuthContextProps | undefined>(undefined);

export const useAuth = (): AuthContextProps => {
  const context = useContext<AuthContextProps | undefined>(AuthContext);

  if (!context) {
    throw new Error(`AuthProvider context is undefined,
    please verify you are calling useAuth()
    as child of a <AuthProvider> component.`);
  }

  return context;
};

export function withAuth<P>(
  Component: React.ComponentType<P>
): ComponentType<Omit<P, keyof AuthContextProps>> {
  return (props) => {
    const auth = useAuth();

    return <Component {...(props as P)} {...auth} />;
  };
}

export const deviceMetadata: {
  clientVersion: string | number;
  client: string;
  deviceId: string;
  latitude?: string;
  longitude?: string;
} = {
  clientVersion,
  client: clientType,
  deviceId: getDeviceId(),
};

const AuthProviderEntity = ({
  children,
  user,
  setError,
  setFeatureFlags,
  setUser,
  isAuth0FeatureEnabled,
  isAuth0RolloutSignInFeatureEnabled,
  isAuth0RolloutSignUpFeatureEnabled,
}: PropsWithChildren<{
  setError: typeof setErrorAction;
  setFeatureFlags: typeof setFeatureFlagsAction;
  setUser: typeof setUserAction;
  user?: User;
  isAuth0FeatureEnabled: boolean;
  isAuth0RolloutSignInFeatureEnabled: boolean;
  isAuth0RolloutSignUpFeatureEnabled: boolean;
}>) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isAlternateHomeRoute, setIsAlternateHomeRoute] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobilePage = /^\/m\//.test(location.pathname);

  const handleWebLobbySwitch = useCallback((data: FeatureResponse) => {
    const webLobbySwitch = data?.features.find(
      (feature: FeatureData) => feature.key === 'lobby_switch'
    )?.status;
    setIsAlternateHomeRoute(webLobbySwitch);
  }, []);

  const getUser = useCallback(async () => {
    const userData = await api.getUserData();
    const userFeaturesData = await featuresApi.getUserFeatures();

    handleWebLobbySwitch(userFeaturesData.data);

    if (userData) {
      setIsLoading(false);
      setUser(userData);
      setIsAuthenticated(true);
      setFeatureFlags(userFeaturesData.data);
    }
  }, [handleWebLobbySwitch, setFeatureFlags, setUser]);

  const loginUser = useCallback(
    async (userLoginObject: UserLoginRequest): Promise<void> => {
      let userData: UserResponse;
      let profileResponse: UserProfileResponse;

      const isAuth0 = isAuth0Enabled({
        email: userLoginObject.user.email,
        isAuth0FeatureEnabled,
        isAuth0RolloutSignInFeatureEnabled,
        isAuth0RolloutSignUpFeatureEnabled,
        type: 'signIn',
      });

      try {
        if (isAuth0) {
          auth0Service.setIsAuth0Active(true);
          await api.loginUserAuth0(userLoginObject);
          const userDataResponse = await api.getUserData();
          userData = userDataResponse.data;
          profileResponse = userDataResponse.profileData;
        } else {
          const userDataResponse = await api.loginUser(userLoginObject);
          userData = userDataResponse.data;
          const userProfileResponse = await api.getUserProfile();
          profileResponse = userProfileResponse.data;
        }
        setUser({ data: userData, profileData: profileResponse });

        const featuresResponse = await featuresApi.getUserFeatures();
        handleWebLobbySwitch(featuresResponse.data);
        setFeatureFlags(featuresResponse.data);

        setIsAuthenticated(true);
      } catch (e) {
        if (e?.status === 422) {
          setError({ ...e, type: 'modal' });
        } else {
          setError({ ...e, type: 'toast' });
        }
      }
    },
    [
      handleWebLobbySwitch,
      isAuth0FeatureEnabled,
      isAuth0RolloutSignInFeatureEnabled,
      isAuth0RolloutSignUpFeatureEnabled,
      setError,
      setFeatureFlags,
      setUser,
    ]
  );

  const registerUser = useCallback(
    async ({
      ampliPromotionType,
      promoCode,
      userRegistrationObject,
    }: {
      ampliPromotionType: RegistrationReferralCodeEnteredSuccessfulProperties['promotion_type'];
      promoCode: string;
      userRegistrationObject: UserRegistrationRequest;
    }): Promise<void> => {
      let userData: UserResponse;
      let profileData: UserProfileResponse;

      const isAuth0 = isAuth0Enabled({
        email: userRegistrationObject.user.email,
        isAuth0FeatureEnabled,
        isAuth0RolloutSignInFeatureEnabled,
        isAuth0RolloutSignUpFeatureEnabled,
        type: 'signUp',
      });

      let auth0RegistrationResponse;
      try {
        if (isAuth0) {
          // auth0 - register, login, fetch user, fetch user profile
          auth0Service.setIsAuth0Active(true);
          auth0RegistrationResponse = await api.registerUserAuth0(userRegistrationObject);
          await api.loginUserAuth0({
            user: {
              email: userRegistrationObject.user.email,
              password: userRegistrationObject.user.password,
              birthdate: userRegistrationObject.user.birthdate,
            },
          });
          const userDataResponse = await api.getUserData();
          userData = userDataResponse.data;
          profileData = userDataResponse.profileData;
        } else {
          // devise - register, fetch user profile
          const registrationResponse = await api.registerUser(userRegistrationObject);
          userData = registrationResponse.data;
          const profileResponse = await api.getUserProfile();
          profileData = profileResponse.data;
        }
        setUser({
          data: userData,
          profileData,
          newRegistration: true,
        });

        const featuresResponse = await featuresApi.getUserFeatures();
        const featuresData: FeatureResponse = featuresResponse.data;
        handleWebLobbySwitch(featuresResponse.data);
        setFeatureFlags(featuresData);

        setIsAuthenticated(true);

        ampli.registrationBirthdayEnteredSuccessful();
        ampli.registrationPasswordEnteredSuccessful();
        ampli.registrationEmailEnteredSuccessful();
        ampli.registrationUsernameEnteredSuccessful();
        ampli.registrationReferralCodeEnteredSuccessful({
          promo_code: promoCode,
          promotion_type: ampliPromotionType,
        });
      } catch (e) {
        if (auth0RegistrationResponse) {
          const errorMessage = 'Your account was successfully created. Please try logging in.';
          // if auth0 sign up is successful, but login fails, show user custom error msg
          // and bring them to the login screen
          setError({
            message: errorMessage,
            type: 'toast',
          });
          navigate('/login');
          return;
        }
        setError({ ...e, type: 'toast' });
      }
    },
    [
      isAuth0FeatureEnabled,
      isAuth0RolloutSignInFeatureEnabled,
      isAuth0RolloutSignUpFeatureEnabled,
      setUser,
      handleWebLobbySwitch,
      navigate,
      setFeatureFlags,
      setError,
    ]
  );

  const clearStorage = () => {
    const underdogTheme = localStorage.getItem('underdogTheme');
    localStorage.clear();
    localStorage.setItem('underdogTheme', underdogTheme);
    sessionStorage.clear();
  };

  const logoutUser = useCallback(async (): Promise<void> => {
    removeReferral();
    logoutLocation();

    // batches all unsent events to Amplitude.
    batchAmplitudeEvents();

    if (user?.id) {
      datadogRum.clearUser();
      unsubscribeToPrivateChannels(user?.id);
    }

    try {
      if (!auth0Service.getIsAuth0Active()) {
        await api.logoutUser();
      }
    } finally {
      auth0Service.setIsAuth0Active(false);
      auth0Service.resetPendingRefresh();
      clearStorage();
      removeAuth();
      setIsAuthenticated(false);
      setUser(undefined);
      setIsLoading(false);
    }
  }, [user?.id, setUser]);

  const errInterceptor = async (axiosError: AxiosError<any, any>) => {
    const isUnauthorized = axiosError?.response?.status === 401;
    const isAuth0Active = auth0Service.getIsAuth0Active();
    const signOutRoute = axiosError?.config?.url?.includes('/sign_out'); // devise
    const signInRoute = axiosError?.config?.url?.includes('/sign_in'); // devise

    // check if error is a failed refresh token request (403, auth0)
    const refreshTokenFailed =
      JSON.parse(axiosError?.config?.data || '{}').grant_type === 'refresh_token';

    if (refreshTokenFailed) {
      // there's an edge case where auth0 might be enabled, but the user has a devise cookie
      // so we need to set auth0 to true before logging them out
      auth0Service.setIsAuth0Active(true);
      await logoutUser();
      setError({
        message: ERROR_MESSAGES.UNAUTHORIZED_ERROR,
        type: 'toast',
      });
      return Promise.reject(
        new AppError({
          ...axiosError,
          message: ERROR_MESSAGES.UNAUTHORIZED_ERROR,
          title: 'Something went wrong',
        })
      );
    }

    // mobile apps will send a fresh accesss token before opening web views. for auth0 and devise
    // the token is stored as session. this is fine for now because we can't refresh it.
    // we just need to catch the 401 and direct the user back to the app
    if (isMobilePage && isUnauthorized) {
      errorLogger(false, ERROR_MESSAGES.UNAUTHORIZED_ERROR_MOBILE, {
        location: axiosError.config.url,
      });
      return Promise.reject(
        new AppError({
          ...axiosError,
          status: 401,
          title: 'Something went wrong',
          message: ERROR_MESSAGES.UNAUTHORIZED_ERROR_MOBILE,
        })
      );
    }

    // if the devise token fails, log the user out
    if (isUnauthorized && !isAuth0Active) {
      if (!signInRoute) {
        await logoutUser();
        setError({
          message: ERROR_MESSAGES.UNAUTHORIZED_ERROR,
          type: 'toast',
        });
      }
      return Promise.reject(axiosError);
    }

    // if auth0 is enabled - try to get a new access token and then retry the original request
    // if the original request errors, log user out
    // if attempting to refresh the access token fails, it'll be caught in the block above
    if (isUnauthorized && isAuth0Active) {
      await auth0Service.refreshAccessToken();
      const accessToken = auth0Service.getAccessToken();
      if (accessToken) {
        const originalRequest = axiosError.config;
        originalRequest.headers.Authorization = accessToken;
        const originalRequestResponse = await axios(originalRequest);
        if (originalRequestResponse.status !== 401) {
          return Promise.resolve(originalRequestResponse);
        }
        await logoutUser();
        // we can't assume that all of our API calls are handled by a toast message
        // so we need to set this error AND bubble the message up to the original request
        setError({
          message: ERROR_MESSAGES.UNAUTHORIZED_ERROR,
          type: 'toast',
        });
        return Promise.reject(ERROR_MESSAGES.UNAUTHORIZED_ERROR);
      }
    }

    if (signOutRoute) {
      // if a devise token is invalid or deleted, the sign_out api request will send a 403 or 500
      // we need to handle this error and show the user the correct toast message
      return Promise.reject(ERROR_MESSAGES.UNAUTHORIZED_ERROR);
    }

    // handle auth0 errors, eg. invalid password on sign in
    if (axiosError?.response?.data?.description) {
      return Promise.reject(
        new AppError({
          ...axiosError,
          message: axiosError.response.data.description,
        })
      );
    }

    // handle fantasy api errors
    return Promise.reject(axiosError);
  };

  const interceptor = axios.interceptors.response.use(undefined, errInterceptor);
  useEffect(() => {
    return () => axios.interceptors.response.eject(interceptor);
  });

  useEffect(() => {
    // we fetch the global feature flags in app.tsx, so we need to wait for them to exist
    if (typeof isAuth0FeatureEnabled !== 'undefined' && isLoading) {
      const accessToken = Cookies.get('session') || auth0Service.getAccessToken(); // devise or auth0
      const refreshToken = auth0Service.getRefreshToken();

      if (!isAuth0FeatureEnabled && auth0Service.getRefreshToken()) {
        // log user out if session_refresh && !web_auth0 (admin and non-admin)
        auth0Service.removeRefreshToken();
        auth0Service.setIsAuth0Active(false);
        Cookies.remove('session');
        setError({
          type: 'toast',
          message: ERROR_MESSAGES.UNAUTHORIZED_ERROR,
        });
        setIsLoading(false);
      } else if (accessToken || refreshToken) {
        // try to log user in
        getUser();
      } else {
        // show unauthenticated state
        setIsLoading(false);
      }
    }
  }, [getUser, isAuth0FeatureEnabled, isLoading, setError]);

  const values = useMemo(
    () => ({
      isAuthenticated,
      isAlternateHomeRoute,
      isAuth0FeatureEnabled,
      isAuth0RolloutSignInFeatureEnabled,
      isAuth0RolloutSignUpFeatureEnabled,
      loginUser,
      registerUser,
      logoutUser,
      isLoading,
    }),
    [
      isAuthenticated,
      isAlternateHomeRoute,
      isAuth0FeatureEnabled,
      isAuth0RolloutSignInFeatureEnabled,
      isAuth0RolloutSignUpFeatureEnabled,
      loginUser,
      registerUser,
      logoutUser,
      isLoading,
    ]
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export const AuthProvider = connect(
  (state: RootState) => ({
    isAuth0FeatureEnabled: state.featureFlags.webAuth0,
    isAuth0RolloutSignInFeatureEnabled: state.featureFlags.auth0RolloutSignIn,
    isAuth0RolloutSignUpFeatureEnabled: state.featureFlags.auth0RolloutSignUp,
    user: state.user,
  }),
  (dispatch) => ({
    setError: (payload: AppErrorRedux) => dispatch(setErrorAction(payload)),
    setFeatureFlags: (payload: FeatureResponse) => dispatch(setFeatureFlagsAction(payload)),
    setUser: (payload: {
      data: UserResponse;
      profileData: UserProfileResponse;
      stateConfigData: StateConfigResponse;
      newRegistration?: true;
    }) => dispatch(setUserAction(payload)),
  })
)(AuthProviderEntity);
